export default [
  {
    path: "/apps/event",
    name: "apps-event",
    component: () => import("@/views/apps/calendar/Calendar.vue"),
  },
  {
    path: "/apps/calendar",
    name: "apps-calendar",
    component: () => import("@/views/apps/academic/Calendar.vue"),
  },
  {
    path: "/device-settings",
    name: "device-settings",
    component: () => import("@/views/myskool/management/deviceSettings.vue"),
  },
  {
    path: "/manage-classes",
    name: "manage-classes",
    component: () =>
      import("@/views/myskool/profiles/departmentandclasses.vue"),
  },
  {
    path: "/manage-student",
    name: "manage-studentgrid",
    component: () => import("@/views/myskool/student/grid.vue"),
  },
  {
    path: "/manage-enrolled",
    name: "manage-enrolled",
    component: () => import("@/views/myskool/student/gridEnrolled.vue"),
  },
  {
    path: "/student-registered",
    name: "student-registered",
    component: () => import("@/views/myskool/student/studentRegistered.vue"),
  },
  {
    path: "/student-enrolled",
    name: "student-enrolled",
    component: () => import("@/views/myskool/student/studentEnrolled.vue"),
  },
  {
    path: "/requests",
    name: "leaving-requests",
    component: () => import("@/views/myskool/student/leavingRequests.vue"),
  },
  {
    path: "/student-info",
    name: "student-info",
    component: () => import("@/views/myskool/student/studentPrint.vue"),
    props: true,
  },
  {
    path: "/student-complaints",
    name: "student-complaints",
    component: () => import("@/views/myskool/student/studentComplaints.vue"),
  },
  {
    path: "/student-category",
    name: "student-category",
    component: () => import("@/views/myskool/student/studentCategory.vue"),
  },
  {
    path: "/student-interview",
    name: "student-interview",
    component: () => import("@/views/myskool/student/studentInterview.vue"),
  },
  {
    path: "/manage-branches",
    name: "manage-branches",
    component: () => import("@/views/myskool/schoolinfo/branchcards.vue"),
  },
  {
    path: "/school-complaints",
    name: "school-complaints",
    component: () => import("@/views/myskool/schoolinfo/schoolComplaints.vue"),
  },
  {
    path: "/school-dashboard",
    name: "school-dashboard",
    component: () => import("@/views/myskool/schoolinfo/dashboard.vue"),
  },
  {
    path: "/manage-clubs",
    name: "manage-clubs",
    component: () => import("@/views/myskool/schoolinfo/clubs.vue"),
  },
  // {
  //   path: "/join-club",
  //   name: "join-club",
  //   component: () => import("@/views/myskool/schoolinfo/joinClub.vue"),
  //   meta: {
  //     layout: "full",
  //     resource: "Auth",
  //     proceed: true,
  //   },
  // },
  {
    path: "/attendance-note",
    name: "attendance-note",
    component: () => import("@/views/myskool/schoolinfo/attendanceNote.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      proceed: true,
    },
  },
  {
    path: "/manage-inquiry",
    name: "manage-inquiry",
    component: () => import("@/views/myskool/inquiry/inquiry.vue"),
  },
  {
    path: "/manage-inquiry-new",
    name: "manage-inquiry-new",
    component: () => import("@/views/myskool/inquiry/inquiryNew.vue"),
    props: true,
  },
  {
    path: "/inquiry",
    name: "inquiry",
    component: () => import("@/views/myskool/inquiry/inquiryGridNew.vue"),
  },
  {
    path: "/inquiry-form",
    name: "inquiry-form",
    component: () => import("@/views/myskool/inquiry/inquiryFormNew.vue"),
    props: true,
  },
  {
    path: "/manage-student/:id/:readmit?",
    name: "manage-student",
    component: () => import("@/views/myskool/student/student.vue"),
    props: true,
  },
  {
    path: "/student-coachinggrid",
    name: "student-coachinggrid",
    component: () => import("@/views/myskool/student/gridCoaching.vue"),
  },
  {
    path: "/student-coachinggrid/:id/:readmit?",
    name: "student-coaching",
    component: () => import("@/views/myskool/student/studentCoaching.vue"),
    props: true,
  },
  {
    path: "/student",
    name: "student-public",
    component: () => import("@/views/myskool/student/studentPublic.vue"),
    props: true,
  },
  {
    path: "/add-student",
    name: "add-student",
    component: () => import("@/views/myskool/student/addStudent.vue"),
  },
  {
    path: "/manage-addbranch",
    name: "manage-addbranch",
    component: () => import("@/views/myskool/schoolinfo/myschoolinfo.vue"),
  },
  {
    path: "/manage-fee",
    name: "manage-fee",
    component: () => import("@/views/myskool/feestructure/FeeStructure.vue"),
  },
  {
    path: "/staff-departments",
    name: "staff-departments",
    component: () => import("@/views/myskool/departments/StaffDepartments.vue"),
  },
  {
    path: "/manage-staff",
    name: "manage-staffgrid",
    component: () => import("@/views/myskool/staff/staffGrid.vue"),
  },
  {
    path: "/manage-staff/:id",
    name: "manage-staff",
    component: () => import("@/views/myskool/staff/myStaff.vue"),
  },
  {
    path: "/staff-requests",
    name: "staff-requests",
    component: () => import("@/views/myskool/staff/staffRequests.vue"),
  },
  {
    path: "/teaching-classes/:id",
    name: "teaching-classes",
    component: () => import("@/views/myskool/staff/teacherClasses.vue"),
  },
  {
    path: "/requisition",
    name: "requisition",
    component: () => import("@/views/myskool/staff/requisition.vue"),
  },
  {
    path: "/purchase-order",
    name: "purchase-order",
    component: () => import("@/views/myskool/staff/purchaseOrder.vue"),
  },
  {
    path: "/staff-application",
    name: "staff-application",
    component: () => import("@/views/myskool/staff/staffApplication.vue"),
  },
  {
    path: "/applicants",
    name: "applicants",
    component: () => import("@/views/myskool/staff/allApplications.vue"),
  },
  {
    path: "/staff-performance",
    name: "staff-performance",
    component: () => import("@/views/myskool/staff/performanceCards.vue"),
  },
  {
    path: "/manage-performance/:id",
    name: "manage-performance",
    component: () => import("@/views/myskool/staff/performanceForm.vue"),
  },
  {
    path: "/staff-evaluation",
    name: "staff-evaluation",
    component: () => import("@/views/myskool/staff/staffPerformance.vue"),
  },
  {
    path: "/challan",
    name: "manage-challan",
    component: () => import("@/views/myskool/feestructure/challan.vue"),
  },
  {
    path: "/fee-creator",
    name: "fee-creator",
    component: () => import("@/views/myskool/feestructure/feeCreator.vue"),
  },
  {
    path: "/fee-dashboard",
    name: "fee-dashboard",
    component: () => import("@/views/myskool/feestructure/feeDashboard.vue"),
  },
  {
    path: "/fee-reconcilation",
    name: "fee-reconcilation",
    component: () => import("@/views/myskool/feestructure/reconcilation.vue"),
  },
  {
    path: "/dues-summary",
    name: "dues-summary",
    component: () => import("@/views/myskool/feestructure/duesSummary.vue"),
  },
  {
    path: "/manage-user",
    name: "manage-user",
    component: () => import("@/views/apps/user/users-list/UsersList.vue"),
  },
  {
    path: "/manage-userrights",
    name: "manage-userRights",
    component: () => import("@/views/myskool/management/userRights.vue"),
  },
  {
    path: "/manage-subjects",
    name: "manage-subjects",
    component: () => import("@/views/myskool/profiles/subjects.vue"),
  },
  {
    path: "/subjects",
    name: "manage-subjects-new",
    component: () => import("@/views/myskool/profiles/subjectsNew.vue"),
  },
  {
    path: "/manage-subject",
    name: "manage-subject",
    component: () => import("@/views/myskool/profiles/subjectsCoaching.vue"),
  },
  {
    path: "/exam-type",
    name: "exam-type",
    component: () => import("@/views/myskool/exam/examType.vue"),
  },
  {
    path: "/exam-marks",
    name: "exam-marks",
    component: () => import("@/views/myskool/exam/examMarks.vue"),
  },
  {
    path: "/manage-marks",
    name: "manage-marks",
    component: () => import("@/views/myskool/exam/marksEntry.vue"),
  },
  {
    path: "/marks",
    name: "marks",
    component: () => import("@/views/myskool/exam/marksEntryAll.vue"),
  },
  {
    path: "/marks-sub",
    name: "marks-sub",
    component: () => import("@/views/myskool/exam/marksEntrySub.vue"),
  },
  {
    path: "/marks-coaching",
    name: "marks-coaching",
    component: () => import("@/views/myskool/exam/marksEntryIota.vue"),
  },
  {
    path: "/print-result",
    name: "print-result",
    component: () => import("@/views/myskool/exam/printResultMe.vue"),
  },
  {
    path: "/grading",
    name: "grading",
    component: () => import("@/views/myskool/exam/grading.vue"),
  },
  {
    path: "/grading-reward",
    name: "grading-reward",
    component: () => import("@/views/myskool/exam/gradingReward.vue"),
  },
  {
    path: "/grading-class",
    name: "grading-class",
    component: () => import("@/views/myskool/exam/gradingClasswise.vue"),
  },
  {
    path: "/remarks",
    name: "remarks",
    component: () => import("@/views/myskool/exam/remarks.vue"),
  },
  {
    path: "/sessions",
    name: "sessions",
    component: () => import("@/views/myskool/sessions/sessions.vue"),
  },
  {
    path: "/workingdays",
    name: "workingdays",
    component: () => import("@/views/myskool/sessions/workingDays.vue"),
  },
  {
    path: "/timetable/:id",
    name: "timetable",
    component: () => import("@/views/myskool/sessions/timetable.vue"),
    props: true,
  },
  {
    path: "/timetable",
    name: "assign-timetable",
    component: () => import("@/views/myskool/sessions/assignTimetable.vue"),
  },
  {
    path: "/accounts",
    name: "accounts",
    component: () => import("@/views/myskool/accounts/chartOfAccounts.vue"),
  },
  {
    path: "/journal",
    name: "journal",
    component: () => import("@/views/myskool/accounts/journal.vue"),
  },
  {
    path: "/accounting/:id",
    name: "accounting",
    component: () => import("@/views/myskool/accounts/accounting.vue"),
  },
  {
    path: "/manage-suppliers",
    name: "manage-suppliers",
    component: () => import("@/views/myskool/accounts/supplier.vue"),
  },
  {
    path: "/expenses",
    name: "expenses",
    component: () => import("@/views/myskool/accounts/expenses.vue"),
  },
  {
    path: "/income",
    name: "income",
    component: () => import("@/views/myskool/accounts/income.vue"),
  },
  {
    path: "/opening-balance",
    name: "opening-balance",
    component: () => import("@/views/myskool/accounts/openingBalance.vue"),
  },
  {
    path: "/other-income",
    name: "other-income",
    component: () => import("@/views/myskool/accounts/otherIncome.vue"),
  },
  {
    path: "/manage-chequebook",
    name: "manage-chequebook",
    component: () => import("@/views/myskool/accounts/chequeBook.vue"),
  },
  {
    path: "/bank-deposit",
    name: "bank-deposit",
    component: () => import("@/views/myskool/accounts/bankDeposit.vue"),
  },
  {
    path: "/student-attendance",
    name: "student-attendance",
    component: () => import("@/views/myskool/student/studentAttendance.vue"),
  },
  {
    path: "/student-attendance-subject",
    name: "student-attendance-subject",
    component: () =>
      import("@/views/myskool/student/studentAttendanceSubject.vue"),
  },
  {
    path: "/attendance-sync",
    name: "attendance-sync",
    component: () =>
      import("@/views/myskool/student/studentAttendanceSync.vue"),
  },
  {
    path: "/attendance-dashboard",
    name: "attendance-dashboard",
    component: () => import("@/views/myskool/student/attendanceDashboard.vue"),
  },
  {
    path: "/staff-attendance",
    name: "staff-attendance",
    component: () => import("@/views/myskool/staff/staffAttendance.vue"),
  },
  {
    path: "/report",
    name: "report",
    component: () => import("@/views/myskool/reports/reports.vue"),
  },
  {
    path: "/templates",
    name: "templates",
    component: () => import("@/views/myskool/message/templates.vue"),
  },

  {
    path: "/send-messages",
    name: "apps-chat",
    component: () => import("@/views/apps/chat/Chat.vue"),
    // component: () => import("@/views/apps/chat/Chat2.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "chat-application",
    },
  },
  {
    path: "/groups",
    name: "apps-groups",
    component: () => import("@/views/apps/groups/Chat.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "chat-application",
    },
  },

  {
    path: "/whatsapp-bot",
    name: "whatsapp-bot",
    component: () => import("@/views/myskool/message/whatsappBot.vue"),
  },
  {
    path: "/outbox",
    name: "outbox",
    component: () => import("@/views/myskool/message/outbox.vue"),
  },
  {
    path: "/sentbox",
    name: "sentbox",
    component: () => import("@/views/myskool/message/sentbox.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/myskool/message/settings.vue"),
  },
  {
    path: "/profile-dashboard",
    name: "profile-dashboard",
    component: () => import("@/views/myskool/profiles/profileDashboard.vue"),
  },
  {
    path: "/library",
    name: "library",
    component: () => import("@/views/myskool/resources/library.vue"),
  },
  {
    path: "/homework",
    name: "homework",
    component: () => import("@/views/myskool/resources/homework.vue"),
  },
  {
    path: "/online-classes",
    name: "online-classes",
    component: () => import("@/views/myskool/resources/onlineClasses.vue"),
  },
  {
    path: "/join-class/:id",
    name: "join-class",
    component: () => import("@/views/myskool/resources/joinClass.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      proceed: true,
    },
  },
  {
    path: "/live-classes",
    name: "live-classes",
    component: () => import("@/views/myskool/resources/liveClasses.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      proceed: true,
    },
  },
  {
    path: "/lectures",
    name: "lectures",
    component: () => import("@/views/myskool/resources/lectures.vue"),
  },
  {
    path: "/admit-card",
    name: "admit-card",
    component: () => import("@/views/myskool/resources/admitCard.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      proceed: true,
    },
  },
  {
    path: "/form-requests",
    name: "form-requests",
    component: () => import("@/views/myskool/resources/formRequests.vue"),
  },
  {
    path: "/card-requests",
    name: "card-requests",
    component: () => import("@/views/myskool/resources/cardRequests.vue"),
  },
  {
    path: "/inquiry-elixir",
    name: "inquiry-elixir",
    component: () => import("@/views/myskool/inquiry/inquiryElixir.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      proceed: true,
    },
  },
  {
    path: "/inquiry-hpgs",
    name: "inquiry-hpgs",
    component: () => import("@/views/myskool/inquiry/inquiryHpgs.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      proceed: true,
    },
  },
  {
    path: "/school-inquiry",
    name: "school-inquiry",
    component: () => import("@/views/myskool/inquiry/inquiryPublic.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      proceed: true,
    },
  },
  {
    path: "/inquiry-veritas",
    name: "inquiry-veritas",
    component: () => import("@/views/myskool/inquiry/inquiryVeritas.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      proceed: true,
    },
  },
  {
    path: "/staff-registration",
    name: "staff-registration",
    component: () => import("@/views/myskool/others/staffRegistration.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      proceed: true,
    },
  },
  {
    path: "/student-registration",
    name: "student-registration",
    component: () => import("@/views/myskool/others/studentRegistration.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      proceed: true,
    },
  },
  {
    path: "/registration",
    name: "registration",
    component: () => import("@/views/myskool/student/registration.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      proceed: true,
    },
  },
  {
    path: "/help-palestine",
    name: "help-palestine",
    component: () => import("@/views/myskool/others/palestine.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      proceed: true,
    },
  },
  // ==== szablc ====
  {
    path: "/student-enrollment",
    name: "student-enrollment",
    component: () => import("@/views/myskool/others/studentEnrollment.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      proceed: true,
    },
  },
  {
    path: "/student-admission",
    name: "student-admission",
    component: () => import("@/views/myskool/others/studentAdmission.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      proceed: true,
    },
  },
  // ==== szablc ====
  {
    path: "/branding",
    name: "branding",
    component: () => import("@/views/myskool/others/branding.vue"),
  },
  {
    path: "/donations",
    name: "donations",
    component: () => import("@/views/myskool/donations/donations.vue"),
  },
  {
    path: "/staff-salary",
    name: "staff-salary",
    component: () => import("@/views/myskool/staff/staffSalary.vue"),
  },
  {
    path: "/salary",
    name: "salary",
    component: () => import("@/views/myskool/staff/shareSalary.vue"),
  },
  {
    path: "/manage-quiz",
    name: "manage-quiz",
    component: () => import("@/views/myskool/quiz/manageQuiz.vue"),
  },
  {
    path: "/quiz-question/:id",
    name: "quiz-question",
    component: () => import("@/views/myskool/quiz/questions.vue"),
  },
  {
    path: "/create-quiz/:id",
    name: "create-quiz",
    component: () => import("@/views/myskool/quiz/createQuiz.vue"),
  },
  {
    path: "/question-paper",
    name: "question-paper",
    component: () => import("@/views/myskool/quiz/questionPaper.vue"),
  },
  {
    path: "/quiz",
    name: "quiz",
    component: () => import("@/views/myskool/quiz/quiz.vue"),
  },
  // {
  //   path: "/quiz",
  //   name: "quiz-auto",
  //   component: () => import("@/views/myskool/quiz/quizAuto.vue"),
  // },
  {
    path: "/manage-datesheet",
    name: "manage-datesheet",
    component: () => import("@/views/myskool/exam/datesheetGrid.vue"),
  },
  {
    path: "/datesheet/:id",
    name: "datesheet",
    component: () => import("@/views/myskool/exam/datesheet.vue"),
  },
  {
    path: "/manage-planner/:id?",
    name: "manage-planner",
    component: () => import("@/views/myskool/planner/plannerGrid.vue"),
  },
  {
    path: "/planner/:id",
    name: "planner",
    component: () => import("@/views/myskool/planner/planner.vue"),
  },
  {
    path: "/inventory",
    name: "inventory",
    component: () => import("@/views/myskool/inventory/inventory.vue"),
  },
  {
    path: "/sales",
    name: "sales",
    component: () => import("@/views/myskool/inventory/sales.vue"),
  },
  {
    path: "/sales-transactions",
    name: "sales-transactions",
    component: () => import("@/views/myskool/inventory/salesTransactions.vue"),
  },
  {
    path: "/manage-buildings",
    name: "manage-buildings",
    component: () => import("@/views/myskool/inventory/building.vue"),
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: () => import("@/views/myskool/inventory/maintenance.vue"),
  },
  {
    path: "/billing",
    name: "apps-billing",
    component: () => import("@/views/apps/billing/Chat.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "chat-application",
    },
  },
  {
    path: "/fee-increment",
    name: "fee-increment",
    component: () => import("@/views/myskool/feestructure/feeIncrement.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "chat-application",
    },
  },
  {
    path: "/homework-staff",
    name: "homework-staff",
    component: () => import("@/views/myskool/teachers/homeworkStaff.vue"),
  },
  {
    path: "/attendance-staff",
    name: "attendance-staff",
    component: () =>
      import("@/views/myskool/teachers/studentAttendanceStaff.vue"),
  },
  {
    path: "/marks-staff",
    name: "marks-staff",
    component: () => import("@/views/myskool/teachers/marksStaff.vue"),
  },
  {
    path: "/apps/webinar",
    name: "webinar",
    component: () => import("@/views/myskool/teachers/webinar.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      proceed: true,
    },
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: "/apps/email",
    name: "apps-email",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
    },
  },
  {
    path: "/apps/email/:folder",
    name: "apps-email-folder",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "apps-email",
    },
    beforeEnter(to, _, next) {
      if (
        ["sent", "draft", "starred", "spam", "trash"].includes(to.params.folder)
      )
        next();
      else next({ name: "error-404" });
    },
  },
  {
    path: "/apps/email/label/:label",
    name: "apps-email-label",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "apps-email",
    },
    beforeEnter(to, _, next) {
      if (
        ["personal", "company", "important", "private"].includes(
          to.params.label
        )
      )
        next();
      else next({ name: "error-404" });
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/todo",
    name: "apps-todo",
    component: () => import("@/views/apps/todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
    },
  },
  {
    path: "/apps/todo/:filter",
    name: "apps-todo-filter",
    component: () => import("@/views/apps/todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
      navActiveLink: "apps-todo",
    },
    beforeEnter(to, _, next) {
      if (["important", "completed", "deleted"].includes(to.params.filter))
        next();
      else next({ name: "error-404" });
    },
  },
  {
    path: "/apps/todo/tag/:tag",
    name: "apps-todo-tag",
    component: () => import("@/views/apps/todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
      navActiveLink: "apps-todo",
    },
    // beforeEnter(to, _, next) {
    //   if (["team", "low", "medium", "high", "update"].includes(to.params.tag))
    //     next();
    //   else next({ name: "error-404" });
    // },
  },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/e-commerce/shop",
    name: "apps-e-commerce-shop",
    component: () =>
      import("@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue"),
    meta: {
      contentRenderer: "sidebar-left-detached",
      contentClass: "ecommerce-application",
      pageTitle: "Shop",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Shop",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/e-commerce/wishlist",
    name: "apps-e-commerce-wishlist",
    component: () =>
      import(
        "@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue"
      ),
    meta: {
      pageTitle: "Wishlist",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Wishlist",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/e-commerce/checkout",
    name: "apps-e-commerce-checkout",
    component: () =>
      import(
        "@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue"
      ),
    meta: {
      pageTitle: "Checkout",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Checkout",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/e-commerce/:slug",
    name: "apps-e-commerce-product-details",
    component: () =>
      import(
        "@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue"
      ),
    meta: {
      pageTitle: "Product Details",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Shop",
          active: true,
        },
        {
          text: "Product Details",
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/users/list",
    name: "apps-users-list",
    component: () => import("@/views/apps/user/users-list/UsersList.vue"),
  },
  {
    path: "/apps/users/view/:id/:item",
    name: "apps-users-view",
    component: () => import("@/views/apps/user/users-view/UsersView.vue"),
    props: true,
  },
  {
    path: "/apps/users/edit/:id",
    name: "apps-users-edit",
    component: () => import("@/views/apps/user/users-edit/UsersEdit.vue"),
  },

  // Invoice
  {
    path: "/apps/invoice/list",
    name: "apps-invoice-list",
    component: () =>
      import("@/views/apps/invoice/invoice-list/InvoiceList.vue"),
  },
  {
    path: "/apps/invoice/preview/:id",
    name: "apps-invoice-preview",
    component: () =>
      import("@/views/apps/invoice/invoice-preview/InvoicePreview.vue"),
  },
  {
    path: "/apps/invoice/add/",
    name: "apps-invoice-add",
    component: () => import("@/views/apps/invoice/invoice-add/InvoiceAdd.vue"),
  },
  {
    path: "/apps/invoice/edit/:id",
    name: "apps-invoice-edit",
    component: () =>
      import("@/views/apps/invoice/invoice-edit/InvoiceEdit.vue"),
  },
];
