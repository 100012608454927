<template>
  <div>
    <b-modal
      id="modal-login"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
      title=""
      size="sm"
    >
      <b-row align-h="center">
        <b-spinner
          class="m-5"
          style="width: 4rem; height: 4rem"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </b-row>
    </b-modal>

    <b-row align-h="center" class="match-height">
      <b-col lg="7" md="8">
        <b-card class="" body-class="position-relative">
          <h3 class="">Student Information</h3>
          <hr />

          <b-row>
            <!-- <b-col md="6">
              <b-form-group
                label="Family code"
                invalid-feedback="Family code is required."
              >
                <b-form-input
                  type="number"
                  v-model="myObj.family_code"
                  class="form-control"
                  placeholder="Enter family code"
                />
              </b-form-group>
            </b-col> -->
            <b-col md="6">
              <b-form-group
                :label="
                  reportDomain == 'myskoolhpgs' ||
                  reportDomain == 'myskoolprofectus'
                    ? 'CID *'
                    : 'Gr number *'
                "
                :invalid-feedback="
                  reportDomain == 'myskoolhpgs' ||
                  reportDomain == 'myskoolprofectus'
                    ? 'CID is required'
                    : 'Gr number is required'
                "
                label-class="font-weight-bold"
              >
                <b-form-input
                  ref="grNo"
                  v-model="myObj.grNo"
                  class="form-control"
                  :placeholder="
                    reportDomain == 'myskoolhpgs' ||
                    reportDomain == 'myskoolprofectus'
                      ? 'Enter CID'
                      : 'Enter Gr number '
                  "
                  @focusout="CheckGrNo()"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="schGr && schGr.valueBool">
              <b-form-group
                :label="`${schGr.value}`"
                :invalid-feedback="`${schGr.value} is required.`"
              >
                <b-form-input
                  :placeholder="`Enter ${schGr.value}`"
                  v-model="myObj.grNoLocal"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col md="6" v-if="spNo && spNo.valueBool">
              <b-form-group
                :label="`${spNo.value}`"
                :invalid-feedback="`${spNo.value} is required.`"
              >
                <b-form-input
                  :placeholder="`Enter ${spNo.value}`"
                  v-model="myObj.receiptNo"
                />
              </b-form-group>
            </b-col> -->
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="Name *"
                invalid-feedback="Student name is required."
                label-class="font-weight-bold"
              >
                <b-form-input
                  placeholder="Enter name."
                  ref="name"
                  v-model.trim="myObj.name"
                  @focusout="CheckName()"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Father name *"
                invalid-feedback="Father name is required."
                label-class="font-weight-bold"
              >
                <b-form-input
                  ref="fname"
                  placeholder="Enter father name."
                  v-model.trim="myObj.father_name"
                  @focusout="CheckFName()"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Gender *"
                invalid-feedback="Gender is required."
                label-class="font-weight-bold"
                ref="gender"
              >
                <v-select
                  v-model="myObj.gender"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="genders"
                  :clearable="false"
                  placeholder="Select gender"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Date of Birth *"
                invalid-feedback="Date of Birth is required."
                label-class="font-weight-bold"
                ref="dob"
              >
                <flat-pickr
                  :config="config"
                  v-model="myObj.date_of_birth"
                  class="form-control"
                  placeholder="Select date of birth."
                  @on-change="formatDate()"
                />
                <!-- @on-change="CheckDob()" -->
              </b-form-group>
            </b-col>
            <!-- <b-col md="6">
                    <b-form-group
                      label="Surname"
                      invalid-feedback="Surname is required."
                    >
                      <b-form-input
                        placeholder="Enter Surname"
                        v-model="myObj.surname"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                      label="Religion"
                      invalid-feedback="School name is required."
                    >
                      <v-select
                        v-model="myObj.religion"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="religions"
                        :clearable="false"
                        ref="religion"
                        :disabled="dataloading"
                        placeholder="Select religion"
                      />
                    </b-form-group>
                  </b-col> -->
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Admitted Campus *"
                invalid-feedback="Campus is required."
                label-class="font-weight-bold"
                ref="adm_camp"
              >
                <v-select
                  v-model="myObj.campus_Admitted"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="campuses"
                  :clearable="false"
                  :reduce="(val) => val.id"
                  label="branch"
                  :disabled="campLoading"
                  placeholder="Select campus"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Current Campus *"
                invalid-feedback="Campus is required."
                label-class="font-weight-bold"
                ref="camp"
              >
                <v-select
                  v-model="myObj.campusID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="campuses"
                  :clearable="false"
                  :reduce="(val) => val.id"
                  label="branch"
                  :disabled="campLoading"
                  placeholder="Select campus"
                  @input="setData()"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Class (admitted) *"
                invalid-feedback="Class is required."
                label-class="font-weight-bold"
                ref="class_admitted"
              >
                <v-select
                  v-model="myObj.class_admitted"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="classesOptions"
                  :clearable="false"
                  :reduce="(val) => val.id"
                  label="name"
                  @input="FillSections()"
                  @search:blur="CheckClassAdmit()"
                  :disabled="dataloading"
                  placeholder="Select class"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Section (admitted) *"
                invalid-feedback="Section is required."
                label-class="font-weight-bold"
                ref="section_admitted"
              >
                <v-select
                  v-model="myObj.section_admitted"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="admittedSections"
                  :reduce="(val) => val.id"
                  label="section"
                  :clearable="false"
                  :disabled="dataloading"
                  placeholder="Select section"
                  @input="CheckAdmitSec()"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Class (current) *"
                invalid-feedback="Class is required."
                label-class="font-weight-bold"
                ref="class_current"
              >
                <v-select
                  v-model="myObj.class_current"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="classesOptions"
                  :clearable="false"
                  :reduce="(val) => val.id"
                  label="name"
                  @input="FillSectionsCurrent()"
                  @search:blur="CheckClassCurrent()"
                  :disabled="dataloading"
                  placeholder="Select class"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Section (current) *"
                invalid-feedback="Section is required."
                label-class="font-weight-bold"
                ref="section_current"
              >
                <v-select
                  v-model="myObj.section_current"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="currentSections"
                  :reduce="(val) => val.id"
                  label="section"
                  :clearable="false"
                  :disabled="dataloading"
                  placeholder="Select section"
                  @input="CheckCurrentSec()"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Date of Admission *"
                invalid-feedback="Date of Admission is required."
                label-class="font-weight-bold"
                ref="doa"
              >
                <flat-pickr
                  :config="config"
                  v-model="myObj.date_of_admission"
                  class="form-control"
                  placeholder="Select date of admission."
                  @on-change="CheckAdmDate()"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group
                label="Last Institute"
                invalid-feedback="School name is required."
              >
                <b-form-input
                  placeholder="Enter last institute."
                  v-model="myObj.last_institute"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Place of birth"
                invalid-feedback="Place of birth is required."
              >
                <b-form-input
                  placeholder="Enter place of birth"
                  v-model="myObj.place_of_birth"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Mother Tongue"
                invalid-feedback="Mother Tongue is required."
              >
                <b-form-input
                  placeholder="Enter mother tongue"
                  v-model="myObj.mother_tongue"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nationality"
                invalid-feedback="Nationality is required."
              >
                <b-form-input
                  placeholder="Enter nationality"
                  v-model="myObj.nationality"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Current Address"
                invalid-feedback="Contact number is required."
              >
                <b-form-input
                  placeholder="Enter current address."
                  v-model="myObj.address_current"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="">
          <h3 class="">Father Information</h3>
          <hr />
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Occupation"
                invalid-feedback="School name is required."
              >
                <b-form-input
                  v-model="myObj.father_occupation"
                  placeholder="Enter Occupation"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="CNIC no "
                invalid-feedback="CNIC is required."
              >
                <b-form-input
                  ref="fCnic"
                  v-model="myObj.father_cnic"
                  placeholder="Enter CNIC number"
                />
                <!-- @focusout="CheckCnic()" -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Education"
                invalid-feedback="School name is required."
              >
                <b-form-input
                  v-model="myObj.father_education"
                  placeholder="Enter Education"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Monthly income">
                <b-form-input
                  type="number"
                  v-model="myObj.monthly_income"
                  placeholder="Enter monthly income"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Email" invalid-feedback="Email is required.">
                <b-form-input
                  ref="fEmail"
                  v-model="myObj.father_email"
                  placeholder="Enter Email"
                />
              </b-form-group>
              <!-- @focusout="CheckEmail()" -->
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Employer (Firm)"
                invalid-feedback="Contact number is required."
              >
                <b-form-input
                  v-model="myObj.father_employer"
                  placeholder="Enter Employer"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Office Contact"
                invalid-feedback="Contact number is required."
              >
                <b-form-input
                  v-model="myObj.office_phone"
                  placeholder="Enter office number"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Office Address"
                invalid-feedback="Contact number is required."
              >
                <b-form-input
                  placeholder="Enter office address"
                  v-model="myObj.office_address"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="">
          <h3 class="">Mother Information</h3>
          <hr />
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Name"
                invalid-feedback="School name is required."
              >
                <b-form-input
                  v-model="myObj.mother_name"
                  placeholder="Enter name"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Occupation"
                invalid-feedback="School name is required."
              >
                <b-form-input
                  v-model="myObj.mother_occupation"
                  placeholder="Enter occupation"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="CNIC no"
                invalid-feedback="CNIC is required."
              >
                <b-form-input
                  ref="mCnic"
                  v-model="myObj.mother_cnic"
                  placeholder="Enter CNIC number"
                />
                <!-- @focusout="CheckMCnic()" -->
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Education"
                invalid-feedback="School name is required."
              >
                <b-form-input
                  v-model="myObj.mother_education"
                  placeholder="Enter education."
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Email"
                invalid-feedback="School name is required."
              >
                <b-form-input
                  v-model="myObj.mother_email"
                  placeholder="Enter email address"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Employer"
                invalid-feedback="School name is required."
              >
                <b-form-input
                  v-model="myObj.mother_employer"
                  placeholder="Enter employer"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Office Contact"
                invalid-feedback="School name is required."
              >
                <b-form-input
                  v-model="myObj.mother_office_phone"
                  placeholder="Enter office contact"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Office Address"
                invalid-feedback="School name is required."
              >
                <b-form-input
                  v-model="myObj.mother_office_address"
                  placeholder="Enter office address"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="">
          <h3 class="">Guardian Information</h3>
          <hr />
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Name"
                invalid-feedback="School name is required."
              >
                <b-form-input
                  placeholder="Enter guardian name."
                  v-model="myObj.guardian_name"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Relation"
                invalid-feedback="School name is required."
              >
                <b-form-input
                  placeholder="Enter guardian relation."
                  v-model="myObj.guardian_relationship"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="Occupation"
                invalid-feedback="School name is required."
              >
                <b-form-input
                  placeholder="Enter guardian occupation."
                  v-model="myObj.guardian_occupation"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="CNIC no"
                invalid-feedback="Contact number is required."
              >
                <b-form-input
                  ref="gCnic"
                  placeholder="Enter CNIC number."
                  v-model="myObj.guardian_cnic"
                />
                <!-- @focusout="CheckGCnic()" -->
              </b-form-group>
            </b-col>
            <!-- <b-col md="6">
                    <b-form-group
                      label="Mobile No"
                      invalid-feedback="School name is required."
                    >
                      <b-form-input placeholder="Enter mobile number" />
                    </b-form-group>
                  </b-col> -->
            <b-col md="6"> </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Email"
                invalid-feedback="Contact number is required."
              >
                <b-form-input
                  ref="gEmail"
                  placeholder="Enter email addresss."
                  v-model="myObj.guardian_email"
                />
                <!-- @focusout="CheckGEMail()" -->
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Employer"
                invalid-feedback="Contact number is required."
              >
                <b-form-input
                  placeholder="Enter employer."
                  v-model="myObj.guardian_employer"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Office Contact"
                invalid-feedback="Contact number is required."
              >
                <b-form-input
                  placeholder="Enter office number"
                  v-model="myObj.guardian_office_phone"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Education"
                invalid-feedback="Contact number is required."
              >
                <b-form-input
                  placeholder="Enter Education"
                  v-model="myObj.guardian_education"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Office Address"
                invalid-feedback="Contact number is required."
              >
                <b-form-input
                  placeholder="Enter office contact number"
                  v-model="myObj.guardian_office_address"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="">
          <h3 class="">Contact Information</h3>
          <hr />
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Father's Contact *"
                invalid-feedback="Please enter correct contact number."
                label-class="font-weight-bold"
              >
                <vue-tel-input
                  v-model="myObj.contact_father"
                  :inputOptions="vueTelOptions"
                  :dropdownOptions="dropOptions"
                  @input="CheckFContact"
                ></vue-tel-input>
                <small class="mr-1 text-danger" v-if="fphoneerror"
                  >Phone number is required.</small
                >
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Mother's Contact"
                invalid-feedback="Please enter correct phone number."
              >
                <vue-tel-input
                  :inputOptions="vueTelOptions"
                  @input="CheckMContact"
                  :dropdownOptions="dropOptions"
                  v-model="myObj.contact_mother"
                ></vue-tel-input>
                <small class="mr-1 text-danger" v-if="mphoneerror"
                  >Phone number is required.</small
                >
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Student's Contact"
                invalid-feedback="Please enter correct phone number."
              >
                <vue-tel-input
                  :inputOptions="vueTelOptions"
                  :dropdownOptions="dropOptions"
                  @input="CheckContact"
                  v-model="myObj.contact_student"
                ></vue-tel-input>
                <small class="mr-1 text-danger" v-if="phoneerror"
                  >Phone number is required.</small
                >
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="">
          <h3 class="">Emergency Information</h3>
          <hr />
          <b-row>
            <b-col md="12">
              <b-form-group label="Name" invalid-feedback="Name is required.">
                <b-form-input
                  ref="emerName"
                  placeholder="Enter name."
                  v-model="myObj.emergency_name"
                />
                <!-- @focusout="CheckEmerName()" -->
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Relation"
                invalid-feedback="Relation is required."
              >
                <b-form-input
                  ref="emerRel"
                  placeholder="Enter relation."
                  v-model="myObj.emergency_relationship"
                />
                <!-- @focusout="CheckEmerRelation()" -->
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Emergency contact"
                invalid-feedback="Emergency Contact is required."
              >
                <vue-tel-input
                  v-model="myObj.emergency_contact"
                  :inputOptions="emerOpt"
                  :dropdownOptions="dropOptions"
                  @input="CheckEmerContact"
                ></vue-tel-input>
                <small class="mr-1 text-danger" v-if="emerPhoneerror"
                  >Phone number is required.</small
                >
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="">
          <h3 class="">Other Information</h3>
          <hr />
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Allergies"
                invalid-feedback="Allergies is required."
              >
                <b-form-input
                  placeholder="Enter allergies."
                  v-model="myObj.allergies"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group label="Note" invalid-feedback="Note is required.">
                <b-form-textarea
                  id="textarea-default"
                  placeholder="Enter note"
                  rows="3"
                  v-model="myObj.remarks"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col lg="7" md="8">
        <b-button block variant="success" @click="shortcutSubmit()">
          Save
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BMediaAside,
  BImg,
  BMedia,
  BLink,
  BSpinner,
  BBadge,
  BFormFile,
  BFormTextarea,
  BProgress,
  BProgressBar,
  BFormCheckbox,
  BSidebar,
  BForm,
  BInputGroupPrepend,
  BInputGroup,
  BTable,
  VBTooltip,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BButton,
    BMediaAside,
    BImg,
    BMedia,
    BLink,
    BSpinner,
    BBadge,
    BFormFile,
    BFormTextarea,
    BProgress,
    BProgressBar,
    BFormCheckbox,
    BSidebar,
    BForm,
    BInputGroupPrepend,
    BInputGroup,
    BTable,
    BAvatar,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      campLoading: false,
      dataloading: false,
      admittedSections: [],
      currentSections: [],
      classes: [],
      genders: ["Male", "Female"],
      religions: ["ISLAM", "CHRISTIANITY", "HINDUISM", "BUDDHISM", "SIKHISM"],

      myObj: {
        id: 0,
        grNo: null,
        family_code: null,
        name: null,
        father_name: null,
        gender: null,
        picture: null,
        date_of_birth: null,
        date_of_birth_words: null,
        date_of_admission: null,
        blood_group: null,
        nationality: null,
        religion: null,
        place_of_birth: null,
        mother_tongue: null,
        address_current: null,
        address_permanent: null,
        contact_student: null,
        contact_father: null,
        contact_mother: null,
        contact_home: null,
        contact_tutor: null,
        last_institute: null,
        last_class: null,
        last_session: null,
        last_result: null,
        last_note: null,
        class_admitted: 0,
        cls_admitted: null,
        class_current: 0,
        cls_current: null,
        section_admitted: 0,
        section_current: 0,
        roll_number: null,
        house: null,
        rfid_number: null,
        bform_number: null,
        date_of_registraion: null,
        date_of_readmission: null,
        father_cnic: null,
        father_education: null,
        father_occupation: null,
        monthly_income: null,
        office_phone: null,
        office_address: null,
        father_email: null,
        father_employer: null,
        mother_name: null,
        mother_cnic: null,
        mother_education: null,
        mother_occupation: null,
        mother_office_phone: null,
        mother_office_address: null,
        mother_email: null,
        mother_employer: null,
        guardian_name: null,
        guardian_cnic: null,
        guardian_education: null,
        guardian_occupation: null,
        guardian_office_phone: null,
        guardian_office_address: null,
        guardian_email: null,
        guardian_employer: null,
        guardian_relationship: null,
        emergency_name: null,
        emergency_contact: null,
        emergency_relationship: null,
        physical_handicap: null,
        allergies: null,
        remarks: null,
        monthly_fee: 0,
        discount_percentage: 0,
        challan_due_date: null,
        docs: "",
        status: "present",
        campusID: this.$store.state.userData.cId,
        campus_Admitted: this.$store.state.userData.cId,
        testPayment: false,
        testPassed: false,
        testCompleted: false,
        sessionID: 0,
        currency: "Rs",
        grNoLocal: null,
        receiptNo: 0,
      },
      fphoneerror: false,
      mphoneerror: false,
      phoneerror: false,
      emerPhoneerror: false,
      vueTelOptions: { placeholder: "Enter contact number" },
      dropOptions: {
        disabled: false,
        showDialCodeInList: true,
        showFlags: true,
      },
      emerOpt: { placeholder: "Enter emergency contact" },
      classesOptions: [],
      check: 1,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      campuses: [],
    };
  },
  created() {
    if (
      this.reportDomain == "myskoolhpgs" &&
      this.$store.state.loggedUser.campusID == 1 &&
      ["Administrator", "administrator"].includes(
        this.$store.state.loggedUser.meta_role
      )
    ) {
      this.LoadCampuses();
      this.LoadData();
      this.LoadSettingsAll();
    } else {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    }
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    numberToWords(number) {
      const words = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ];
      const tens = [
        "",
        "",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ];

      if (number < 20) return words[number];
      if (number < 100)
        return (
          tens[Math.floor(number / 10)] +
          (number % 10 === 0 ? "" : " " + words[number % 10])
        );
      if (number < 1000)
        return (
          this.numberToWords(Math.floor(number / 100)) +
          " Hundred" +
          (number % 100 === 0 ? "" : " and " + this.numberToWords(number % 100))
        );

      // For this specific use case, we assume the year is always in the 2000s
      return (
        "Two Thousand" +
        (number % 1000 !== 0 ? " " + this.numberToWords(number % 1000) : "")
      );
    },
    formatDate() {
      if (this.myObj.date_of_birth) {
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const dayNames = [
          "",
          "First",
          "Second",
          "Third",
          "Fourth",
          "Fifth",
          "Sixth",
          "Seventh",
          "Eighth",
          "Ninth",
          "Tenth",
          "Eleventh",
          "Twelfth",
          "Thirteenth",
          "Fourteenth",
          "Fifteenth",
          "Sixteenth",
          "Seventeenth",
          "Eighteenth",
          "Nineteenth",
          "Twentieth",
          "Twenty First",
          "Twenty Second",
          "Twenty Third",
          "Twenty Fourth",
          "Twenty Fifth",
          "Twenty Sixth",
          "Twenty Seventh",
          "Twenty Eighth",
          "Twenty Ninth",
          "Thirtieth",
          "Thirty First",
        ];

        const [yearStr, monthStr, dayStr] = this.myObj.date_of_birth.split("-");
        const day = parseInt(dayStr, 10);
        const year = parseInt(yearStr, 10);
        const monthIndex = parseInt(monthStr, 10) - 1;

        this.myObj.date_of_birth_words = `${dayNames[day]} ${
          months[monthIndex]
        } ${this.numberToWords(year)}`;
      } else this.myObj.date_of_birth_words = "";
    },

    setData() {
      this.myObj.class_admitted = 0;
      this.myObj.section_admitted = 0;
      this.myObj.class_current = 0;
      this.myObj.section_current = 0;
      this.admittedSections = [];
      this.currentSections = [];
      this.classes = [];
      this.classesOptions = [];
      this.LoadData();
      this.CheckCampus();
      //   this.LoadInit(this.myObj.campusID);
    },
    async LoadCampuses() {
      this.campLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.campuses = await this.get(obj);
      // console.log(this.campuses);
      this.campLoading = false;
    },
    async LoadData() {
      this.dataloading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClassesAll?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.myObj.campusID +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);

      this.classesOptions = this.classes.map((el) => el.cls);
      this.dataloading = false;
    },
    async LoadSettingsAll() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettingsAll?db=" +
          this.$store.state.userData.db,
        body: ["local_gr", "receipt_number"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      this.schGr = res.find((el) => el.key == "local_gr");
      this.spNo = res.find((el) => el.key == "receipt_number");
    },
    async LoadInit(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "Students/AddInit?campusId=" +
          id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      let initData = await this.get(obj);
      // console.log("data:", initData);
      this.myObj.grNo = initData.newG;
      this.myObj.family_code = initData.fCode;
    },

    CheckClassAdmit() {
      var elem = this.$refs["class_admitted"];
      if (this.myObj.class_admitted == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAdmitSec() {
      var elem = this.$refs["section_admitted"];
      if (this.myObj.section_admitted == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckClassCurrent() {
      var elem = this.$refs["class_current"];
      if (this.myObj.class_current == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCurrentSec() {
      var elem = this.$refs["section_current"];
      if (this.myObj.section_current == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "" || this.myObj.name < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckFName() {
      var elem = this.$refs["fname"];
      if (this.myObj.father_name == "" || this.myObj.father_name < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGender() {
      var elem = this.$refs["gender"];
      if (!this.myObj.gender) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDob() {
      var elem = this.$refs["dob"];
      if (this.myObj.date_of_birth == "" || this.myObj.date_of_birth == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCampus() {
      var elem = this.$refs["camp"];
      if (this.myObj.campusID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAdmCampus() {
      var elem = this.$refs["adm_camp"];
      if (this.myObj.campus_Admitted == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAdmDate() {
      var elem = this.$refs["doa"];
      if (!this.myObj.date_of_admission) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckFContact(s, e) {
      if (this.myObj.contact_father != "") {
        if (e.valid) {
          this.fphoneerror = false;
          this.myObj.contact_father = e.number;
        } else {
          this.fphoneerror = true;
        }
      } else {
        this.fphoneerror = false;
      }
    },
    CheckMContact(s, e) {
      if (this.myObj.contact_mother != "") {
        if (e.valid) {
          this.mphoneerror = false;
          this.myObj.contact_mother = e.number;
        } else {
          this.mphoneerror = true;
        }
      } else {
        this.mphoneerror = false;
      }
    },
    CheckContact(s, e) {
      if (this.myObj.contact_student != "") {
        if (e.valid) {
          this.phoneerror = false;
          this.myObj.contact_student = e.number;
        } else {
          this.phoneerror = true;
        }
      } else {
        this.phoneerror = false;
      }
    },
    CheckCnic() {
      var elem = this.$refs["fCnic"];
      if (this.myObj.father_cnic == null || this.myObj.father_cnic == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckMCnic() {
      var elem = this.$refs["mCnic"];
      if (this.myObj.mother_cnic == null || this.myObj.mother_cnic == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmail() {
      var elem = this.$refs["fEmail"];
      if (this.myObj.father_email == null || this.myObj.father_email == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGCnic() {
      var elem = this.$refs["gCnic"];
      if (this.myObj.guardian_cnic == null || this.myObj.guardian_cnic == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGEmail() {
      var elem = this.$refs["gEmail"];
      if (
        this.myObj.guardian_email == null ||
        this.myObj.guardian_email == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerName() {
      var elem = this.$refs["emerName"];
      if (
        this.myObj.emergency_name == null ||
        this.myObj.emergency_name == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerRelation() {
      var elem = this.$refs["emerRel"];
      if (
        this.myObj.emergency_relationship == null ||
        this.myObj.emergency_relationship == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerContact(s, e) {
      // console.log(e);
      if (this.myObj.emergency_contact != "") {
        // console.log(e);
        if (e.valid) {
          this.emerPhoneerror = false;
          this.myObj.emergency_contact = e.number;
          // console.log(this.myObj.emergency_contact);
        } else {
          this.emerPhoneerror = true;
        }
      } else {
        this.emerPhoneerror = false;
      }
    },
    CheckGrNo() {
      var elem = this.$refs["grNo"];
      if (this.myObj.grNo == null || this.myObj.grNo.toString().trim() == "") {
        return (elem.state = false);
      } else {
        if (this.reportDomain == "myskoolhpgs") {
          let x = this.myObj.grNo.toString().replace(/\s+/g, "");
          if (x) {
            this.myObj.grNo = x;
            return (elem.state = true);
          }
          return (elem.state = false);
        }
        return (elem.state = true);
      }
    },
    FillSections(text) {
      this.classes.forEach((elem) => {
        if (elem.cls.id === this.myObj.class_admitted) {
          if (elem.sections.length != 0) {
            this.admittedSections = elem.sections;
          } else {
            // console.log("null");
            this.admittedSections = [];
          }
        }
      });
      if (text !== "filled") this.myObj.section_admitted = 0;
    },
    async FillSectionsCurrent(text) {
      this.classes.forEach((elem) => {
        if (elem.cls.id === this.myObj.class_current) {
          if (elem.sections.length != 0) {
            this.currentSections = elem.sections;
          } else {
            // console.log("null");
            this.currentSections = [];
          }
        }
      });
      if (text !== "filled") this.myObj.section_current = 0;
    },
    async shortcutSubmit(val) {
      // console.log(val);
      // console.log("obj", this.myObj);
      this.check = 0; // due to save button click
      this.CheckGrNo();
      this.CheckName();
      this.CheckFName();
      this.CheckGender();
      this.CheckDob();
      this.CheckAdmCampus();
      this.CheckCampus();
      this.CheckAdmDate();
      this.CheckClassAdmit();
      this.CheckAdmitSec();
      this.CheckClassCurrent();
      this.CheckCurrentSec();
      if (!this.myObj.contact_father) this.fphoneerror = true;

      if (this.CheckGrNo() == false) {
        this.$bvToast.toast(
          `Please enter the ${
            ["myskoolhpgs", "myskoolprofectus"].includes(this.reportDomain)
              ? "CID"
              : "GR number "
          }`,
          {
            title: "Error!",
            variant: "danger",
            solid: true,
          }
        );
        this.check = 1;
      } else if (this.CheckName() == false) {
        this.$bvToast.toast("Please enter the name", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckFName() == false) {
        this.$bvToast.toast("Please enter the father name", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckGender() == false) {
        this.$bvToast.toast("Please enter gender", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckDob() == false) {
        this.$bvToast.toast("Please enter date of birth", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckAdmCampus() == false) {
        this.$bvToast.toast("Please select the admitted campus", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckCampus() == false) {
        this.$bvToast.toast("Please select the campus", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckAdmDate() == false) {
        this.$bvToast.toast("Please enter date of admission", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckClassAdmit() == false) {
        this.$bvToast.toast("Please enter class (admitted)", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckAdmitSec() == false) {
        this.$bvToast.toast("Please enter section (admitted)", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckClassCurrent() == false) {
        this.$bvToast.toast("Please enter class (current)", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckCurrentSec() == false) {
        this.$bvToast.toast("Please enter section (current)", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.fphoneerror || this.mphoneerror || this.phoneerror) {
        this.$bvToast.toast("Invalid contact details entered", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.emerPhoneerror) {
        this.$bvToast.toast("Invalid emergency contact entered", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else {
        this.$bvModal.show("modal-login");

        this.myObj.status = "present";
        this.myObj.address_permanent = this.myObj.address_current;

        if (this.myObj.date_of_birth == "") {
          this.myObj.date_of_birth = null;
        }
        if (!this.myObj.date_of_admission) {
          this.myObj.date_of_admission = new Date().toJSON();
        }
        this.myObj.date_of_registraion = this.myObj.date_of_admission;

        if (this.myObj.grNoLocal == "") {
          this.myObj.grNoLocal = null;
        }
        // if (this.myObj.receiptNo) {
        //   this.myObj.receiptNo = parseInt(this.myObj.receiptNo);
        // } else this.myObj.receiptNo = 0;

        this.myObj.grNo = this.myObj.grNo.toString();
        // f.code equal to gr
        let x = parseInt(this.myObj.grNo);
        this.myObj.family_code = isNaN(x) ? 0 : x;

        this.myObj.monthly_income = this.myObj.monthly_income
          ? parseInt(this.myObj.monthly_income)
          : 0;

        let data = {
          student: this.myObj,
          customFee: [],
          subjectsEnrollnment: [],
          admissionTestData: {
            id: 0,
            sID: 0,
            campusID: this.myObj.campusID,
            testDate: null,
            testStatus: "",
            interviewDate: null,
            interviewStatus: "",
            remarks: "",
            documents: "",
          },
        };

        var status = await this.post({
          url:
            this.$store.state.domain +
            // "students/SaveStudent?db=" +
            "students/SaveStudentWithData?db=" +
            this.$store.state.userData.db +
            "&sms=false&inqID=",
          body: data,
          message: "Student saved successfully.",
          context: this,
          token: this.$store.state.userData.token,
          savingStudent: true,
        });
        if (status) {
          this.myObj = {
            id: 0,
            grNo: null,
            family_code: null,
            name: null,
            father_name: null,
            gender: null,
            picture: null,
            date_of_birth: null,
            date_of_birth_words: null,
            date_of_admission: null,
            blood_group: null,
            nationality: null,
            religion: null,
            place_of_birth: null,
            mother_tongue: null,
            address_current: null,
            address_permanent: null,
            contact_student: null,
            contact_father: null,
            contact_mother: null,
            contact_home: null,
            contact_tutor: null,
            last_institute: null,
            last_class: null,
            last_session: null,
            last_result: null,
            last_note: null,
            class_admitted: 0,
            cls_admitted: null,
            class_current: 0,
            cls_current: null,
            section_admitted: 0,
            section_current: 0,
            roll_number: null,
            house: null,
            surname: null,
            rfid_number: null,
            bform_number: null,
            date_of_registraion: null,
            date_of_readmission: null,
            father_cnic: null,
            father_education: null,
            father_occupation: null,
            monthly_income: null,
            office_phone: null,
            office_address: null,
            father_email: null,
            father_employer: null,
            mother_name: null,
            mother_cnic: null,
            mother_education: null,
            mother_occupation: null,
            mother_office_phone: null,
            mother_office_address: null,
            mother_email: null,
            mother_employer: null,
            guardian_name: null,
            guardian_cnic: null,
            guardian_education: null,
            guardian_occupation: null,
            guardian_office_phone: null,
            guardian_office_address: null,
            guardian_email: null,
            guardian_employer: null,
            guardian_relationship: null,
            emergency_name: null,
            emergency_contact: null,
            emergency_relationship: null,
            physical_handicap: null,
            allergies: null,
            remarks: null,
            monthly_fee: 0,
            discount_percentage: 0,
            challan_due_date: null,
            docs: "",
            status: "present",
            campusID: this.myObj.campusID,
            campus_Admitted: this.myObj.campus_Admitted,
            testPayment: false,
            testPassed: false,
            testCompleted: false,
            sessionID: 0,
            currency: "Rs",
            grNoLocal: null,
            receiptNo: 0,
          };
          this.documents = [];
          this.$bvModal.hide("modal-login");

          this.check = 1;
          this.$router.go(-1);
          //   this.cleanStates();
        } else {
          this.$bvModal.hide("modal-login");
          this.check = 1;
        }
      }
    },

    cleanStates() {
      var elem = this.$refs["camp"];
      elem.state = undefined;
      var elem = this.$refs["name"];
      elem.state = undefined;
      var elem = this.$refs["fname"];
      elem.state = undefined;
      var elem = this.$refs["dob"];
      elem.state = undefined;
      var elem = this.$refs["gender"];
      elem.state = undefined;
      var elem = this.$refs["class_admitted"];
      elem.state = undefined;
      var elem = this.$refs["section_admitted"];
      elem.state = undefined;
      var elem = this.$refs["class_current"];
      elem.state = undefined;
      var elem = this.$refs["section_current"];
      elem.state = undefined;
      this.fphoneerror = false;
      this.mphoneerror = false;
      this.phoneerror = false;
      this.emerPhoneerror = false;
      this.admittedSections = [];
      this.currentSections = [];
    },
  },
};
</script>
<style></style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
