<template>
  <b-row align-h="center" v-if="dataLoading">
    <b-spinner
      class="m-5"
      style="width: 4rem; height: 4rem"
      type="grow"
      variant="primary"
      label="Spinning"
    ></b-spinner>
  </b-row>
  <div v-else>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Obtained Marks *"
              invalid-feedback="marks is required."
              ref="marks"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter obtained marks here"
                ref="marks"
                v-model="marksObj.obtained_marks"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1 fullWidth"
              @click="Add()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-if="request == false"> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility4"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Publish Result</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility4 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Publish From"
              invalid-feedback="Date is required."
              ref="pub_from"
            >
              <flat-pickr
                :config="config2"
                ref="pub_from"
                v-model="pubObj.dtFrom"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Publish Till"
              invalid-feedback="Date is required."
              ref="pub_till"
            >
              <flat-pickr
                :config="config2"
                ref="pub_till"
                v-model="pubObj.dtTo"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="publish()"
              :disabled="publishing"
              block
            >
              <b-spinner v-if="publishing" small type="grow" />
              <span v-else> {{ isPublish ? "Unpublish" : "Publish" }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Fee Reward</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Class"
              invalid-feedback="Class is required."
              ref="reward_class"
            >
              <v-select
                v-model="rewardObj.clsID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classesOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                input-id="id"
                ref="reward_class"
                @input="FillRewardSec()"
                @search:blur="getFee()"
                placeholder="Select class."
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Section"
              invalid-feedback="section is required."
              ref="reward_sec"
            >
              <v-select
                ref="reward_sec"
                v-model="rewardObj.secIDs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="rewardSections"
                :reduce="(val) => val.id"
                label="section"
                :clearable="false"
                placeholder="Select section."
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Class Statndard Fee"
              invalid-feedback="Standard fee is required."
              ref="fee"
            >
              <b-form-input
                :disabled="true"
                type="number"
                placeholder="Enter class standard fee"
                ref="fee"
                v-model="rewardObj.fee"
                @input="CheckNum()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" class="mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1"
              @click="ApplyDiscount()"
              :disabled="saving"
              block
            >
              <b-spinner v-if="saving" small type="grow" />
              <span v-else> Apply Discount </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Result Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12" v-if="reportDomain == 'myskoolmerittoexcel'">
            <b-form-group
              label="Absent Marks Deduction"
              invalid-feedback="marks is required."
              ref="marks"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter absent marks deduction here"
                ref="marks"
                v-model="settObj.absentMarks"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required."
              ref="date"
            >
              <flat-pickr
                :config="config"
                ref="date"
                v-model="settObj.rangeDate"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>

          <b-col md="12" v-if="lockObj && lockObj.valueBool">
            <b-form-group
              label="Auto Lock after (days)"
              invalid-feedback="Day is required."
              ref="lock_days"
            >
              <b-button
                v-for="n in 10"
                :key="n"
                :variant="
                  lockObj.valueNumber == n ? 'primary' : 'outline-primary'
                "
                class="mr-50 mb-50"
                @click="lockObj.valueNumber = n"
              >
                {{ n }}
              </b-button>
            </b-form-group>
          </b-col>

          <b-col md="12" v-if="termDate">
            <b-form-group
              label="Exam Date"
              invalid-feedback="Exam Date is required."
              ref="exam_date"
            >
              <b-form-input placeholder="Enter date" v-model="termDate.value" />
            </b-form-group>
          </b-col>

          <b-col
            v-if="logoObj"
            md="12"
            class="d-flex align-items-center justify-content-between mb-1"
          >
            <h4 class="mb-0 mr-1">Show Logo</h4>
            <b-form-checkbox v-model="logoObj.valueBool" switch />
          </b-col>

          <b-col
            v-if="footerObj"
            md="12"
            class="d-flex align-items-center justify-content-between mb-1"
          >
            <h4 class="mb-0 mr-1">Show Footer</h4>
            <b-form-checkbox v-model="footerObj.valueBool" switch />
          </b-col>

          <b-col
            v-if="stampObj"
            md="12"
            class="d-flex align-items-center justify-content-between"
          >
            <h4 class="mb-0 mr-1">Show Stamp</h4>
            <b-form-checkbox v-model="stampObj.valueBool" switch />
          </b-col>
          <b-col md="12" v-if="stampObj && stampObj.valueBool">
            <b-form-group
              label="Stamp Image"
              invalid-feedback="Stamp Image is required"
              required
              ref="stamp"
            >
              <b-spinner
                v-if="stLoading"
                type="grow"
                label="Loading..."
                variant="primary"
                class="m-5"
              />
              <b-img
                v-else-if="!stampObj.value"
                ref="previewEl"
                src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                @click="$refs.stampImg.click()"
                style="border-radius: 10px"
                height="120px"
                width="120px"
              />
              <div v-else class="d-flex align-items-center">
                <b-img
                  :src="stampObj.value"
                  style="border-radius: 10px"
                  height="120px"
                  width="120px"
                />
                <feather-icon
                  icon="Trash2Icon"
                  size="22"
                  class="text-danger cursor-pointer ml-1"
                  @click="removeStamp()"
                />
              </div>
              <input
                type="file"
                id="stampImg"
                hidden
                ref="stampImg"
                @change="addStamp()"
                accept="image/*"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="courseObj"
            md="12"
            class="d-flex align-items-center justify-content-between mb-50"
          >
            <h4 class="mb-0 mr-1">Course Marks</h4>
            <b-form-checkbox v-model="courseObj.valueBool" switch />
          </b-col>
          <b-col md="12" v-if="courseObj && courseObj.valueBool">
            <!-- label="Exam Type" -->
            <b-form-group
              invalid-feedback="Exam Type is required."
              ref="c_exam"
            >
              <v-select
                v-model="courseObj.valueNumber"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="examType"
                :reduce="(val) => val.id"
                label="examType"
                :clearable="false"
                placeholder="Select exam type"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" class="mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              @click="saveMax()"
              :disabled="saving"
              block
            >
              <b-spinner v-if="saving" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility5"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Average / Grace Marks</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility5 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Class"
              invalid-feedback="Class is required."
              ref="grace_class"
            >
              <v-select
                v-model="graceObj.classID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classesOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                input-id="id"
                ref="grace_class"
                @input="FillGraceSec()"
                placeholder="Select class."
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Section"
              invalid-feedback="section is required."
              ref="grace_sec"
            >
              <v-select
                ref="grace_sec"
                v-model="graceObj.sectionID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="graceSections"
                :reduce="(val) => val.id"
                label="section"
                :clearable="false"
                placeholder="Select section."
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Session"
              invalid-feedback="Session is required."
              ref="grace_session"
            >
              <v-select
                ref="grace_session"
                v-model="graceObj.sessionID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sessions"
                :reduce="(val) => val.id"
                label="session"
                :clearable="false"
                placeholder="Select session."
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Update Marks For"
              invalid-feedback="Exam type is required."
              ref="grace_examType"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                ref="grace_examType"
                v-model="graceObj.examIDs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="examType"
                :reduce="(val) => val.id"
                label="examType"
                :clearable="false"
                placeholder="Select exam type to update"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Based On"
              invalid-feedback="Based On is required."
              ref="based"
            >
              <v-select
                ref="based"
                v-model="graceObj.basedOn"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="examType"
                :reduce="(val) => val.id"
                label="examType"
                :clearable="false"
                placeholder="Select exam type"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Type"
              invalid-feedback="Type is required."
              ref="type"
            >
              <v-select
                ref="type"
                v-model="graceObj.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                :reduce="(val) => val.value"
                label="text"
                :clearable="false"
                placeholder="Select"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" class="mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1"
              @click="ApplyGrace()"
              :disabled="gracing"
              block
            >
              <b-spinner v-if="gracing" small type="grow" />
              <span v-else>Update</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row align-h="start" class="text-left align-items-center mt-1">
        <b-col md="2">
          <b-form-group
            label="Class"
            invalid-feedback="Class is required."
            ref="class"
          >
            <v-select
              v-model="myObj.clsID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="classesOptions"
              :clearable="false"
              label="name"
              :reduce="(val) => val.id"
              input-id="id"
              ref="class"
              @input="FillSections()"
              placeholder="Select class."
              @search:blur="checkLock()"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Section"
            invalid-feedback="section is required."
            ref="sec"
          >
            <v-select
              ref="sec"
              v-model="myObj.sec"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="currentSections"
              :reduce="(val) => val.id"
              label="section"
              :clearable="false"
              placeholder="Select section."
              @input="checkLock()"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
            label="Exam Type"
            invalid-feedback="Exam type is required."
            ref="examType"
          >
            <v-select
              multiple
              :closeOnSelect="false"
              ref="examType"
              v-model="myObj.examTypeID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="examType"
              :reduce="(val) => val.id"
              label="examType"
              :clearable="false"
              placeholder="Select exam type."
              @input="checkLock()"
            />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group
            label="Session"
            invalid-feedback="Session is required."
            ref="session"
          >
            <v-select
              ref="session"
              v-model="myObj.sessionID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sessions"
              :reduce="(val) => val.id"
              label="session"
              :clearable="false"
              placeholder="Select session."
              @input="checkLock()"
            />
          </b-form-group>
        </b-col>
        <b-col md="">
          <b-button
            style=""
            variant="success"
            class="mr-1 mb-50"
            @click="show()"
            :disabled="this.iShow"
          >
            <b-spinner v-if="this.iShow" small type="grow" />
            <span v-if="!this.iShow">Show</span>
          </b-button>

          <template v-if="approveObj && approveObj.valueBool">
            <b-button
              v-if="isApproved"
              variant="danger"
              class="mr-1 mb-50"
              @click="approveRes(false)"
              :disabled="approving"
            >
              <b-spinner v-if="approving" small type="grow" />
              <span v-else>Undo</span>
            </b-button>

            <b-button
              v-else
              variant="success"
              class="mr-1 mb-50"
              @click="approveRes(true)"
              :disabled="approving"
            >
              <b-spinner v-if="approving" small type="grow" />
              <span v-else>Approve</span>
            </b-button>
          </template>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-50 mb-50"
            @click="print('')"
            :disabled="this.iPrint"
          >
            <!-- @click="checkPrint('')" -->
            <feather-icon
              v-if="!this.iPrint"
              icon="PrinterIcon"
              class="mr-50"
            />
            <b-spinner v-if="this.iPrint" small type="grow" />
            <span v-if="!this.iPrint">Print</span>
            <span v-if="!this.iPrint && selectedIDs.length > 0"
              >({{ selectedIDs.length }})</span
            >
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-1 mb-50"
            v-b-tooltip.hover.top
            title="Publish"
            :disabled="loadingStatus"
            @click="CheckStatus()"
          >
            <b-spinner v-if="loadingStatus" small />
            <feather-icon v-else icon="GlobeIcon" class="" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-1 mb-50"
            v-b-tooltip.hover.top
            :title="isLock ? 'Unlock Result' : 'Lock Result'"
            :disabled="locking"
            @click="lock()"
          >
            <b-spinner v-if="locking" small />
            <feather-icon
              v-else
              :icon="isLock ? 'LockIcon' : 'UnlockIcon'"
              class=""
            />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-1 mb-50"
            v-b-tooltip.hover.top
            title="Send WhatsApp"
            :disabled="sending"
            @click="sendMessage()"
          >
            <b-spinner v-if="sending" small />
            <feather-icon v-else icon="MailIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-1 mb-50"
            v-b-tooltip.hover.top
            title="Fee Reward"
            @click="openSettings()"
          >
            <feather-icon icon="DollarSignIcon" class="" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-1 mb-50"
            v-b-tooltip.hover.top
            title="Settings"
            @click="openMax()"
          >
            <feather-icon icon="SettingsIcon" class="" />
          </b-button>
          <b-button
            variant="outline-secondary"
            v-b-tooltip.hover.top
            title="Export"
            class="btn-icon mr-1 mb-50"
            :disabled="exporting"
            @click="exportData()"
          >
            <b-spinner v-if="exporting" small />
            <feather-icon v-else icon="UploadIcon" class="" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-1 mb-50"
            v-b-tooltip.hover.top
            title="Average / Grace Marks"
            @click="openGrace()"
          >
            <feather-icon icon="AwardIcon" class="" />
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-1"
            v-b-tooltip.hover.top
            title="Attendance"
            @click="openAttendance()"
          >
            <feather-icon icon="CheckCircleIcon" class="" />
          </b-button> -->
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="showGrid" class="mt-2">
      <!-- <div
          class="d-flex align-items-center justify-content-between"
          style="width: 100%"
        >
          <b-card-title v-if="false" class="m-0">Result</b-card-title>
        </div>
         -->
      <b-row class="mb-1">
        <b-col>
          <div class="d-flex align-items-center justify-content-start">
            <b-button
              v-if="selectedIDs.length > 0"
              variant="outline-secondary"
              class="btn-icon mr-50"
              v-b-tooltip.hover.top
              title="Clear Selection"
              @click="clear()"
            >
              <feather-icon icon="XIcon" />
            </b-button>
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block"
              placeholder="Search"
            />
            <b-button
              v-if="sheetObj && sheetObj.valueBool && items.length > 0"
              variant="success"
              class="btn-icon ml-50"
              :disabled="sheetLoading || gridLoading"
              @click="dataSheet()"
            >
              <!-- @click="printTable()" -->
              <b-spinner v-if="sheetLoading" small type="grow" />
              <span v-else>DataSheet</span>
            </b-button>
            <!-- <b-button style="" variant="outline-success" class="mr-1">
              <span class="text-nowrap"
                >Passed
                <b-badge variant="success">
                  {{ passCount }}
                </b-badge></span
              >
            </b-button>
            <b-button style="" variant="outline-danger" class="">
              <span class="text-nowrap"
                >Failed
                <b-badge variant="danger">
                  {{ failCount }}
                </b-badge></span
              >
            </b-button> -->
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-table
          ref="selectableTable"
          :items="filters"
          :fields="fields"
          show-empty
          :busy="gridLoading"
          responsive
          hover
          small
          selectable
          select-mode="multi"
          @row-selected="onRowSelected"
        >
          <template #table-busy>
            <div
              class="d-flex justify-content-center mb-3"
              style="margin-top: 50px"
            >
              <b-spinner
                style="width: 3rem; height: 3rem"
                type="grow"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
          </template>
          <template #cell(grNo)="data">
            <b-badge variant="light-primary">
              {{ data.item.grNo }}
            </b-badge>
          </template>
          <template #cell(name)="data">
            <b-badge variant="light-primary">
              {{ data.item.name }}
            </b-badge>
          </template>
          <template #cell(father_name)="data">
            <b-badge variant="light-primary">
              {{ data.item.father_name }}
            </b-badge>
          </template>
          <template #cell(totalMarks)="data">
            <b-badge variant="light-primary">
              {{ data.item.totalMarks }}
            </b-badge>
          </template>
          <template #cell(obtainedMarks)="data">
            <b-badge variant="light-primary">
              {{ data.item.obtainedMarks }} / {{ data.item.totalMarks }}
            </b-badge>
          </template>
          <template #cell(percentage)="data">
            <b-badge variant="light-primary">
              {{ data.item.percentage }} <span>%</span>
            </b-badge>
          </template>

          <template #cell(rank)="data">
            <b-badge
              :variant="
                data.item.rank == '1st' ||
                data.item.rank == '2nd' ||
                data.item.rank == '3rd'
                  ? 'light-success'
                  : 'light-primary'
              "
            >
              {{ data.item.rank }}
            </b-badge>
          </template>

          <template #cell(grade)="data">
            <b-badge
              v-if="data.item.grade"
              :variant="
                data.item.grade.toLowerCase() == 'fail' ||
                data.item.grade.toLowerCase() == 'failed' ||
                data.item.grade.toLowerCase() == 'f'
                  ? 'light-danger'
                  : 'light-primary'
              "
            >
              {{ data.item.grade }}
            </b-badge>
          </template>
          <template #cell(result)="data">
            <b-badge
              :variant="
                data.item.result == 'PASSED' ? 'light-success' : 'light-danger'
              "
            >
              {{ data.item.result }}
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              v-b-tooltip.hover.right
              title="Print"
              :disabled="iPrint && current == data.item.sID"
              @click="printSingle(data.item.sID)"
            >
              <b-spinner v-if="iPrint && current == data.item.sID" small />
              <feather-icon v-else icon="PrinterIcon" />
            </b-button>
            <b-button
              v-if="reportDomain == 'myskoolelixir'"
              variant="outline-primary"
              class="btn-icon ml-50"
              v-b-tooltip.hover.right
              title="Export Result"
              @click="expResult(data.item.sID)"
              :disabled="exportingRes"
            >
              <b-spinner
                v-if="exportingRes && currentExp == data.item.sID"
                small
              />
              <feather-icon v-else icon="DownloadIcon" />
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              v-b-tooltip.hover.top
              title="WhatsApp"
              @click="printSingle(data.item.sID)"
            >
              <b-img
                src="https://cdn.cloudious.net/file-1680171801807-163272375.svg"
                alt="pic"
              />
            </b-button> -->
          </template>

          <!-- <template #head(actions)="data">
            <div class="mr-5 text-right">
              <span>{{ data.label }}</span>
            </div>
          </template> -->
        </b-table>
      </b-row>
    </b-card>
    <b-card class="mt-2" v-if="showAtt">
      <b-row>
        <b-col md="12">
          <h3>
            {{ currentExam }} ({{ currentSession }})
            <!-- <span style="font-size: 1.25rem">({{ myObj.date }})</span> -->
          </h3>
        </b-col>
        <!-- Search -->
        <b-col cols="12" md="12">
          <div class="d-flex align-items-center justify-content-start">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              class="btn-icon mr-50"
              @click="Back()"
            >
              <feather-icon icon="ArrowLeftIcon" />
            </b-button>

            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-50"
              placeholder="Search..."
            />
            <b-form-group
              label="Total Days"
              invalid-feedback="Total Days is required."
              ref="total"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter total"
                ref="total"
                type="number"
                v-model="attData.totalDays"
                @keyup.enter="firstFocus()"
              />
            </b-form-group>
            <b-form-group
              label="Date"
              invalid-feedback="Date is required."
              ref="date"
              class="ml-1"
            >
              <flat-pickr
                :config="config"
                ref="date"
                v-model="attData.date"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
            <b-button
              variant="success"
              class="ml-1"
              @click="Save()"
              :disabled="request"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-table
          class="mt-1"
          ref="selectableTable"
          :items="filterAtt"
          :fields="newEntry ? attFields : attFields"
          show-empty
          :busy="attLoading"
          responsive
          hover
        >
          <template #table-busy>
            <div
              class="d-flex justify-content-center mb-3"
              style="margin-top: 50px"
            >
              <b-spinner
                style="width: 3rem; height: 3rem"
                type="grow"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
          </template>
          <template #cell(index)="data">
            <b-badge variant="light-primary">
              {{ attData.records.indexOf(data.item) + 1 }}
            </b-badge>
          </template>
          <template #cell(grNo)="data">
            <b-badge style="margin-inline: 2px" variant="light-primary">
              {{ data.item.grNo }}
            </b-badge>
          </template>
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  variant="light-primary"
                />
              </template>

              <b-link class="font-weight-bold d-block text-nowrap">
                {{ data.item.name }}
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary">
                  GR - {{ data.item.grNo }}
                </b-badge>
              </small>
            </b-media>
          </template>
          <template #cell(present)="data">
            <b-form-group
              label=""
              invalid-feedback="Invalid entry"
              :ref="'present' + data.index"
            >
              <b-form-input
                :id="'mid' + data.index"
                style="width: 80px"
                class="present-field"
                placeholder="Enter present days here"
                v-model="data.item.present"
                @input="checkValues(data.index)"
                @keyup.enter="moveFocus(data.index)"
              />
            </b-form-group>
          </template>

          <template #head(actions)="data">
            <div class="mr-5 text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(percentage)="data">
            <b-badge variant="light-primary">
              {{ data.item.obj.meta_subj_percenatge }} <span>%</span>
            </b-badge>
          </template>

          <template #cell(status)="data">
            <b-badge
              :variant="
                data.item.obj.status == 'PASSED'
                  ? 'light-success'
                  : 'light-danger'
              "
            >
              {{ data.item.obj.status }}
            </b-badge>
          </template>

          <!-- <template #cell(actions)="data">
              <div class="text-right">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="ViewDetails(data.item)"
                >
                  <feather-icon icon="EyeIcon" class="" />
                </b-button>
  
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Edit(data.item)"
                >
                  <feather-icon icon="EditIcon" class="" />
                </b-button>
  
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-secondary"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Print()"
                >
                  <feather-icon icon="PrinterIcon" />
                </b-button>
              </div>
            </template> -->
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";
import * as XLSX from "xlsx";

export default {
  components: {
    //Multiselect,
    BCard,
    BCardTitle,
    BRow,
    BBadge,
    BTable,
    BImg,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,
    BDropdown,
    BDropdownItem,
    vSelect,
    flatPickr,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    if (!this.$store.state.rights[this.$route.name]) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];

      // console.log(this.settObj);
      this.collection = this.$store.state.marksSelection;

      if (!Array.isArray(this.collection)) {
        this.$store.commit("setSelection", []);
      }
      let data = this.collection.find(
        (el) => el.campusID == this.$store.state.userData.cId
      );
      // console.log(data);
      if (data) {
        // examTypeID: data.examID == 0 ? [] : [data.examID],
        this.myObj = {
          campusID: data.campusID,
          clsID: data.clsID,
          sec: data.secID,
          examTypeID: data.examTypes ? data.examTypes : [],
          sessionID: data.sessionID,
          dtFrom: "",
          dtTo: "",
        };
        // console.log(this.myObj);
      }
      this.LoadData();
      this.loadSettings();
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.father_name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.grNo.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
    filterAtt: function () {
      return this.attData.records.filter((pro) => {
        return (
          pro.name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.grNo.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      rights: {},
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      collection: [],
      current: null,
      iPrint: false,
      iShow: false,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      config2: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      request: false,
      saving: false,
      settLoading: false,
      colorDep: [],
      visibility2: false,
      visibility3: false,
      visibility4: false,
      visibility5: false,
      pubObj: {
        campusID: this.$store.state.userData.cId,
        dtFrom: "",
        dtTo: "",
      },
      publishRange: null,
      publishing: false,
      isLock: false,
      isPublish: false,
      loadingStatus: false,
      locking: false,
      color: [
        "primary",
        "danger",
        "success",
        "warning",
        "info",
        "secondary",
        "primary",
        "danger",
        "success",
        "warning",
        "info",
        "secondary",
        "primary",
        "danger",
        "success",
        "warning",
        "info",
        "secondary",
        "primary",
        "danger",
        "success",
        "warning",
        "info",
        "secondary",
        "primary",
        "danger",
        "success",
        "warning",
        "info",
        "secondary",
        "primary",
        "danger",
        "success",
        "warning",
        "info",
        "secondary",
        "primary",
        "danger",
        "success",
        "warning",
        "info",
        "secondary",
        "primary",
        "danger",
        "success",
        "warning",
        "info",
        "secondary",
      ],

      sidebarTitle: "Add Balance",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      sheetLoading: false,
      index: null,
      passCount: 0,
      failCount: 0,
      fields: [
        { label: "GR #", key: "grNo" },
        { label: "name", key: "name" },
        { label: "Father name", key: "father_name" },
        // { label: "Total", key: "totalMarks" },
        { label: "Marks", key: "obtainedMarks" },
        { label: "Percentage", key: "percentage" },
        { label: "Rank", key: "rank" },
        { label: "Grade", key: "grade" },
        { label: "Result", key: "result" },
        { key: "actions", label: "actions" },
      ],

      items: [],
      accountOptions: [
        // {
        //   id: 1,
        //   code: 999,
        // },
      ],
      sections: [],
      secText: "Section",
      classes: [],
      classesOptions: [],
      currentSections: [],
      subjects: [],
      classText: "Class",
      sessions: [],
      sessionText: "Session",
      examType: [],
      examText: "Exam Type",
      selected: null,
      rangeDate: "",
      myObj: {
        campusID: this.$store.state.userData.cId,
        clsID: 0,
        sec: 0,
        examTypeID: [],
        sessionID: 0,
        dtFrom: "",
        dtTo: "",
        maxRank: 10,
        absentDeduction: 0,
      },
      absentMarks: 0,
      marksObj: {},
      settObj: this.$store.state.resultSetting,
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      timeout: 10000,
      showGrid: false,
      showDetils: false,
      subLoading: false,
      rewardObj: {
        fee: null,
        clsID: null,
        secIDs: null,
      },
      rewardSections: [],
      selectedIDs: [],
      attLoading: false,
      showAtt: false,
      attData: {
        totalDays: null,
        date: null,
        records: [
          {
            id: 1,
            name: "test",
            present: 72,
          },
          {
            id: 12,
            name: "testwq",
            present: 86,
          },
        ],
      },
      currentExam: "",
      currentSession: "",
      attFields: [
        // { label: "GR #", key: "grNo" },
        { label: "name", key: "name" },
        // { label: "Father name", key: "father_name" },
        { label: "days present", key: "present" },

        // { key: "actions", label: "actions" },
      ],
      exporting: false,
      exportingRes: false,
      currentExp: 0,
      graceObj: {
        classID: 0,
        sectionID: 0,
        sessionID: 0,
        examIDs: [],
        basedOn: 0,
        type: "",
      },
      graceSections: [],
      gracing: false,
      typeOptions: [
        { text: "Average", value: "average" },
        { text: "Grand", value: "grand" },
      ],
      examNames: [],
      lockObj: null,
      sending: false,
      approveObj: null,
      isApproved: false,
      approving: false,
      termDate: null,
      logoObj: null,
      footerObj: null,
      stampObj: null,
      courseObj: null,
      sheetObj: null,
      stLoading: false,
      sampleData: [
        {
          Gr: 1,
          Name: "Muhammad Ali",
          Marks: [
            {
              Subject: "English",
              Previous: "20/30",
              Current: "40/70",
              Total: "60/100",
              Perc: "60%",
              Grade: "A",
            },
            {
              Subject: "Maths",
              Previous: "30/30",
              Current: "40/70",
              Total: "70/100",
              Perc: "70%",
              Grade: "A",
            },
            {
              Subject: "Urdu",
              Previous: "30/30",
              Current: "40/70",
              Total: "70/100",
              Perc: "70%",
              Grade: "A",
            },
            {
              Subject: "Islamiyat",
              Previous: "30/30",
              Current: "40/70",
              Total: "70/100",
              Perc: "70%",
              Grade: "A",
            },
            {
              Subject: "Computer",
              Previous: "30/30",
              Current: "40/70",
              Total: "70/100",
              Perc: "70%",
              Grade: "A",
            },
            {
              Subject: "Science",
              Previous: "30/30",
              Current: "40/70",
              Total: "70/100",
              Perc: "70%",
              Grade: "A",
            },
            {
              Subject: "Urdu G.",
              Previous: "30/30",
              Current: "40/70",
              Total: "70/100",
              Perc: "70%",
              Grade: "A",
            },
          ],
          Summary: {
            Total: "600/750",
            Perc: "80%",
            Grade: "A",
            Rank: "-",
          },
        },
        // {
        //   Gr: 1,
        //   Name: "Ahmed",
        //   Marks: [
        //     {
        //       Subject: "English",
        //       Previous: "10/30",
        //       Current: "60/70",
        //       Total: "70/100",
        //       Perc: "70%",
        //       Grade: "A",
        //     },
        //     {
        //       Subject: "Maths",
        //       Previous: "25/30",
        //       Current: "40/70",
        //       Total: "65/100",
        //       Perc: "65%",
        //       Grade: "B",
        //     },
        //     {
        //       Subject: "Urdu",
        //       Previous: "22/30",
        //       Current: "40/70",
        //       Total: "70/100",
        //       Perc: "50%",
        //       Grade: "C",
        //     },
        //   ],
        //   Summary: {
        //     Total: "555/750",
        //     Perc: "80%",
        //     Grade: "C",
        //     Rank: "-",
        //   },
        // },
      ],
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({
      get: "get",
      post: "post",
      postObj: "postObj",
      put: "put",
      delete: "delete",
    }),

    printTable() {
      // Generate the HTML for the table
      let tableHTML = `
        <html>
          <head>
            <title>Datasheet</title>
            <style>
              /* Style for landscape orientation and fitting content */
              @page {
                size: landscape;
                margin: 1cm; /* Adjust margins as needed */
              }

              /* Table styles for printing */
              .styled-table {
                width: 100%;
                border-collapse: collapse;
                font-family: Arial, sans-serif;
                border: 1px solid #ebe9f1;
              }
              .styled-table th, .styled-table td {
                border: 1px solid #ebe9f1;
                padding: 10px 8px;
                text-align: center;
              }
              .p-1 {
                padding: 1rem;
              }
              th { background-color: #f2f2f2; font-weight: bold; }

              /* Scale content to fit page */
              body {
                transform: scale(0.9); /* Adjust scale as needed */
                transform-origin: top left;
                margin: 0;
                padding: 0;
              }
            </style>
          </head>
          <body>
            <table class="styled-table">
              <thead>
                <tr>
                  <th class="small-column">Gr</th>
                  <th class="large-column">Name</th>`;

      // Add subjects as columns
      this.sampleData[0].Marks.forEach((mark) => {
        tableHTML += `<th colspan="3" class="medium-column">${mark.Subject}</th>`;
      });

      // Add Summary column
      tableHTML += `<th colspan="4" class="medium-column">Summary</th></tr>`;

      // Add rows for marks headers
      tableHTML += `<tr><th></th><th></th>`;
      this.sampleData[0].Marks.forEach(() => {
        tableHTML += `
        <th>Prev</th>
        <th>Curr</th>
        <th>Tot</th>
         `;
      });
      tableHTML += `
          <th>Total</th>
          <th>Perc</th>
          <th>Grade</th>
          <th>Rank</th>
        </tr>
      </thead>`;

      // Add table body
      tableHTML += `<tbody>`;
      this.sampleData.forEach((student) => {
        tableHTML += `
          <tr>
            <td>${student.Gr}</td>
            <td>${student.Name}</td>`;

        student.Marks.forEach((mark) => {
          tableHTML += `
            <td>${mark.Previous}</td>
            <td>${mark.Current}</td>
            <td>${mark.Total}</td>
           <!--
            <td>${mark.Perc}</td>
            <td>${mark.Grade}</td>
             -->
           `;
        });

        tableHTML += `
          <td>${student.Summary.Total}</td>
          <td>${student.Summary.Perc}</td>
          <td>${student.Summary.Grade}</td>
          <td>${student.Summary.Rank}</td>
        </tr>`;
      });
      tableHTML += `</tbody></table></body></html>`;

      // Open a new window and print the table
      const printWindow = window.open(
        "",
        "",
        "width=" + screen.width + ",height=" + screen.height
      );
      // const printWindow = window.open("", "_blank");
      printWindow.document.write(tableHTML);
      printWindow.document.close();
      printWindow.print();
    },
    async dataSheet() {
      if (
        this.myObj.clsID > 0 &&
        ((this.reportDomain != "myskoolmakjauhar" && this.myObj.sec > 0) ||
          this.reportDomain == "myskoolmakjauhar") &&
        this.myObj.sessionID > 0 &&
        this.myObj.examTypeID.length > 0
      ) {
        this.sheetLoading = true;
        let obj = {
          campusID: this.myObj.campusID,
          clsID: this.myObj.clsID,
          sec: this.myObj.sec,
          examTypeID: this.myObj.examTypeID,
          sessionID: this.myObj.sessionID,
          dtFrom: null,
          dtTo: null,
        };
        let res = await this.post({
          url:
            this.$store.state.domain +
            "Marks/CreateResultInPhases?db=" +
            this.$store.state.userData.db +
            "&phase=2",
          body: obj,
          subjects: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        this.sheetLoading = false;
        if (res.length > 0) {
          let url = `https://${this.reportDomain}.myskool.app/Exams/ShowDatasheetMultiple?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&cls=${this.myObj.clsID}&sec=${this.myObj.sec}&examID=${this.myObj.examTypeID}&sessionID=${this.myObj.sessionID}&sIDs=${this.selectedIDs}&date=`;
          window.open(url, "_blank");
        } else {
          this.$bvToast.toast("No records found.", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        }
      } else {
        this.$bvToast.toast("Please select all the requird fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },

    async sendMessage() {
      this.myObj.dtFrom = null;
      this.myObj.dtTo = null;
      if (
        this.myObj.clsID > 0 &&
        ((this.reportDomain != "myskoolmakjauhar" && this.myObj.sec > 0) ||
          this.reportDomain == "myskoolmakjauhar") &&
        this.myObj.sessionID > 0 &&
        this.myObj.examTypeID.length > 0
      ) {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "You want to send result on WhatsApp?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes!",
        });
        if (result.isConfirmed) {
          this.sending = true;
          let res = await this.post({
            url:
              this.$store.state.domain +
              "Marks/CreateResult?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            context: this,
            subjects: true,
            token: this.$store.state.userData.token,
          });
          if (res) {
            try {
              // console.log(res);
              await this.postObj({
                url:
                  this.$store.state.domain +
                  "Marks/SendWhatsapp?db=" +
                  this.$store.state.userData.db +
                  "&cID=" +
                  this.$store.state.userData.cId +
                  "&userID=" +
                  this.$store.state.userData.userID,
                message: "Result sent on WhatsApp!",
                context: this,
                body: this.myObj,
                token: this.$store.state.userData.token,
              });
            } catch (error) {
            } finally {
              this.sending = false;
            }
          } else {
            this.$bvToast.toast("Something went wrong!", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-center",
            });
          }
          this.sending = false;
        }
      } else {
        this.$bvToast.toast("Please select all the required fields.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },

    openGrace() {
      this.visibility5 = true;
    },
    FillGraceSec() {
      // console.log(this.graceObj.classID);
      this.graceSections = [];
      this.graceSections = this.classes.find(
        (el) => el.cls.id == this.graceObj.classID
      ).sections;

      this.graceObj.sectionID = 0;

      // console.log("secs", this.graceSections);
    },
    async ApplyGrace() {
      // console.log(this.graceObj);

      if (
        this.graceObj.classID > 0 &&
        this.graceObj.sectionID > 0 &&
        this.graceObj.sessionID > 0 &&
        this.graceObj.examIDs.length > 0 &&
        this.graceObj.basedOn > 0 &&
        this.graceObj.type !== ""
      ) {
        this.gracing = true;
        let res = await this.post({
          url:
            this.$store.state.domain +
            "Marks/ApplyBonus?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.graceObj,
          message: "Marks Updated",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.gracing = false;
      } else {
        this.$bvToast.toast("Please select all the required fields.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },

    setDate() {
      let d = this.publishRange.split(" to ");
      this.pubObj.dtFrom = d[0];
      this.pubObj.dtTo = d[1];
    },
    checkPubFrom() {
      var elem = this.$refs["pub_from"];
      if (this.pubObj.dtFrom == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkPubTill() {
      var elem = this.$refs["pub_till"];
      if (this.pubObj.dtTo == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async CheckStatus() {
      if (
        this.myObj.clsID > 0 &&
        ((this.reportDomain != "myskoolmakjauhar" && this.myObj.sec > 0) ||
          this.reportDomain == "myskoolmakjauhar") &&
        this.myObj.sessionID > 0 &&
        this.myObj.examTypeID.length > 0
      ) {
        this.loadingStatus = true;
        let res = await this.get({
          url:
            this.$store.state.domain +
            "Marks/LoadPublish?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&clsID=" +
            this.myObj.clsID +
            "&secID=" +
            this.myObj.sec +
            "&examID=" +
            this.myObj.examTypeID[0] +
            "&sessionID=" +
            this.myObj.sessionID,
          context: this,
          token: this.$store.state.userData.token,
        });
        // console.log(res);
        if (res == "") {
          this.isLock = false;
          this.isPublish = false;
          this.pubObj.dtFrom = "";
          this.pubObj.dtTo = "";
        } else {
          this.isLock = res.isLocked;
          this.isPublish = res.isPublished;
          this.pubObj.dtFrom = res.publishedFrom;
          this.pubObj.dtTo = res.publishedTo;
        }

        this.loadingStatus = false;
        this.visibility4 = true;
      } else {
        this.$bvToast.toast("Please select all the requird fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    async publish() {
      this.checkPubFrom();
      this.checkPubTill();
      if (this.checkPubFrom() == true && this.checkPubTill() == true) {
        this.publishing = true;

        if (this.isPublish) {
          let saveObj = {
            cID: this.myObj.clsID,
            secID: this.myObj.sec,
            examID: this.myObj.examTypeID[0],
            sessionID: this.myObj.sessionID,
            dtFrom: this.pubObj.dtFrom,
            dtTo: this.pubObj.dtTo,
            userID: this.$store.state.userData.userID,
          };
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Marks/UnpublishResult?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: saveObj,
            message: "Result Unpublished successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.publishing = false;

          if (status) this.visibility4 = false;
        } else {
          let obj = {
            campusID: this.$store.state.userData.cId,
            clsID: this.myObj.clsID,
            sec: this.myObj.sec,
            examTypeID: [this.myObj.examTypeID[0]],
            sessionID: this.myObj.sessionID,
            dtFrom: null,
            dtTo: null,
          };

          let res = await this.post({
            url:
              this.$store.state.domain +
              "Marks/CreateResult?db=" +
              this.$store.state.userData.db,
            body: obj,
            subjects: true,
            context: this,
            token: this.$store.state.userData.token,
          });

          if (res.length > 0) {
            let saveObj = {
              cID: this.myObj.clsID,
              secID: this.myObj.sec,
              examID: this.myObj.examTypeID[0],
              sessionID: this.myObj.sessionID,
              dtFrom: this.pubObj.dtFrom,
              dtTo: this.pubObj.dtTo,
              userID: this.$store.state.userData.userID,
            };
            var status = await this.post({
              url:
                this.$store.state.domain +
                "Marks/PublishResult?db=" +
                this.$store.state.userData.db +
                "&cID=" +
                this.$store.state.userData.cId,
              body: saveObj,
              message: "Result Published successfully.",
              context: this,
              token: this.$store.state.userData.token,
            });
            this.publishing = false;

            if (status) this.visibility4 = false;
          } else {
            this.publishing = false;
            this.$bvToast.toast("No records found.", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-center",
            });
          }
        }
      } else {
        this.$bvToast.toast("Please select publish duration date", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },

    async checkLock() {
      if (
        this.myObj.clsID > 0 &&
        ((this.reportDomain != "myskoolmakjauhar" && this.myObj.sec > 0) ||
          this.reportDomain == "myskoolmakjauhar") &&
        this.myObj.sessionID > 0 &&
        this.myObj.examTypeID.length > 0
      ) {
        let res = await this.get({
          url:
            this.$store.state.domain +
            "Marks/LoadPublish?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&clsID=" +
            this.myObj.clsID +
            "&secID=" +
            this.myObj.sec +
            "&examID=" +
            this.myObj.examTypeID[0] +
            "&sessionID=" +
            this.myObj.sessionID,
          context: this,
          token: this.$store.state.userData.token,
        });
        // console.log(res);
        if (res == "") {
          this.isLock = false;
          this.isPublish = false;
          this.pubObj.dtFrom = "";
          this.pubObj.dtTo = "";
        } else {
          this.isLock = res.isLocked;
          this.isPublish = res.isPublished;
          this.pubObj.dtFrom = res.publishedFrom;
          this.pubObj.dtTo = res.publishedTo;
        }
      }
    },
    async lock() {
      if (
        this.myObj.clsID > 0 &&
        ((this.reportDomain != "myskoolmakjauhar" && this.myObj.sec > 0) ||
          this.reportDomain == "myskoolmakjauhar") &&
        this.myObj.sessionID > 0 &&
        this.myObj.examTypeID.length > 0
      ) {
        let saveObj = {
          cID: this.myObj.clsID,
          secID: this.myObj.sec,
          examID: this.myObj.examTypeID[0],
          sessionID: this.myObj.sessionID,
          dtFrom: null,
          dtTo: null,
          userID: this.$store.state.userData.userID,
        };
        if (this.isLock) {
          this.locking = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Marks/Unlock" +
              "?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: saveObj,
            message: "Result unlocked successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.locking = false;
          if (status) this.checkLock();
        } else {
          this.locking = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Marks/Lock" +
              "?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: saveObj,
            message: "Result locked successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.locking = false;
          if (status) this.checkLock();
        }
      } else {
        this.$bvToast.toast("Please select all the requird fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    exportData() {
      if (this.items.length > 0) {
        this.exporting = true;

        const newData = this.items.map((item) => ({
          grNo: item.grNo,
          name: item.name,
          father: item.father_name,
          total: item.totalMarks,
          obtained: item.obtainedMarks,
          percenatge: item.percentage,
          grade: item.grade,
          rank: item.rank,
          result: item.result,
        }));

        const worksheet = XLSX.utils.json_to_sheet(newData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
        XLSX.writeFile(workbook, "result.xlsx");

        this.exporting = false;
      } else {
        this.$bvToast.toast("Please first create a result to export.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    async expResult(sID) {
      this.currentExp = sID;
      this.exportingRes = true;

      let obj = {
        campusID: this.myObj.campusID,
        clsID: this.myObj.clsID,
        sec: this.myObj.sec,
        examTypeID: this.myObj.examTypeID,
        sessionID: this.myObj.sessionID,
        dtFrom: null,
        dtTo: null,
      };
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Marks/CreateResultInPhases?db=" +
          this.$store.state.userData.db +
          "&phase=2",
        body: obj,
        subjects: true,
        context: this,
        token: this.$store.state.userData.token,
      });

      let examNames = [];
      this.myObj.examTypeID.forEach((el) => {
        let obj = this.examType.find((elem) => elem.id == el);
        if (obj) examNames.push(obj.examType);
      });

      var url = `https://${this.reportDomain}.myskool.app/Exams/ShowExport?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&cls=${this.myObj.clsID}&sec=${this.myObj.sec}&examID=${this.myObj.examTypeID}&sessionID=${this.myObj.sessionID}&sIDs=${sID}&date=&examType=${examNames}`;
      var axios = require("axios");
      axios
        .get(url)
        .then((res) => {
          // console.log("res:", res);
          if (res.data.status == "success") {
            window.open(res.data.data[0], "_blank");
          } else {
            this.$bvToast.toast("Something went wrong!", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-center",
            });
          }
        })
        .catch((error) => {
          console.error("err", error);
          this.$bvToast.toast("Something went wrong!", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        })
        .finally(() => (this.exportingRes = false));
    },
    downloadPDF(pdfUrl) {
      // Create a new anchor element
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = pdfUrl.split("/").at(-1);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    firstFocus() {
      let first = document.querySelector(".present-field");
      first.focus();
      first.select();
    },
    checkValues(ind) {
      var elem = this.$refs["present" + ind];
      if (
        this.filterAtt[ind].present < 0 ||
        parseInt(this.filterAtt[ind].present) > parseInt(this.attData.totalDays)
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = undefined);
      }
    },
    async moveFocus(ind, item) {
      let all = document.querySelectorAll(".present-field");
      // console.log(all);

      if (ind + 1 != all.length) {
        all[ind + 1].focus();
        all[ind + 1].select();
      }
    },

    async openAttendance() {
      if (
        this.myObj.clsID > 0 &&
        ((this.reportDomain != "myskoolmakjauhar" && this.myObj.sec > 0) ||
          this.reportDomain == "myskoolmakjauhar") &&
        this.myObj.sessionID > 0 &&
        this.myObj.examTypeID.length > 0
      ) {
        this.showGrid = false;
        this.showAtt = true;
        this.currentExam = this.examType.find(
          (el) => el.id == this.myObj.examTypeID[0]
        ).examType;
        this.currentSession = this.sessions.find(
          (el) => el.id == this.myObj.sessionID
        ).session;
        // this.attLoading = true;
        // this.attData = await this.get({
        //   url:
        //     this.$store.state.domain +
        //     " ?db=" +
        //     this.$store.state.userData.db +
        //     "&campusID=" +
        //     this.$store.state.userData.cId +
        //     "&clsID=" +
        //     this.myObj.clsID +
        //     "&sec=" +
        //     this.myObj.sec +
        //     "&examTypeID=" +
        //     this.myObj.examTypeID[0] +
        //     "&sessionID=" +
        //     this.myObj.sessionID,
        //   context: this,
        //   token: this.$store.state.userData.token,
        // });

        // this.attLoading = false;
        setTimeout(() => {
          this.firstFocus();
        }, 500);
      } else {
        this.$bvToast.toast("Please select all the required fields.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },

    async Edit(item) {
      //   var obj = {
      //     url:
      //       this.$store.state.domain +
      //       "examtypes/GetSelected?id=" +
      //       id +
      //       "&db=" +
      //       this.$store.state.userData.db,
      //     token: this.$store.state.userData.token,
      //   };
      //   this.myObj = await this.get(obj);
      //   console.log("editObj:", this.myObj);
      this.marksObj = item;
      this.visibility = true;
      this.sidebarTitle = "Edit Marks";
      this.sidebarButton = "Update";
      var elem = this.$refs["marks"];
      elem.state = undefined;
    },

    AddOpen() {
      this.myObj = {
        id: 0,
        account: 0,
        balance: 0,
        campusID: this.$store.state.userData.cId,
      };
      this.visibility = true;
      this.sidebarTitle = "Add balance";
      this.sidebarButton = "Save";
      var elem = this.$refs["account"];
      elem.state = undefined;
      var elem = this.$refs["balance"];
      elem.state = undefined;
    },

    CheckMarks() {
      var elem = this.$refs["marks"];
      var re = /^[0-9]+$/;
      if (re.test(this.marksObj.obtained_marks)) {
        this.marksObj.obtained_marks = parseInt(this.marksObj.obtained_marks);
        return (elem.state = true);
      } else {
        return (elem.state = false);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },
    FillRewardSec(text) {
      // console.log(this.rewardObj.clsID);
      this.rewardSections = [];
      this.classes.forEach((elem) => {
        if (elem.cls.id === this.rewardObj.clsID) {
          if (elem.sections.length != 0) {
            this.rewardSections = elem.sections;
          } else {
            // console.log("null");
            this.rewardSections = [];
          }
        }
      });
      if (text != "filled") this.rewardObj.secIDs = null;
      // if (this.rewardSections.length > 0)
      //   this.rewardSections.unshift({ id: 0, section: "All Sections" });
      // console.log("sec", this.rewardSections);
    },
    setSections() {
      if (this.rewardObj.secIDs.at(-1) == 0) {
        this.rewardObj.secIDs = [0];
      } else {
        this.rewardObj.secIDs = this.rewardObj.secIDs.filter((el) => el != 0);
      }
      // console.log(this.rewardObj.secIDs);
    },
    FillSections(text) {
      // console.log(this.myObj.clsID);

      if (this.reportDomain == "myskoolmakjauhar") {
        if (this.myObj.clsID == 0) {
          this.currentSections = [{ id: 0, section: "All Sections" }];
          this.myObj.sec = 0;
        } else {
          this.classes.forEach((elem) => {
            if (elem.cls.id === this.myObj.clsID) {
              if (elem.sections.length != 0) {
                this.currentSections = [
                  { id: 0, section: "All Sections" },
                  ...elem.sections,
                ];
              } else {
                // console.log("null");
                this.currentSections = [{ id: 0, section: "All Sections" }];
              }
            }
          });
          if (text != "filled") {
            this.myObj.sec = 0;
          }
        }
      } else {
        this.classes.forEach((elem) => {
          if (elem.cls.id === this.myObj.clsID) {
            if (elem.sections.length != 0) {
              this.currentSections = elem.sections;
            } else {
              // console.log("null");
              this.currentSections = [];
            }
          }
        });
        if (text != "filled") this.myObj.sec = 0;
      }

      // console.log("Thisis", this.currentSections);
    },
    ViewDetails(item) {
      this.showGrid = false;
      this.showDetils = true;
    },

    Back() {
      this.showAtt = false;
      this.showGrid = true;
      // this.showDetils = false;
    },
    async loadSettings() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: [
          "auto_lock",
          "result_approval",
          "term_dates",
          "show_footer",
          "show_logo",
          "show_stamp",
          "course_marks",
          "datasheetOnResult",
        ],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      // console.log(res);
      this.lockObj = res.find((el) => el.key == "auto_lock");

      if (this.lockObj && this.lockObj.valueNumber == null) {
        this.lockObj.valueNumber = 1;
      }

      this.termDate = res.find((el) => el.key == "term_dates");
      this.approveObj = res.find((el) => el.key == "result_approval");
      this.footerObj = res.find((el) => el.key == "show_footer");
      this.logoObj = res.find((el) => el.key == "show_logo");
      this.stampObj = res.find((el) => el.key == "show_stamp");
      this.courseObj = res.find((el) => el.key == "course_marks");
      this.sheetObj = res.find((el) => el.key == "datasheetOnResult");
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);
      this.classesOptions = [];
      this.classes.forEach((el) => this.classesOptions.push(el.cls));
      // console.log("item", this.classes);
      this.FillSections("filled");

      var obj2 = {
        url:
          this.$store.state.domain +
          "Sessions?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.sessions = await this.get(obj2);
      // console.log("Session", this.sessions);

      var obj3 = {
        url:
          this.$store.state.domain +
          "ExamTypes?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.examType = await this.get(obj3);
      // console.log("exam type", this.examType);

      // var obj = {
      //   url:
      //     this.$store.state.domain +
      //     "Subjects?db=" +
      //     this.$store.state.userData.db +
      //     "&cId=" +
      //     this.$store.state.userData.cId,
      //   token: this.$store.state.userData.token,
      // };
      // this.subjects = await this.get(obj);

      // console.log("subject", this.subjects);

      this.dataLoading = false;
      if (
        this.myObj.clsID > 0 &&
        ((this.reportDomain != "myskoolmakjauhar" && this.myObj.sec > 0) ||
          this.reportDomain == "myskoolmakjauhar") &&
        this.myObj.sessionID > 0 &&
        this.myObj.examTypeID.length > 0
      ) {
        // this.show();
        this.checkLock();
      }
    },
    async show() {
      this.myObj.campusID = this.$store.state.userData.cId;
      let data = {
        campusID: this.myObj.campusID,
        clsID: this.myObj.clsID,
        secID: this.myObj.sec,
        examID:
          this.myObj.examTypeID.length == 0 ? 0 : this.myObj.examTypeID[0],
        examTypes: this.myObj.examTypeID,
        sessionID: this.myObj.sessionID,
      };
      if (this.collection.length == 0) {
        this.collection.push(data);
      } else {
        const item = this.collection.find(
          (el) => el.campusID == this.$store.state.userData.cId
        );
        let ind = this.collection.indexOf(item);
        // console.log(ind);
        if (ind == -1) {
          this.collection.push(data);
        } else {
          this.collection[ind] = data;
        }
      }
      this.$store.commit("setSelection", this.collection);

      if (this.selectedIDs.length > 0) this.clear();
      this.iShow = true;

      this.isApproved = false;
      try {
        this.myObj.dtFrom = null;
        this.myObj.dtTo = null;
        if (
          this.myObj.clsID > 0 &&
          ((this.reportDomain != "myskoolmakjauhar" && this.myObj.sec > 0) ||
            this.reportDomain == "myskoolmakjauhar") &&
          this.myObj.sessionID > 0 &&
          this.myObj.examTypeID.length > 0
        ) {
          this.gridLoading = true;
          let st = await this.postObj({
            url:
              this.$store.state.domain +
              "Marks/CreateResultInPhases?db=" +
              this.$store.state.userData.db +
              "&phase=1",
            body: this.myObj,
            message: "Result created.",
            context: this,
            token: this.$store.state.userData.token,
          });
          if (st == false) this.items = [];
          else this.items = st;
          // console.log(this.items);
          this.showGrid = true;
          this.gridLoading = false;
        } else {
          this.$bvToast.toast("Please select all the required fields.", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        }
      } catch (error) {
      } finally {
        this.iShow = false;
      }
    },
    async ApplyDiscount() {
      this.myObj.campusID = this.$store.state.userData.cId;
      let data = {
        campusID: this.myObj.campusID,
        clsID: this.rewardObj.clsID,
        secID: this.rewardObj.secIDs,
        examID:
          this.myObj.examTypeID.length == 0 ? 0 : this.myObj.examTypeID[0],
        sessionID: this.myObj.sessionID,
      };
      // console.log(data);

      this.iShow = true;
      try {
        this.myObj.dtFrom = null;
        this.myObj.dtTo = null;
        if (
          this.rewardObj.clsID > 0 &&
          this.rewardObj.secIDs > 0 &&
          this.myObj.sessionID > 0 &&
          this.myObj.examTypeID.length > 0
        ) {
          this.gridLoading = true;
          this.items = await this.postObj({
            url:
              this.$store.state.domain +
              "Marks/UpdateFee?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Fee updated.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.showGrid = true;
          this.gridLoading = false;
        } else {
          this.$bvToast.toast("Please select all the required fields.", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        }
      } catch (error) {
      } finally {
        this.iShow = false;
      }
    },
    onRowSelected(row) {
      this.selectedIDs = row.reduce((acc, el) => {
        acc.push(el.sID);
        return acc;
      }, []);
      // console.log(this.selectedIDs);
    },
    clear() {
      this.$refs.selectableTable.clearSelected();
      // this.selectedIDs = []
    },
    approveRes(val) {
      if (
        this.myObj.clsID > 0 &&
        ((this.reportDomain != "myskoolmakjauhar" && this.myObj.sec > 0) ||
          this.reportDomain == "myskoolmakjauhar") &&
        this.myObj.sessionID > 0 &&
        this.myObj.examTypeID.length > 0
      ) {
        this.approving = true;
        setTimeout(() => {
          this.isApproved = val;
          this.approving = false;
          if (val) {
            this.$bvToast.toast("Result is approved now.", {
              title: "Success!",
              variant: "success",
              toaster: "b-toaster-top-right",
              solid: true,
            });
          } else {
            this.$bvToast.toast("Approval removed successfully!", {
              title: "Success!",
              variant: "success",
              toaster: "b-toaster-top-right",
              solid: true,
            });
          }
        }, 2000);
      } else {
        this.$bvToast.toast("Please select all the required fields.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    checkPrint(val) {
      if (this.approveObj && this.approveObj.valueBool) {
        if (this.isApproved) this.print(val);
        else {
          this.$bvToast.toast("Result is not approved yet", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        }
      } else {
        // console.log(val);
        this.print(val);
      }
    },
    printSingle(sID) {
      this.print(sID);
    },
    getMonthName(month) {
      return months[month - 1];
    },
    async print(sID) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var myDate = "";
      if (
        this.reportDomain == "myskoolmerittoexcel" &&
        this.settObj.absentMarks != undefined &&
        this.settObj.absentMarks != null &&
        this.settObj.absentMarks != "" &&
        this.settObj.absentMarks.length > 0
      ) {
        this.myObj.absentDeduction = parseInt(this.settObj.absentMarks);
      } else this.myObj.absentDeduction = 0;

      if (this.settObj.rangeDate.indexOf(" to ") > -1) {
        var dt = this.settObj.rangeDate.split(" to ");
        var dtFrom = dt[0].split("-");
        var dtTo = dt[1].split("-");
        this.myObj.dtFrom = dt[0];
        this.myObj.dtTo = dt[0];
        var mFrom = months[parseInt(dtFrom[1]) - 1];
        var mTo = months[parseInt(dtTo[1]) - 1];

        // console.log(dtFrom, dtTo, mFrom, mTo);
        myDate = dtFrom[2] + "-" + mFrom + "-" + dtFrom[0];
        if (dtFrom != dtTo) {
          myDate += " to " + dtTo[2] + "-" + mTo + "-" + dtTo[0];
          this.myObj.dtTo = dt[1];
        }
        // console.log(myDate);
      } else {
        if (
          this.settObj.rangeDate != undefined &&
          this.settObj.rangeDate != null &&
          this.settObj.rangeDate.length > 0
        ) {
          var dt = this.settObj.rangeDate;
          this.myObj.dtFrom = dt;
          this.myObj.dtTo = dt;
          var dtFrom = dt.split("-");
          var mFrom = months[parseInt(dtFrom[1]) - 1];
          myDate = dtFrom[2] + "-" + mFrom + "-" + dtFrom[0];
        } else {
          myDate = "";
          this.myObj.dtFrom = null;
          this.myObj.dtTo = null;
        }
      }
      this.examNames = [];
      this.myObj.examTypeID.forEach((el) => {
        let name = this.examType.find((elem) => elem.id == el).examType;
        if (name) this.examNames.push(name);
      });
      // console.log(this.examNames);
      this.iPrint = true;
      this.current = sID;
      try {
        // if (sID == "" && this.selectedIDs.length > 0) {
        //   var url = `https://${this.reportDomain}.myskool.app/Exams/Show?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&cls=${this.myObj.clsID}&sec=${this.myObj.sec}&examID=${this.myObj.examTypeID}&sessionID=${this.myObj.sessionID}&sIDs=${this.selectedIDs}&date=${myDate}&examType=${this.examNames}`;
        //   window.open(url, "_blank");
        // }
        //  else {
        // }
        if (
          this.myObj.clsID > 0 &&
          ((this.reportDomain != "myskoolmakjauhar" && this.myObj.sec > 0) ||
            this.reportDomain == "myskoolmakjauhar") &&
          this.myObj.sessionID > 0 &&
          this.myObj.examTypeID.length > 0
        ) {
          this.items = await this.postObj({
            url:
              this.$store.state.domain +
              "Marks/CreateResultInPhases?db=" +
              this.$store.state.userData.db +
              "&phase=2",
            body: this.myObj,
            message: "Result created.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.showGrid = true;
          if (sID == "" && this.selectedIDs.length > 0) {
            let url = `https://${this.reportDomain}.myskool.app/Exams/Show?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&cls=${this.myObj.clsID}&sec=${this.myObj.sec}&examID=${this.myObj.examTypeID}&sessionID=${this.myObj.sessionID}&sIDs=${this.selectedIDs}&date=${myDate}&examType=${this.examNames}`;
            window.open(url, "_blank");
          } else {
            let url = `https://${this.reportDomain}.myskool.app/Exams/Show?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&cls=${this.myObj.clsID}&sec=${this.myObj.sec}&examID=${this.myObj.examTypeID}&sessionID=${this.myObj.sessionID}&sIDs=${sID}&date=${myDate}&examType=${this.examNames}`;
            window.open(url, "_blank");
          }
        } else {
          this.$bvToast.toast("Please select all the required fields.", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        }
      } catch (error) {
      } finally {
        this.iPrint = false;
      }
    },

    openMax() {
      this.visibility3 = true;
    },
    removeStamp() {
      this.stampObj.value = null;
      this.stLoading = false;
      this.$refs.stampImg.files = null;
    },
    addStamp() {
      let obj = this.$refs.stampImg.files[0];
      if (obj != "") {
        this.stLoading = true;
        let formData = new FormData();
        formData.append("file", obj);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp[0].path;
            this.stampObj.value = resp;
            this.stLoading = false;
          })
          .catch((e) => {
            this.stLoading = false;
          });
      }
    },
    CheckTermDate() {
      if (this.termDate) {
        var elem = this.$refs["exam_date"];
        if (!this.termDate.value) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckStamp() {
      if (this.stampObj && this.stampObj.valueBool) {
        var elem = this.$refs["stamp"];
        if (!this.stampObj.value) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckCourse() {
      if (this.courseObj && this.courseObj.valueBool) {
        var elem = this.$refs["c_exam"];
        if (this.courseObj.valueNumber == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    async saveMax() {
      if (
        this.CheckTermDate() == true &&
        this.CheckStamp() == true &&
        this.CheckCourse() == true
      ) {
        // console.log(this.settObj);
        this.$store.commit("setResult", this.settObj);

        let data = [];
        if (this.lockObj && this.lockObj.valueBool) data.push(this.lockObj);
        if (this.termDate) data.push(this.termDate);
        if (this.logoObj) data.push(this.logoObj);
        if (this.footerObj) data.push(this.footerObj);
        if (this.stampObj) data.push(this.stampObj);
        if (this.courseObj) data.push(this.courseObj);

        if (data.length > 0) {
          this.saving = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Settings/SaveNew?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: data,
            message: "Settings saved successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.saving = false;
          if (status) {
            this.loadSettings();
            this.visibility3 = false;
          }
        } else {
          this.visibility3 = false;
        }

        // if (this.lockObj && this.lockObj.valueBool) {
        //   this.saving = true;
        //   // console.log(this.lockObj);
        //   var status = await this.put({
        //     url:
        //       this.$store.state.domain +
        //       "Settings/" +
        //       this.lockObj.id +
        //       "?db=" +
        //       this.$store.state.userData.db,
        //     body: this.lockObj,
        //     message: "Setting saved successfully.",
        //     context: this,
        //     token: this.$store.state.userData.token,
        //   });
        //   this.saving = false;
        //   if (status) {
        //     this.loadSettings();
        //     this.visibility3 = false;
        //   }
        // } else {
        //   this.visibility3 = false;
        //   this.saving = false;
        // }
      }
    },
    async openSettings() {
      this.rewardObj.clsID = this.myObj.clsID;
      this.FillRewardSec("filled");
      this.getFee();
      this.rewardObj.secIDs = null;
      if (this.myObj.sec > 0) this.rewardObj.secIDs = this.myObj.sec;

      this.visibility2 = true;
      // console.log("rew:", this.rewardObj);
    },
    async getFee() {
      var obj = {
        url:
          this.$store.state.domain +
          "Students/LoadClassFee?clsID=" +
          this.myObj.clsID +
          "&campusId=" +
          this.$store.state.userData.cId +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.rewardObj.fee = await this.get(obj);
    },
    CheckNum() {
      var elem = this.$refs["fee"];
      var re = /^[0-9]+$/;
      if (re.test(this.rewardObj.fee) && this.rewardObj.fee != 0) {
        this.rewardObj.fee = parseInt(this.rewardObj.fee);
        return (elem.state = true);
      } else {
        return (elem.state = false);
      }
    },
    async saveSetting() {
      if (this.CheckNum() == true) {
        this.saving = true;
        console.log(this.rewardObj);
        // var status = await this.post({
        //   url:
        //     this.$store.state.domain + " ?db=" + this.$store.state.userData.db,
        //   body: this.rewardObj,
        //   message: "Discount applied successfully.",
        //   context: this,
        //   token: this.$store.state.userData.token,
        // });
        this.saving = false;

        // if (status) this.visibility2 = false;
      }
    },
    async Add() {
      //   this.CheckAccount();
      this.CheckMarks();
      if (this.CheckMarks() == false) {
        return this.$bvToast.toast("Please enter the marks.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.marksObj.id == 0) {
          //Add
          this.request = true;
          console.log("obj:", this.marksObj);
          this.request = false;
          this.visibility = false;
          //   var status = await this.post({
          //     url:
          //       this.$store.state.domain +
          //       "OpeningBalance?db=" +
          //       this.$store.state.userData.db,
          //     body: this.myObj,
          //     message: "Marks added successfully.",
          //     context: this,
          //     token: this.$store.state.userData.token,
          //   });
          //   this.request = false;
          //   this.visibility = false;
          //   if (status) this.LoadData();
        } else {
          //Edit
          this.request = true;
          console.log("edit_obj:", this.marksObj);
          this.request = false;
          this.visibility = false;
          //   var status = await this.put({
          //     url:
          //       this.$store.state.domain +
          //       "OpeningBalance/" +
          //       this.myObj.id +
          //       "?db=" +
          //       this.$store.state.userData.db,
          //     body: this.myObj,
          //     message: "Marks updated successfully.",
          //     context: this,
          //     token: this.$store.state.userData.token,
          //   });
          //   this.request = false;
          //   this.visibility = false;
          //   console.log("status", status);
          //   if (status) this.LoadData();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
