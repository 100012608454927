<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility6"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Mark Holiday</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility6 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Department"
              invalid-feedback="Department is required."
              ref="hol_dep"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="holiObj.departmentIDs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="departments"
                :reduce="(val) => val.id"
                label="name"
                :clearable="false"
                @input="setHoliDept()"
                ref="hol_dep"
                placeholder="Select department"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date From"
              invalid-feedback=" date is required."
              ref="hol_dateF"
            >
              <flat-pickr
                ref="hol_dateF"
                :config="config"
                v-model="holiObj.dtFrom"
                class="form-control"
                placeholder="Select Date"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date Till"
              invalid-feedback=" date is required."
              ref="hol_dateT"
            >
              <flat-pickr
                ref="hol_dateT"
                :config="config"
                v-model="holiObj.dtTo"
                class="form-control"
                placeholder="Select Date"
              />
            </b-form-group>
          </b-col>

          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="markHoliday()"
              :disabled="marking"
              block
            >
              <b-spinner v-if="marking" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility5"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility5 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1">
          <b-col
            v-if="checkInObj"
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Show Checkin</h4>
            <b-form-checkbox v-model="checkInObj.valueBool" switch />
          </b-col>
          <!-- <b-col
           v-if='checkOutObj'
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Show Checkout</h4>
            <b-form-checkbox v-model="checkOutObj.valueBool" switch />
          </b-col> -->

          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="saveChecks()"
              :disabled="savingCh"
              block
            >
              <b-spinner v-if="savingCh" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility4"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Smart Attendance</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility4 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1">
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required."
              ref="msg_date"
            >
              <flat-pickr
                ref="msg_date"
                :config="config"
                v-model="smartObj.dt"
                class="form-control"
                placeholder="Select Date"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Gr Numbers"
              invalid-feedback="Please enter gr separated by space"
              ref="grs"
            >
              <b-form-textarea
                ref="grs"
                placeholder="Enter gr numbers seperated by space"
                v-model="smartObj.gr"
                rows="4"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-form-group
              label="Status"
              invalid-feedback="Status is required."
              ref="sm_status"
            >
              <v-select
                ref="sm_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="smartObj.status"
                :options="smartOptions"
                :reduce="(opt) => opt.value"
                label="name"
                placeholder="select status"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="saveSmart()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Reports</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1" style="align-items: center">
          <b-col md="12">
            <b-form-group
              label="Select Class"
              invalid-feedback="class is required."
              ref="rep_class"
            >
              <v-select
                ref="rep_class"
                multiple
                :closeOnSelect="false"
                v-model="repClass"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classesOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                @input="FillRepSections()"
                placeholder="Select class"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="repClass[0] != 0">
            <b-form-group
              label="Select Section"
              invalid-feedback="section is required."
              ref="rep_section"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="repSection"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="repSecOptions"
                :reduce="(val) => val.id"
                :clearable="false"
                @input="checkRepSections()"
                ref="rep_section"
                label="section"
                placeholder="Select section"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback=" date is required."
              ref="rep_date"
            >
              <flat-pickr
                ref="rep_date"
                :config="config2"
                v-model="repDate"
                class="form-control"
                placeholder="Select Date"
                @on-change="setRepDate"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-form-group
              label="Select Status"
              invalid-feedback="status is required."
              ref="rep_status"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                ref="rep_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="repObj.status"
                :options="statusOptions"
                :reduce="(opt) => opt.value"
                label="name"
                placeholder="select status"
                @input="setStatus()"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="getReport()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Show</span>
            </b-button>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="getSheet()"
              :disabled="sheetLoading"
              block
            >
              <b-spinner v-if="sheetLoading" small type="grow" />
              <span v-else>Attendance Sheet</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Send Messsage</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1" style="align-items: center">
          <b-col md="12">
            <b-form-group
              label="Select Class"
              invalid-feedback="class is required."
              ref="msg_class"
            >
              <v-select
                ref="msg_class"
                multiple
                :closeOnSelect="false"
                v-model="msgClass"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classesOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                @input="FillMsgSections()"
                placeholder="Select class"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="msgClass[0] != 0">
            <b-form-group
              label="Select Section"
              invalid-feedback="section is required."
              ref="msg_section"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="msgSection"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="msgSecOptions"
                :reduce="(val) => val.id"
                :clearable="false"
                @input="checkMsgSections()"
                ref="msg_section"
                label="section"
                placeholder="Select section"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback=" date is required."
              ref="msg_date"
            >
              <flat-pickr
                ref="msg_date"
                :config="config"
                v-model="msgObj.dt"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-form-group
              label="Select Status"
              invalid-feedback="status is required."
              ref="msg_status"
            >
              <v-select
                ref="msg_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="msgObj.status"
                :options="statusOptions"
                :reduce="(opt) => opt.value"
                label="name"
                placeholder="select status"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="saveMessage()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Send Message</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-modal
      id="modal-att"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      title="Edit Attendance"
    >
      <b-row class="">
        <b-col>
          <b-button
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="currentObj.status == 'P' ? 'success' : 'outline-success'"
            @click="currentObj.status = 'P'"
          >
            P
          </b-button>
          <b-button
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="currentObj.status == 'A' ? 'danger' : 'outline-danger'"
            @click="currentObj.status = 'A'"
          >
            A
          </b-button>
          <b-button
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="currentObj.status == 'L' ? 'info' : 'outline-info'"
            @click="currentObj.status = 'L'"
          >
            L
          </b-button>
          <b-button
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="
              currentObj.status.toLowerCase() == 'late'
                ? 'warning'
                : 'outline-warning'
            "
            @click="currentObj.status = 'Late'"
          >
            Late
          </b-button>
          <b-button
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="currentObj.status == 'H' ? 'info' : 'outline-info'"
            @click="currentObj.status = 'H'"
          >
            H
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="d-flex justify-content-end">
          <b-button
            @click="saveAtt()"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="success"
            :disabled="savingAtt"
          >
            <b-spinner v-if="savingAtt" small type="grow" />
            <span v-else class="align-middle">Save</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Details -->
    <b-row v-if="showreport">
      <b-col md="12" v-if="showDetails">
        <b-row class="mb-1">
          <b-col>
            <b-media vertical-align="center">
              <template #aside>
                <b-button
                  variant="outline-secondary"
                  class="btn-icon mr-1 rounded-circle"
                  @click="showDetails = false"
                  v-b-tooltip.hover.top
                  title="Back"
                >
                  <feather-icon class="" size="18" icon="ArrowLeftIcon" />
                  <!-- Back -->
                </b-button>
                <b-avatar
                  size="44"
                  :src="detailObj.picture"
                  variant="light-primary"
                />
              </template>

              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary">
                  {{ detailObj.name }}
                </b-badge>
              </b-link>
              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary">
                  F/N: {{ detailObj.father_name }}
                </b-badge>
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary">
                  {{ detailObj.cls }}
                </b-badge>
              </small>
            </b-media>
          </b-col>
        </b-row>
        <b-row align-h="center" v-if="detailLoading" class="mb-2">
          <b-spinner
            style="width: 3rem; height: 3rem"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </b-row>
        <b-row class="hover-card" v-else>
          <b-col md="3">
            <b-card bg-variant="success" text-variant="white" class="">
              <h4 class="text-white font-weight-bolder">Present</h4>
              <p class="font-weight-bolder mb-0 text-right summary-count">
                {{ countObj.present }}
              </p>
            </b-card>
          </b-col>
          <b-col md="3">
            <b-card bg-variant="danger" text-variant="white" class="">
              <h4 class="text-white font-weight-bolder">Absent</h4>
              <p class="font-weight-bolder mb-0 text-right summary-count">
                {{ countObj.absent }}
              </p>
            </b-card>
          </b-col>
          <b-col md="3">
            <b-card bg-variant="info" text-variant="white" class="">
              <h4 class="text-white font-weight-bolder">Leave</h4>
              <p class="font-weight-bolder mb-0 text-right summary-count">
                {{ countObj.leave }}
              </p>
            </b-card>
          </b-col>
          <b-col md="3">
            <b-card bg-variant="warning" text-variant="white" class="">
              <h4 class="text-white font-weight-bolder">Late</h4>
              <p class="font-weight-bolder mb-0 text-right summary-count">
                {{ countObj.late }}
              </p>
            </b-card>
          </b-col>
        </b-row>
        <b-card>
          <!-- <b-button
            variant="outline-primary"
            @click="loadDetails('week')"
            class="mr-1"
          >
            This Week
          </b-button>
          <b-button
            variant="outline-primary"
            @click="loadDetails('month')"
            class="mr-1"
          >
            This Month
          </b-button>
          <b-button
            variant="outline-primary"
            @click="loadDetails('year')"
            class="mr-1"
          >
            This Year
          </b-button>
          <b-button
            variant="outline-primary"
            @click="loadDetails('last year')"
            class="mr-1"
          >
            Last Year
          </b-button> -->

          <b-row>
            <b-col md="12" class="d-flex align-items-start flex-wrap">
              <b-button
                class="btn-icon mr-50 mb-1"
                v-for="mon in months"
                :key="mon.value"
                :variant="attMonth == mon.value ? 'primary' : 'outline-primary'"
                @click="
                  attMonth = mon.value;
                  moveCalendar();
                "
              >
                {{ mon.text.slice(0, 3) }}
              </b-button>
              <div style="width: 120px">
                <b-form-group label="">
                  <v-select
                    v-model="attYear"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="years"
                    :clearable="false"
                    placeholder="Select year"
                    @input="moveCalendar()"
                  />
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <full-calendar
            ref="refCalendar"
            :options="calendarOptions"
            class="full-calendar mt-1"
          >
            <template v-slot:eventContent="arg">
              <b class="font-large-3">{{ arg.event.title }}</b>
              <!-- <p v-if="arg.event.start" class="font-small-2 mb-25">
                <span class="text-dark text-uppercase">Time in: </span>
                <b-badge
                  :variant="getVariant(arg.event.title)"
                  class="font-small-2"
                >
                  {{
                    new Intl.DateTimeFormat("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                    }).format(new Date(arg.event.start))
                  }}
                </b-badge>
              </p> -->
            </template>
          </full-calendar>

          <b-table
            v-if="false"
            responsive
            :items="detailItems"
            :fields="detailFields"
            class="mt-1"
            show-empty
            :busy="detailLoading"
          >
            <template #table-busy>
              <div
                class="d-flex justify-content-center mb-3"
                style="margin-top: 50px"
              >
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  variant="primary"
                  label="Spinning"
                  type="grow"
                ></b-spinner>
              </div>
            </template>
            <template #cell(status)="data">
              <b-badge
                :variant="
                  data.value == 'P'
                    ? 'light-success'
                    : data.value == 'A'
                    ? 'light-danger'
                    : data.value == 'L' || data.value == 'H'
                    ? 'light-info'
                    : 'light-warning'
                "
              >
                {{ data.item.status }}
              </b-badge>
            </template>
            <template #cell(date)="data">
              <b-badge variant="light-primary">
                {{
                  new Date(data.value).toLocaleDateString("en-UK", {
                    year: "numeric",
                    day: "numeric",
                    month: "short",
                  })
                }}
              </b-badge>
            </template>
            <template #cell(time)="data">
              <b-badge variant="light-primary">
                {{
                  new Intl.DateTimeFormat("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                  }).format(new Date(data.item.date))
                }}
              </b-badge>
            </template>
          </b-table>
        </b-card>
      </b-col>

      <template v-else>
        <b-col md="12">
          <b-row align-h="center" v-if="summLoading" class="mb-2">
            <b-spinner
              style="width: 3rem; height: 3rem"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </b-row>
          <b-row class="" v-else>
            <b-col xl="2" md="4">
              <b-card body-class="pl-1">
                <b-media no-body>
                  <b-media-aside class="mr-50">
                    <b-avatar size="44" variant="light-success">
                      <feather-icon size="24" icon="UserCheckIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="">
                    <h4 class="font-weight-bolder mb-0">Present</h4>
                    <b-card-text class="mb-0">
                      {{ summaryObj.present }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-card>
            </b-col>
            <b-col xl="2" md="4">
              <b-card body-class="pl-1">
                <b-media no-body>
                  <b-media-aside class="mr-50">
                    <b-avatar size="44" variant="light-danger">
                      <feather-icon size="24" icon="UserXIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="">
                    <h4 class="font-weight-bolder mb-0">Absent</h4>
                    <b-card-text class="mb-0">
                      {{ summaryObj.absent }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-card>
            </b-col>
            <b-col xl="2" md="4">
              <b-card body-class="pl-1">
                <b-media no-body>
                  <b-media-aside class="mr-50">
                    <b-avatar size="44" variant="light-info">
                      <feather-icon size="24" icon="PlusCircleIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="">
                    <h4 class="font-weight-bolder mb-0">Leave</h4>
                    <b-card-text class="mb-0">
                      {{ summaryObj.leave }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-card>
            </b-col>
            <b-col xl="2" md="4">
              <b-card body-class="pl-1">
                <b-media no-body>
                  <b-media-aside class="mr-50">
                    <b-avatar size="44" variant="light-warning">
                      <feather-icon size="24" icon="ClockIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="">
                    <h4 class="font-weight-bolder mb-0">Late</h4>
                    <b-card-text class="mb-0">
                      {{ summaryObj.late }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-card>
            </b-col>
            <b-col xl="2" md="4">
              <b-card body-class="pl-1">
                <b-media no-body>
                  <b-media-aside class="mr-50">
                    <b-avatar size="44" variant="light-primary">
                      <feather-icon size="24" icon="CloudIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="">
                    <h4 class="font-weight-bolder mb-0">Holidays</h4>
                    <b-card-text class="mb-0">
                      {{ summaryObj.holidays }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-card>
            </b-col>
            <b-col xl="2" md="4">
              <b-card body-class="pl-1">
                <b-media no-body>
                  <b-media-aside class="mr-50">
                    <b-avatar size="44" variant="light-danger">
                      <feather-icon size="24" icon="InfoIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="">
                    <h4 class="font-weight-bolder mb-0">Suspended</h4>
                    <b-card-text class="mb-0">
                      {{ summaryObj.suspended }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <div class="d-flex justify-content-between">
            <b-button
              variant="outline-secondary"
              class="mr-50"
              @click="showreport = false"
            >
              <feather-icon class="" icon="ArrowLeftIcon" />
              Back
            </b-button>
            <!-- <div>
              <b-button
                class="mr-50"
                v-for="t in types"
                :key="type"
                :variant="t.value == report.type ? 'primary' : 'outline-primary'"
                @click="setType(t.value)"
              >
                {{ t.name }}
              </b-button>
            </div> -->
          </div>
          <b-card class="mt-1">
            <b-row style="align-items: center">
              <!-- <b-col md="2">
                <b-form-group
                  label="Select type"
                  invalid-feedback="type is required."
                  ref="type"
                >
                  <v-select
                    ref="type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    v-model="report.type"
                    :options="types"
                    :reduce="(opt) => opt.value"
                    label="name"
                    @input="setType()"
                    placeholder="select type"
                  />
                </b-form-group>
              </b-col> -->
              <b-col md="2" v-if="showClasses">
                <b-form-group
                  label="Select Class"
                  invalid-feedback="class is required."
                  ref="class"
                >
                  <v-select
                    ref="class"
                    multiple
                    :closeOnSelect="false"
                    v-model="classID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="classesOptions"
                    :clearable="false"
                    label="name"
                    :reduce="(val) => val.id"
                    @input="FillSections()"
                    placeholder="Select class"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2" v-if="showClasses">
                <b-form-group
                  label="Select Section"
                  invalid-feedback="section is required."
                  ref="section"
                >
                  <v-select
                    multiple
                    :closeOnSelect="false"
                    v-model="secID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="admittedSections"
                    :reduce="(val) => val.id"
                    :clearable="false"
                    @input="checkSections()"
                    ref="section"
                    label="section"
                    placeholder="Select section"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Date"
                  invalid-feedback=" date is required."
                  ref="date"
                >
                  <flat-pickr
                    ref="date"
                    :config="config"
                    v-model="report.dtFrom"
                    class="form-control"
                    placeholder="Select Date."
                  />
                  <!-- @input="setDate()" -->
                </b-form-group>
              </b-col>
              <b-button
                class="ml-1"
                :disabled="gridLoading"
                variant="primary"
                @click="loadgrid()"
              >
                Show
              </b-button>
              <b-button
                variant="success"
                class="ml-1"
                :disabled="saving"
                @click="save()"
              >
                Save
              </b-button>
              <b-button
                variant="success"
                class="ml-1"
                :disabled="saving"
                @click="openHoliday()"
              >
                Mark Holiday
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1 btn-icon"
                v-b-tooltip.hover.top
                title="Delete"
                @click="DeleteAtt()"
                :disabled="deleting"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
              <b-button
                class="ml-1 btn-icon"
                variant="outline-primary"
                v-b-tooltip.hover.top
                title="Smart Attendance"
                @click="showSmart()"
              >
                <feather-icon icon="CheckCircleIcon" />
              </b-button>
              <b-button
                class="ml-1 btn-icon"
                variant="outline-primary"
                v-b-tooltip.hover.top
                title="Reports"
                @click="openReport()"
              >
                <feather-icon icon="BarChart2Icon" />
              </b-button>
              <b-button
                class="ml-1 btn-icon"
                variant="outline-primary"
                v-b-tooltip.hover.top
                title="Messages"
                @click="openMessage()"
              >
                <feather-icon icon="MailIcon" />
              </b-button>
              <!-- <b-button
                class="ml-1 btn-icon"
                variant="outline-secondary"
                v-b-tooltip.hover.top
                title="Settings"
                @click="openSett()"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button> -->
            </b-row>
            <b-row class="mt-1" v-if="showGrid">
              <b-col lg="10" md="9">
                <b-form-group label="Search">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block"
                    placeholder="Search by name or gr"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3">
                <b-form-group label="Sort By">
                  <v-select
                    v-model="sortBy"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="sortOptions"
                    :reduce="(val) => val.value"
                    label="text"
                    @input="sortData()"
                    :clearable="false"
                    placeholder="Select"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-table
                  class="mt-1"
                  :busy="gridLoading"
                  show-empty
                  :items="filteredAtt"
                  :fields="fields"
                  responsive
                  hover
                  selectable
                  select-mode="single"
                  @row-selected="showDetail($event[0])"
                >
                  <template #table-busy>
                    <div
                      class="d-flex justify-content-center mb-3"
                      style="margin-top: 50px"
                    >
                      <b-spinner
                        style="width: 3rem; height: 3rem"
                        type="grow"
                        variant="primary"
                        label="Spinning"
                      ></b-spinner>
                    </div>
                  </template>

                  <template #cell(cls)="data">
                    <b-badge style="margin-inline: 2px" variant="light-primary">
                      {{ data.item.cls }}
                    </b-badge>
                  </template>

                  <!-- <template #cell(profile)="data">
                    <b-avatar
                      size="36"
                      class=""
                      variant="primary"
                      :src="data.item.picture"
                    />
                  </template> -->
                  <template #cell(checkin)="data">
                    <b-badge variant="light-primary" v-if="data.item.date">
                      <!-- @click="data.item.date = null" -->
                      {{
                        new Intl.DateTimeFormat("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                        }).format(new Date(data.item.date))
                      }}
                    </b-badge>
                    <b-form-group v-else class="mb-0">
                      <b-input-group>
                        <flat-pickr
                          :config="{
                            enableTime: true,
                            noCalendar: true,
                            altFormat: 'h:i K',
                            altInput: true,
                            dateFormat: 'Y-m-d\\TH:i:S',
                            defaultHour: 8,
                          }"
                          v-model="filteredAtt[data.index].inputTimein"
                          class="form-control"
                        />
                        <b-input-group-append
                          is-text
                          @click.stop="
                            filteredAtt[data.index].inputTimein = null
                          "
                          v-b-tooltip.hover.top
                          title="Clear"
                        >
                          <feather-icon
                            class="cursor-pointer"
                            size="16"
                            icon="XIcon"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </template>
                  <template #cell(status)="data">
                    <div class="d-flex align-items-center">
                      <b-button
                        pill
                        size="sm"
                        style="margin-inline: 2px"
                        :variant="
                          data.item.obj.status == 'P'
                            ? 'success'
                            : 'outline-success'
                        "
                        @click="changeAtt(data.index, 'P')"
                      >
                        P
                      </b-button>
                      <b-button
                        pill
                        size="sm"
                        style="margin-inline: 2px"
                        :variant="
                          data.item.obj.status == 'A'
                            ? 'danger'
                            : 'outline-danger'
                        "
                        @click="changeAtt(data.index, 'A')"
                      >
                        A
                      </b-button>
                      <b-button
                        pill
                        size="sm"
                        style="margin-inline: 2px"
                        :variant="
                          data.item.obj.status == 'L' ? 'info' : 'outline-info'
                        "
                        @click="changeAtt(data.index, 'L')"
                      >
                        L
                      </b-button>
                      <b-button
                        pill
                        size="sm"
                        style="margin-inline: 2px"
                        :variant="
                          data.item.obj.status.toLowerCase() == 'late'
                            ? 'warning'
                            : 'outline-warning'
                        "
                        @click="changeAtt(data.index, 'Late')"
                      >
                        Late
                      </b-button>
                      <b-button
                        pill
                        size="sm"
                        style="margin-inline: 2px"
                        :variant="
                          data.item.obj.status == 'H' ? 'info' : 'outline-info'
                        "
                        @click="changeAtt(data.index, 'H')"
                      >
                        H
                      </b-button>
                    </div>
                    <!-- <b-badge v-if="data.item.status == 'P'" variant="light-success">
                    {{ data.item.status }}
                  </b-badge>
                  <b-badge
                    v-else-if="data.item.status == 'L'"
                    variant="light-warning"
                  >
                    {{ data.item.status }}
                  </b-badge>
                  <b-badge v-else variant="light-danger">
                    {{ data.item.status }}
                  </b-badge> -->
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </template>
    </b-row>

    <!-- attendance card -->
    <b-row align-h="center" v-else-if="dataLoading">
      <b-spinner
        class="m-5"
        style="width: 4rem; height: 4rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>
    <div v-else>
      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Settings</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hideSideBar()"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="pt-1 pb-1">
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Barcode</h4>
              <b-form-checkbox
                v-model="settings.attendanceMode"
                switch
                value="barcode"
                unchecked-value=""
              />
            </b-col>
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Notification on arrival</h4>
              <b-form-checkbox v-model="settings.notifyArrival" switch />
            </b-col>
            <!-- <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Notification on departure</h4>
              <b-form-checkbox v-model="settings.notifyDeparture" switch />
            </b-col> -->
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Show dues</h4>
              <b-form-checkbox v-model="settings.showDues" switch />
            </b-col>
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Show recent attendance</h4>
              <b-form-checkbox v-model="settings.showRecent" switch />
            </b-col>
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
              md="12"
            >
              <h4 class="mr-1">Auto hide</h4>
              <b-form-checkbox v-model="settings.autoHide" switch />
            </b-col>
            <b-col v-if="settings.autoHide" md="12">
              <h4 class="mr-1">
                Hide After <span style="font-size: 14px">(seconds)</span>
              </h4>
              <b-form-input
                id="mc-first-name"
                type="number"
                placeholder=""
                ref="autosec"
                v-model="settings.hideAfterSeconds"
              />
            </b-col>
            <b-col class="mt-1" md="12">
              <b-button
                variant="success"
                class="mr-1 fullWidth"
                @click="saveSettings()"
                :disabled="request"
                block
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-if="request == false"> Save </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-row align-h="center">
        <b-col v-if="studentLoading" md="4">
          <b-card class="text-center p-1" style="position: relative">
            <b-spinner
              class="m-5"
              style="width: 4rem; height: 4rem"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </b-card>
        </b-col>
        <b-col lg="4" md="5" v-else>
          <b-card class="text-center p-1" style="position: relative">
            <!-- <feather-icon
              class="cursor-pointer"
              style="position: absolute; right: 15px; top: 15px"
              icon="SettingsIcon"
              size="22"
              v-b-tooltip.hover.right
              title="Settings"
              @click="showSettings()"
            /> -->
            <b-img
              v-if="
                currentStd.std.picture == '' || currentStd.std.picture == null
              "
              class="round mb-1"
              src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
              style="
                width: 100px;
                height: 100px;
                object-fit: cover;
                border-radius: 50%;
              "
              :class="{ 'set-picSize': picObj && picObj.valueBool }"
            />
            <b-img
              v-else
              class="round mb-1"
              :src="currentStd.std.picture"
              style="
                width: 100px;
                height: 100px;
                object-fit: cover;
                border-radius: 50%;
              "
              :class="{ 'set-picSize': picObj && picObj.valueBool }"
            />
            <h3 v-if="currentStd.std.name" class="mb-1">
              {{ currentStd.std.name }}
            </h3>
            <h3 v-else class="mb-1">-</h3>
            <h4 v-if="currentStd.std.cls" style="margin-bottom: 0.5rem">
              {{ currentStd.std.cls }}
            </h4>
            <h4 v-else style="margin-bottom: 0.5rem">-</h4>
            <h4 style="font-weight: 400; margin-bottom: 0.5rem">
              GR #
              <span v-if="currentStd.std.grNo">{{ currentStd.std.grNo }}</span>
              <span v-else>-</span>
            </h4>
            <h4
              v-if="settings.showDues"
              style="font-weight: 400; margin-bottom: 0.5rem"
            >
              Dues: <span v-if="currentStd.dues">{{ currentStd.dues }}</span>
              <span v-else>-</span>
            </h4>
            <h1 v-if="currentStd.time" class="mt-1">
              {{
                new Intl.DateTimeFormat("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                }).format(new Date(currentStd.time))
              }}
            </h1>

            <!-- <h4 class="text-danger font-weight-bolder">dues</h4> -->
            <b-row align-h="center">
              <b-form-input
                class="mt-1"
                style="width: 80%"
                id="mc-first-name"
                placeholder="Scan here"
                ref="name"
                autofocus
                v-model="studentID"
                @keyup.enter="GetStudent()"
              />
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="1" md="2" class="d-flex flex-wrap d-md-block">
          <b-card
            class="mb-1 mr-1 mr-md-0 rounded-circle"
            style="width: 45px; height: 45px"
            v-b-tooltip.hover.right="'Settings'"
            body-class="d-flex align-items-center justify-content-center p-50"
          >
            <feather-icon
              class="cursor-pointer"
              icon="SettingsIcon"
              size="22"
              @click="showSettings()"
            />
          </b-card>
          <b-card
            class="mb-1 mr-1 mr-md-0 rounded-circle"
            style="width: 45px; height: 45px"
            v-b-tooltip.hover.right="'Smart Attendance'"
            body-class="d-flex align-items-center justify-content-center p-50"
          >
            <feather-icon
              class="cursor-pointer"
              icon="CheckCircleIcon"
              size="22"
              @click="showSmart()"
            />
          </b-card>
          <b-card
            class="mb-1 mr-1 mr-md-0 rounded-circle"
            style="width: 45px; height: 45px"
            v-b-tooltip.hover.right="'Reports'"
            body-class="d-flex align-items-center justify-content-center p-50"
          >
            <feather-icon
              class="cursor-pointer"
              icon="BarChart2Icon"
              size="22"
              @click="openReport()"
            />
          </b-card>
          <b-card
            class="mb-1 mr-1 mr-md-0 rounded-circle"
            style="width: 45px; height: 45px"
            v-b-tooltip.hover.right="'Messages'"
            body-class="d-flex align-items-center justify-content-center p-50"
          >
            <feather-icon
              class="cursor-pointer"
              icon="MailIcon"
              size="22"
              @click="openMessage()"
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col lg="4" md="5">
          <b-button variant="primary" block @click="openDetails()">
            Show Details
          </b-button>
        </b-col>
        <b-col lg="1" md="2"></b-col>
      </b-row>
      <b-row v-if="this.settings.showRecent && this.filteredItems.length > 0">
        <b-col md="12">
          <h3>Recent Attendace</h3>
        </b-col>
        <b-col md="3" v-for="card in filteredItems" :key="card">
          <b-card>
            <b-row>
              <b-col md="3">
                <b-avatar
                  v-if="card.std.picture == '' || card.std.picture == null"
                  size="48"
                  src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                />
                <b-avatar v-else size="48" :src="card.std.picture" />
              </b-col>
              <b-col md="9">
                <h5>{{ card.std.name }}</h5>
                <h5>
                  {{ card.std.cls }}
                  <span class="ml-50">GR# {{ card.std.grNo }}</span>
                </h5>
                <h5>
                  {{
                    new Intl.DateTimeFormat("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                    }).format(new Date(card.time))
                  }}
                  <span v-if="settings.showDues" class="ml-50"
                    >Dues: {{ card.dues }}</span
                  >
                </h5>
              </b-col>
            </b-row>
            <!-- variant="transparent" -->
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BAvatar,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BModal,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";

export default {
  components: {
    //Multiselect,
    BFormTextarea,
    BMediaAside,
    BMedia,
    BCard,
    BRow,
    BImg,
    BAvatar,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BModal,
    BForm,
    BPagination,
    BSpinner,
    BDropdown,
    BDropdownItem,
    vSelect,
    flatPickr,
    FullCalendar,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    // if (!this.$store.state.rights[this.$route.name]) {
    //   // console.log(this.$store.state.rights[this.$route.name]);
    //   this.$router.replace({
    //     name: "misc-not-authorized",
    //   });
    // } else {
    //   this.rights = this.$store.state.rights[this.$route.name];
    //   this.LoadClasses();
    //   this.LoadSettings();
    //   this.loadSessions();
    //   this.loadOther();
    //   this.loadSummary();
    // }
    this.LoadClasses();
    this.LoadSettings();
    this.LoadDepartments();
    this.loadSessions();
    this.loadOther();
    this.loadSummary();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    filteredItems: function () {
      return this.items.slice(0, 12);
    },
    filteredAtt() {
      return this.gridData.filter((pro) => {
        return (
          pro.name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.grNo.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    let yList = [];
    for (let i = 2020; i <= 2040; i++) {
      yList.push(i);
    }
    return {
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      rights: {},
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        // mode: "range",
      },
      config2: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        headerToolbar: {
          start: "title",
          end: "",
        },
        events: [],
        eventResizableFromStart: true,
        dragScroll: true,
        dayMaxEvents: 2,
        // navLinks: true,
        eventClassNames({ event: e }) {
          const colorName =
            e.title == "P"
              ? "success"
              : e.title == "A" || e.title == "AA"
              ? "danger"
              : e.title == "L" || e.title == "H" || e.title == "SL"
              ? "info"
              : "warning";

          return [`text-${colorName}`];
        },
        eventClick: this.openEditAtt,
      },
      attMonth: new Date().getMonth() + 1,
      attYear: new Date().getFullYear(),
      currentObj: {
        status: "",
      },
      savingAtt: false,
      months: [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],
      years: yList,
      repDate: "",
      departments: [],
      request: false,
      sheetLoading: false,
      attendance: {
        picture: null,
        obj: {
          status: null,
        },
      },
      colorDep: [],
      visibility1: false,

      sidebarTitle: "Add Balance",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,
      studentLoading: false,
      visibility: false,
      visibility2: false,
      visibility3: false,
      visibility4: false,
      visibility5: false,
      logoloading: "",
      searchQuery: "",
      sortOptions: [
        { text: "Name", value: "name" },
        { text: "GR#", value: "gr" },
      ],
      sortBy: this.$store.state.attSort,
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      report: {
        type: "",
        dtFrom: new Date().toJSON().split("T")[0],
        // dtTo: null,
        clsIDs: [],
        secIDs: [],
      },
      showreport: false,
      rangeDate: null,
      types: [
        { name: "Student", value: "student" },
        { name: "Staff", value: "staff" },
      ],
      statusOptions: [
        { name: "All", value: "" },
        { name: "Present", value: "P" },
        { name: "Absent", value: "A" },
        { name: "Leave", value: "L" },
        { name: "Late", value: "Late" },
      ],
      smartOptions: [
        { name: "Absent", value: "A" },
        { name: "Leave", value: "L" },
        { name: "Late", value: "Late" },
      ],
      showClasses: false,
      classes: [],
      classesOptions: [{ name: "All Classes", id: 0 }],
      admittedSections: [{ section: "All Sections", id: 0 }],

      classID: [0],
      secID: [0],

      gridData: [],
      showGrid: false,
      gridLoading: false,
      fields: [
        // { label: "profile", key: "profile" },
        { label: "gr no", key: "grNo" },
        { label: "name", key: "name" },
        { label: "Father name", key: "father_name" },
        { label: "class", key: "cls" },
        { label: "status", key: "status" },
      ],

      errors: {
        status: false,
      },

      index: null,
      items: [],
      accountOptions: [
        // {
        //   id: 1,
        //   code: 999,
        // },
      ],

      secText: "Section",
      classText: "Class",
      selected: null,
      myObj: {
        picture: null,
        cls: null,
        grNo: null,
        name: null,
      },
      currentStd: {
        std: {
          picture: null,
          cls: null,
          grNo: null,
          name: null,
        },
      },
      settings: {
        id: 0,
        campusID: this.$store.state.userData.cId,
        notifyArrival: false,
        notifyDeparture: false,
        showDues: false,
        showRecent: false,
        autoHide: false,
        hideAfterSeconds: 5,
        attendanceMode: "",
      },
      msgObj: {
        dt: "",
        clsIDs: [0],
        secIDs: [0],
        status: "",
      },
      msgSecOptions: [{ section: "All Sections", id: 0 }],
      msgClass: [0],
      msgSection: [0],

      repObj: {
        type: "",
        dtFrom: "",
        clsIDs: [0],
        secIDs: [0],
        status: [],
      },
      repSecOptions: [{ section: "All Sections", id: 0 }],
      repClass: [0],
      repSection: [0],

      detailObj: {},
      timeout: 10000,
      studentID: null,
      saving: false,
      deleting: false,
      smartObj: {
        status: "",
        dt: new Date(),
        gr: "",
      },
      sessions: [],
      sessionID: 0,
      picObj: null,
      checkInObj: null,
      checkOutObj: null,
      countObj: {
        present: 0,
        absent: 0,
        leave: 0,
        late: 0,
      },
      summaryObj: {
        present: 0,
        absent: 0,
        leave: 0,
        late: 0,
        holidays: 0,
        suspended: 0,
      },
      summLoading: false,
      showDetails: false,
      detailLoading: false,
      detailItems: [],
      detailFields: [
        { label: "date", key: "date" },
        { label: "status", key: "status" },
        { label: "time", key: "time" },
      ],
      savingCh: false,
      holiObj: {
        dtFrom: "",
        dtTo: "",
        departmentIDs: [0],
      },
      visibility6: false,
      marking: false,
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    getVariant(title) {
      const vt =
        title == "P"
          ? "success"
          : title == "A" || title == "AA"
          ? "danger"
          : title == "L" || title == "H" || title == "SL"
          ? "info"
          : "warning";

      return vt;
    },

    openHoliday() {
      this.holiObj = {
        dtFrom: "",
        dtTo: "",
        departmentIDs: [0],
      };
      this.visibility6 = true;

      var elem = this.$refs["hol_dep"];
      elem.state = undefined;
      var elem = this.$refs["hol_dateF"];
      elem.state = undefined;
      var elem = this.$refs["hol_dateT"];
      elem.state = undefined;
    },
    setHoliDept() {
      if (this.holiObj.departmentIDs.at(-1) == 0) {
        this.holiObj.departmentIDs = [0];
      } else {
        this.holiObj.departmentIDs = this.holiObj.departmentIDs.filter(
          (el) => el != 0
        );
      }
    },
    async markHoliday() {
      // console.log(this.holiObj);
      if (
        !this.holiObj.dtFrom ||
        !this.holiObj.dtTo ||
        this.holiObj.departmentIDs.length == 0
      ) {
        return this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else if (this.holiObj.dtFrom > this.holiObj.dtTo) {
        return this.$bvToast.toast("Please select the date correctly!", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        // console.log(this.holiObj);
        this.marking = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/MarkHolidays?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: {
            departmentIDs:
              this.holiObj.departmentIDs[0] == 0
                ? []
                : this.holiObj.departmentIDs,
            dtFrom: this.holiObj.dtFrom,
            dtTo: this.holiObj.dtTo,
          },
          context: this,
          token: this.$store.state.userData.token,
          message: "Attendance marked as holiday",
        });
        this.marking = false;
        if (status) {
          this.visibility6 = false;
        }
      }
    },

    openSett() {
      this.visibility5 = true;
    },
    async saveChecks() {
      // console.log(this.checkInObj, this.checkOutObj);
      this.savingCh = true;
      var status = await this.post({
        url:
          this.$store.state.domain +
          "Settings/SaveNew?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: [this.checkInObj, this.checkOutObj],
        message: "Settings saved successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.savingCh = false;
      if (status) {
        this.loadOther();
        this.visibility5 = false;
      }
    },

    async loadSummary() {
      this.summLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Staff/LoadAttendanceByDate?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&dt=" +
          this.report.dtFrom +
          "&role=student",
        token: this.$store.state.userData.token,
        dashboard: true,
      };
      let res = await this.get(obj);
      this.summaryObj = res.summary;
      // console.log(this.summaryObj);
      this.summLoading = false;
    },
    showDetail(item) {
      this.detailObj = item;
      this.showDetails = true;

      this.attMonth = new Date().getMonth() + 1;
      this.attYear = new Date().getFullYear();

      this.loadDetails("");
    },
    async loadDetails(val) {
      this.detailLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Students/LoadAttendance?db=" +
          this.$store.state.userData.db +
          "&sID=" +
          this.detailObj.sID +
          "&duration=" +
          val,
        token: this.$store.state.userData.token,
        dashboard: true,
      };
      let res = await this.get(obj);
      this.detailItems = res.data;

      if (this.detailItems.length > 0) {
        this.calendarOptions.events = [];
        this.calendarOptions.events = this.detailItems.map((el) => ({
          ...el,
          title: el.status,
          start: el.date,
        }));
      } else {
        this.calendarOptions.events = [];
      }
      this.countObj = res.summary;
      // console.log("res:", res);
      this.detailLoading = false;

      this.moveCalendar();
    },
    moveCalendar() {
      // console.log(this.attYear, this.attMonth);
      let dt = `${this.attYear}-${this.attMonth
        .toString()
        .padStart(2, "0")}-01`;

      this.$nextTick(() => {
        var elem = this.$refs.refCalendar.getApi();
        elem.gotoDate(dt);
      });
    },
    openEditAtt({ event: clickedEvent }) {
      // console.log(clickedEvent);
      const { id, extendedProps, start, startStr } = clickedEvent;
      // let dt = `${start.getFullYear()}-${(start.getMonth() + 1)
      //   .toString()
      //   .padStart(2, "0")}-${start.getDate().toString().padStart(2, "0")}`;
      this.currentObj = { ...extendedProps, id: parseInt(id), date: startStr };
      this.$bvModal.show("modal-att");
    },
    async saveAtt() {
      // console.log(this.currentObj);
      this.savingAtt = true;
      var status = await this.put({
        url:
          this.$store.state.domain +
          "Attendance/" +
          this.currentObj.id +
          "?db=" +
          this.$store.state.userData.db,
        message: "Attendance updated successfully.",
        context: this,
        body: this.currentObj,
        token: this.$store.state.userData.token,
      });
      this.savingAtt = false;
      if (status) {
        this.loadDetails("");
        this.$bvModal.hide("modal-att");
      }
    },

    sortData() {
      this.$store.commit("setAttSort", this.sortBy);

      if (this.sortBy == "gr") {
        this.gridData = this.gridData.sort((a, b) =>
          a.grNo
            .toLowerCase()
            .localeCompare(b.grNo.toLowerCase(), "en", { numeric: true })
        );
      } else {
        this.gridData = this.gridData.sort((a, b) =>
          a.name
            .toLowerCase()
            .localeCompare(b.name.toLowerCase(), "en", { numeric: true })
        );
      }
    },

    async loadOther() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["picture_size"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      // "check_in", "check_out"
      // console.log(res);
      this.picObj = res.find((el) => el.key == "picture_size");
      // console.log(this.picObj);

      // let a = res.find((el) => el.key == "check_in");
      // if (!a) {
      //   this.checkInObj = {
      //     id: 0,
      //     campusID: this.$store.state.userData.cId,
      //     key: "check_in",
      //     value: "check_in",
      //     valueBool: false,
      //     valueNumber: null,
      //   };
      // } else this.checkInObj = a;

      // let b = res.find((el) => el.key == "check_out");
      // if (!b) {
      //   this.checkOutObj = {
      //     id: 0,
      //     campusID: this.$store.state.userData.cId,
      //     key: "check_out",
      //     value: "check_out",
      //     valueBool: false,
      //     valueNumber: null,
      //   };
      // } else this.checkOutObj = b;
      // // console.log(this.checkInObj, this.checkOutObj);
      // let obj = this.fields.find((el) => el.label == "checkin");
      // if (this.checkInObj.id > 0 && !this.checkInObj.valueBool && obj) {
      //   this.fields = this.fields.filter((el) => el.label != "checkin");
      // }
      // if (this.checkInObj.id > 0 && this.checkInObj.valueBool && !obj) {
      //   this.fields.splice(4, 0, { label: "checkin", key: "checkin" });
      // }
    },

    async loadSessions() {
      var obj = {
        url:
          this.$store.state.domain +
          "Sessions?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.sessions = await this.get(obj);
      let cc = this.sessions.find((el) => el.isCurrentSession);
      this.sessionID = cc ? cc.id : 0;
    },
    changeAtt(ind, val) {
      this.filteredAtt[ind].obj.status = val;
    },
    async save() {
      if (!this.report.dtFrom) {
        this.$bvToast.toast("Please select the date", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else if (this.gridData.length != 0) {
        this.saving = true;
        // this.gridData.forEach((el) => {
        //   el.date = !el.inputTimein
        //     ? this.report.dtFrom
        //     : `${this.report.dtFrom}T${el.inputTimein.split("T")[1]}`;
        // });
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/SaveWeb?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.gridData,
          message: "Attendance saved successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.saving = false;
        if (status) {
          this.loadgrid();
          // this.loadSummary();
        }
      }
    },
    openDetails() {
      this.showreport = true;
      this.report.type = "student";
      this.showClasses = true;
    },
    setType(val) {
      this.report.type = val;
      if (this.report.type == "student") this.showClasses = true;
      else this.showClasses = false;
    },

    openMessage() {
      this.msgObj = {
        dt: new Date().toJSON(),
        clsIDs: [0],
        secIDs: [0],
        status: "P",
      };
      this.visibility2 = true;

      var elem = this.$refs["msg_class"];
      elem.state = undefined;
      // var elem = this.$refs["msg_section"];
      // elem.state = undefined;
      var elem = this.$refs["msg_date"];
      elem.state = undefined;
    },

    async saveMessage() {
      // console.log(this.msgObj);
      if (
        this.msgClass.length == 0 ||
        this.msgSection.length == 0 ||
        this.msgObj.dt == ""
      ) {
        return this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.msgClass[0] !== 0) {
          this.msgObj.clsIDs = this.msgClass;
        } else {
          this.msgObj.clsIDs = [];
        }
        if (this.msgSection[0] !== 0) {
          //console.log(this.secID);
          this.msgObj.secIDs = this.msgSection;
        } else {
          this.msgObj.secIDs = [];
        }
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/SendMessage?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.msgObj,
          message: "Message send successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.visibility2 = false;
        }
      }
    },
    FillMsgSections() {
      // console.log(this.msgClass);
      if (this.msgClass[0] == 0 && this.msgClass.length !== 1) {
        this.msgClass = this.msgClass.filter((e) => e !== 0);
        // console.log(this.msgClass);
        this.classes.forEach((elem) => {
          if (this.msgClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.msgSecOptions.push(el));
            } else {
              // console.log("null");
              this.msgSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (
        this.msgClass[this.msgClass.length - 1] !== 0 &&
        this.msgClass.length == 1
      ) {
        this.classes.forEach((elem) => {
          if (this.msgClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.msgSecOptions.push(el));
            } else {
              // console.log("null");
              this.msgSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (this.msgClass[this.msgClass.length - 1] == 0) {
        this.msgClass = [0];
        // console.log(this.msgClass);
        this.msgSecOptions = [{ section: "All Sections", id: 0 }];
        this.msgSection = [0];
      } else {
        this.msgSecOptions = [{ section: "All Sections", id: 0 }];
        this.msgSection = [0];
      }
      // this.CheckClass();
    },
    checkMsgSections() {
      // console.log(this.msgSection);
      if (this.msgSection[0] == 0 && this.msgSection.length !== 1) {
        this.msgSection = this.msgSection.filter((e) => e !== 0);
        // console.log(this.msgSection);
      } else if (this.msgSection[this.msgSection.length - 1] == 0) {
        this.msgSection = [0];
        // console.log(this.msgSection);
      }
    },

    FillSections() {
      //   if (this.classID.includes(0) && this.classID.length == 2) {
      //     this.classID = this.classID.filter((e) => e !== 0);
      //     console.log(this.classID);
      //   }

      // console.log(this.classID);
      if (this.classID[0] == 0 && this.classID.length !== 1) {
        this.classID = this.classID.filter((e) => e !== 0);
        // console.log(this.classID);
        this.classes.forEach((elem) => {
          if (this.classID.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.admittedSections.push(el));
            } else {
              // console.log("null");
              this.admittedSections = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (
        this.classID[this.classID.length - 1] !== 0 &&
        this.classID.length == 1
      ) {
        this.classes.forEach((elem) => {
          if (this.classID.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.admittedSections.push(el));
            } else {
              // console.log("null");
              this.admittedSections = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (this.classID[this.classID.length - 1] == 0) {
        this.classID = [0];
        // console.log(this.classID);
        this.admittedSections = [{ section: "All Sections", id: 0 }];
        this.secID = [0];
      } else {
        this.admittedSections = [{ section: "All Sections", id: 0 }];
        this.secID = [0];
      }
      // this.CheckClass();
    },
    checkSections() {
      // console.log(this.secID);
      if (this.secID[0] == 0 && this.secID.length !== 1) {
        this.secID = this.secID.filter((e) => e !== 0);
        // console.log(this.secID);
      } else if (this.secID[this.secID.length - 1] == 0) {
        this.secID = [0];
        // console.log(this.secID);
      }
      // this.CheckSec();
    },
    CheckSec() {
      var elem = this.$refs["section"];
      if (this.secID.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    FillRepSections() {
      // console.log(this.repClass);
      if (this.repClass[0] == 0 && this.repClass.length !== 1) {
        this.repClass = this.repClass.filter((e) => e !== 0);
        // console.log(this.repClass);
        this.classes.forEach((elem) => {
          if (this.repClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.repSecOptions.push(el));
            } else {
              // console.log("null");
              this.repSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (
        this.repClass[this.repClass.length - 1] !== 0 &&
        this.repClass.length == 1
      ) {
        this.classes.forEach((elem) => {
          if (this.repClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.repSecOptions.push(el));
            } else {
              // console.log("null");
              this.repSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (this.repClass[this.repClass.length - 1] == 0) {
        this.repClass = [0];
        // console.log(this.repClass);
        this.repSecOptions = [{ section: "All Sections", id: 0 }];
        this.repSection = [0];
      } else {
        this.repSecOptions = [{ section: "All Sections", id: 0 }];
        this.repSection = [0];
      }
      // this.CheckClass();
    },
    checkRepSections() {
      // console.log(this.repSection);
      if (this.repSection[0] == 0 && this.repSection.length !== 1) {
        this.repSection = this.repSection.filter((e) => e !== 0);
        // console.log(this.repSection);
      } else if (this.repSection[this.repSection.length - 1] == 0) {
        this.repSection = [0];
        // console.log(this.repSection);
      }
    },
    setRepDate(sd, dateStr) {
      // console.log(sd, dateStr);
      if (dateStr) {
        if (sd.length > 1) {
          let date = dateStr.split(" to ");
          if (date.length == 1) {
            this.repObj.dtFrom = date[0];
            this.repObj.dtTo = date[0];
          } else {
            this.repObj.dtFrom = date[0];
            this.repObj.dtTo = date[1];
          }
        } else {
          this.repObj.dtFrom = dateStr;
          this.repObj.dtTo = null;
        }
      } else {
        this.repObj.dtFrom = null;
        this.repObj.dtTo = null;
      }
      // console.log(this.repObj);
    },
    setStatus() {
      if (this.repObj.status[this.repObj.status.length - 1] == "") {
        this.repObj.status = [""];
      } else {
        this.repObj.status = this.repObj.status.filter((el) => el != "");
      }
      // console.log(this.repObj.status);
    },
    openReport() {
      const fd = new Date();
      const firstDate = `${fd.getFullYear()}-${(fd.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-01`;

      const ld = new Date(fd.getFullYear(), fd.getMonth() + 1, 0);
      const lastDate = `${ld.getFullYear()}-${(ld.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${ld.getDate().toString().padStart(2, "0")}`;

      // console.log(firstDate, lastDate);
      this.repObj = {
        type: "",
        dtFrom: firstDate,
        dtTo: lastDate,
        clsIDs: [0],
        secIDs: [0],
        status: [""],
      };
      this.repDate = `${firstDate} to ${lastDate}`;

      this.visibility3 = true;

      var elem = this.$refs["rep_class"];
      elem.state = undefined;
      // var elem = this.$refs["rep_section"];
      // elem.state = undefined;
      var elem = this.$refs["rep_date"];
      elem.state = undefined;
    },

    async getReport() {
      // console.log(this.repObj);
      if (
        this.repClass.length == 0 ||
        this.repSection.length == 0 ||
        this.repObj.status.length == 0 ||
        !this.repObj.dtFrom ||
        !this.repObj.dtTo
      ) {
        return this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.repClass[0] !== 0) {
          this.repObj.clsIDs = this.repClass;
        } else {
          this.repObj.clsIDs = "";
        }
        if (this.repSection[0] !== 0) {
          this.repObj.secIDs = this.repSection;
        } else {
          this.repObj.secIDs = "";
        }
        let status = "";
        if (this.repObj.status[0] !== "") {
          status = this.repObj.status;
        }
        this.request = true;

        var url =
          `https://${this.reportDomain}.myskool.app/Attendance/Show?type=student&dtFrom=` +
          this.repObj.dtFrom +
          "&dtTo=" +
          this.repObj.dtTo +
          "&dbb=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&cls=" +
          this.repObj.clsIDs +
          "&sec=" +
          this.repObj.secIDs +
          "&status=" +
          status;

        window.open(url, "_blank");
        this.request = false;
        // if (status) {
        //   this.visibility3 = false;
        // }
      }
    },
    async getSheet() {
      if (this.repClass.length == 0 || this.repSection.length == 0) {
        return this.$bvToast.toast("Please select class and section", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.repClass[0] !== 0) {
          this.repObj.clsIDs = this.repClass;
        } else {
          this.repObj.clsIDs = "";
        }
        if (this.repSection[0] !== 0) {
          this.repObj.secIDs = this.repSection;
        } else {
          this.repObj.secIDs = "";
        }

        this.sheetLoading = true;
        if (this.$store.state.currentBranch.organizationType == "coaching") {
          var url =
            `https://${this.reportDomain}.myskool.app/List/Show?type=attendance&dbb=` +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&cls=" +
            this.repObj.clsIDs +
            "&sec=" +
            this.repObj.secIDs +
            "&session=" +
            this.sessionID +
            "&status=&mob=false";

          window.open(url, "_blank");
        } else {
          var url =
            `https://${this.reportDomain}.myskool.app/List/Show?type=attendance&dbb=` +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&cls=" +
            this.repObj.clsIDs +
            "&sec=" +
            this.repObj.secIDs +
            "&status=&mob=false";

          window.open(url, "_blank");
        }
        this.sheetLoading = false;
      }
    },
    async loadgrid() {
      if (
        this.classID.length > 0 &&
        this.secID.length > 0 &&
        this.report.dtFrom
      ) {
        this.gridLoading = true;
        this.showGrid = true;

        if (this.classID[0] !== 0) {
          this.report.clsIDs = this.classID;
        } else {
          this.report.clsIDs = [];
        }
        if (this.secID[0] !== 0) {
          //console.log(this.secID);
          this.report.secIDs = this.secID;
        } else {
          this.report.secIDs = [];
        }
        // console.log(this.report);
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/LoadAttendanceGrid?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.report,
          attendance: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        this.loadSummary();
        if (status) {
          this.gridData = status;
          // this.gridData = this.gridData.map((el) => ({
          //   ...el,
          //   inputTimein: el.date,
          // }));
          this.sortData();
        }
        this.gridLoading = false;
      } else {
        this.$bvToast.toast("Please select all required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    async DeleteAtt() {
      if (
        this.classID.length > 0 &&
        this.secID.length > 0 &&
        this.report.dtFrom
      ) {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "Do you want to delete the attendance?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
        });

        if (result.isConfirmed) {
          this.deleting = true;
          if (this.classID[0] !== 0) {
            this.report.clsIDs = this.classID;
          } else {
            this.report.clsIDs = [];
          }
          if (this.secID[0] !== 0) {
            //console.log(this.secID);
            this.report.secIDs = this.secID;
          } else {
            this.report.secIDs = [];
          }
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Attendance/DeleteAttendace?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            message: "Attendance deleted successfully!",
            context: this,
            body: this.report,
            token: this.$store.state.userData.token,
          });
          this.deleting = false;
          if (status) {
            this.loadgrid();
          }
        }
      } else {
        this.$bvToast.toast("Please select all required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    async GetStudent() {
      if (this.studentID.length !== 0) {
        this.studentLoading = true;
        var obj = {
          url:
            this.$store.state.domain +
            "Attendance/LoadStudentData?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&gr=" +
            this.studentID +
            "&userID=" +
            this.$store.state.userData.userID,
          token: this.$store.state.userData.token,
        };
        this.currentStd = await this.get(obj);
        // console.log("res:", this.currentStd);
        this.studentLoading = false;

        if (this.currentStd == "No data found.") {
          this.currentStd = {
            std: {
              picture: null,
              cls: null,
              grNo: null,
              name: null,
            },
            dues: null,
          };
          return this.$bvToast.toast("No student found.", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else if (this.currentStd == "Student's profile is not active.") {
          this.currentStd = {
            std: {
              picture: null,
              cls: null,
              grNo: null,
              name: null,
            },
            dues: null,
          };
          return this.$bvToast.toast("Student's profile is not active.", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else {
          // this.myObj = this.currentStd.std;
          // console.log("obj:", this.myObj);

          this.items.unshift(this.currentStd);
          // console.log(this.items);
        }
        this.studentID = "";
        if (this.settings.autoHide) {
          setTimeout(() => {
            this.currentStd = {
              std: {
                picture: null,
                cls: null,
                grNo: null,
                name: null,
              },
              dues: null,
            };
            this.studentID = "";
          }, this.settings.hideAfterSeconds * 1000);
        }
      }
    },
    CheckAccount() {
      var elem = this.$refs["account"];
      if (this.myObj.account == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckBalance() {
      var elem = this.$refs["balance"];
      var re = /^[0-9]+$/;
      if (re.test(this.myObj.balance) && this.myObj.balance !== 0) {
        this.myObj.balance = parseInt(this.myObj.balance);
        return (elem.state = true);
      } else {
        return (elem.state = false);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },

    hideAtt() {
      this.editAtt = false;
    },

    showSmart() {
      this.visibility4 = true;
    },
    async saveSmart() {
      if (
        this.smartObj.gr.trim() == "" ||
        this.smartObj.date == "" ||
        this.smartObj.status == ""
      ) {
        this.$bvToast.toast("Please enter the details properly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.smartObj.gr = this.smartObj.gr.replace(/\n|,/g, " ");
        // console.log(this.smartObj);
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/SmartAttendance?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.smartObj,
          message: "Attendance saved successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.visibility4 = false;
        }
      }
    },

    showSettings() {
      this.visibility = true;
    },
    async LoadSettings() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "AttendanceSettings/LoadData?campusID=" +
          this.$store.state.userData.cId +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj);
      this.dataLoading = false;
      if (result == null) {
        this.settings = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          notifyArrival: false,
          notifyDeparture: false,
          showDues: false,
          showRecent: false,
          autoHide: false,
          hideAfterSeconds: 5,
          attendanceMode: "",
        };
      } else {
        this.settings = result;
      }
      // console.log("set", this.settings);
    },
    async LoadClasses() {
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);
      // console.log("class", this.classes);
      this.classes.forEach((el) => {
        this.classesOptions.push(el.cls);
      });
    },
    async LoadDepartments() {
      var obj = {
        url:
          this.$store.state.domain +
          "departments?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.departments = await this.get(obj);
      if (this.departments.length > 0) {
        this.departments.unshift({ name: "All Departments", id: 0 });
      }
    },

    async saveSettings() {
      this.request = true;
      let x = parseInt(this.settings.hideAfterSeconds);
      this.settings.hideAfterSeconds = !x ? 5 : x;

      var status = await this.post({
        url:
          this.$store.state.domain +
          "AttendanceSettings/Save?db=" +
          this.$store.state.userData.db,
        body: this.settings,
        message: "Settings saved successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.request = false;
      if (status) {
        this.visibility = false;
        this.LoadSettings();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/apps/calendar.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
/* .card-body {
  padding: 0.85rem;
} */
@keyframes myfade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.set-picSize {
  width: 180px !important;
  height: 180px !important;
}
</style>
