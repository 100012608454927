<template>
  <div class="p-1">
    <div v-if="showError" class="w-100 text-center pt-4">
      <h2 class="mb-1">Page Not Found 🕵🏻‍♀️</h2>
      <p class="mb-2">
        Oops! 😖 The requested URL was not found on this server.
      </p>
      <b-img fluid :src="imgUrl" alt="Error page" />
    </div>

    <template v-else>
      <b-row v-if="schLoading" align-h="center" class="mb-1">
        <b-col class="text-center" lg="7" md="9">
          <b-spinner
            class="m-5"
            style="width: 4rem; height: 4rem"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </b-col>
      </b-row>
      <template v-else>
        <b-row align-h="center" class="mb-1">
          <b-col class="text-center" lg="7" md="9">
            <b-img
              v-if="school.logo"
              :src="school.logo"
              style="object-fit: contain"
              fluid
              height="100px"
              width="100px"
            />
            <b-img
              v-else
              src="https://cdn.cloudious.net/file-1678110140675-811622963.png"
              style="object-fit: contain"
              fluid
              height="100px"
              width="100px"
            />
            <div class="mt-1">
              <h3>{{ school.name }}</h3>
              <h5>
                <feather-icon icon="MapPinIcon" class="mr-50" />
                {{ school.address }}
              </h5>
              <h5>
                <feather-icon icon="PhoneIcon" class="mr-50" />
                {{ school.phone || school.mobile }}
              </h5>
            </div>
          </b-col>
        </b-row>

        <b-row align-h="center" v-if="!grEntered">
          <b-col md="6">
            <b-card>
              <div v-if="grLoading" class="text-center">
                <b-spinner
                  class="m-1"
                  style="width: 2.5rem; height: 2.5rem"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
              <b-row v-else>
                <b-col md="12">
                  <h5 class="mb-1">
                    Please enter your Enrollment No. to proceed.
                  </h5>
                </b-col>
                <b-col xl="9" sm="8">
                  <!-- label="Enrollment No." -->
                  <b-form-group
                    invalid-feedback="Enrollment No. is required."
                    class="mb-0"
                    ref="gr_input"
                  >
                    <b-form-input
                      ref="gr_field"
                      v-model.trim="grNo"
                      placeholder="Enter Enrollment no"
                      @keyup.enter="LoadStudent()"
                    />
                  </b-form-group>
                </b-col>
                <b-col xl="3" sm="4">
                  <b-button
                    variant="primary"
                    block
                    class="btn-icon"
                    @click="LoadStudent()"
                  >
                    Confirm
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <template v-else>
          <b-row align-h="center">
            <b-col md="10">
              <form-wizard
                ref="wizard"
                color="#7367F0"
                :title="null"
                :subtitle="null"
                shape="square"
                finish-button-text="Submit"
                back-button-text="Previous"
                class="mb-2"
              >
                <!-- @on-complete="formSubmitted" -->
                <b-row v-if="myObj" class="mb-1">
                  <!-- <b-col md="12">
                    <b-media vertical-align="center">
                      <template #aside>
                        <b-avatar
                          size="44"
                          :src="myObj.pic"
                          variant="light-primary"
                        />
                      </template>
  
                      <b-link
                        class="font-weight-bold d-block text-nowrap"
                        style="margin-bottom: 4px"
                      >
                        <b-badge variant="light-primary">
                          {{ myObj.name }}
                        </b-badge>
                      </b-link>
                      <small class="text-muted">
                        <b-badge variant="light-primary">
                          GR - {{ myObj.gr }}
                        </b-badge>
                        <b-badge
                          v-if="myObj.meta_class"
                          variant="light-primary"
                          class="ml-50"
                        >
                          {{ myObj.meta_class }} - {{ myObj.meta_section }}
                        </b-badge>
                      </small>
                    </b-media>
                  </b-col> -->

                  <b-col md="12" class="d-flex" style="gap: 1rem">
                    <div>
                      <b-img
                        v-if="myObj.picture"
                        :src="myObj.picture"
                        rounded
                        width="120"
                        height="120"
                        alt=""
                        style="object-fit: cover"
                      />
                      <b-img
                        v-else
                        src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                        width="120"
                        height="120"
                      />
                    </div>
                    <div>
                      <h5 class="mb-50">
                        Enrollment No: <span>{{ myObj.grNo }}</span>
                      </h5>
                      <h5 class="mb-50">
                        Name: <span>{{ myObj.name }}</span>
                      </h5>
                      <h5 class="mb-50">
                        Father Name: <span>{{ myObj.father_name }}</span>
                      </h5>
                      <h5 class="mb-50">
                        NIC No: <span>{{ myObj.bform_number }}</span>
                      </h5>
                    </div>
                  </b-col>
                </b-row>
                <tab-content title="Admit Card" icon="feather icon-file-text">
                  <b-row>
                    <b-col
                      md="12"
                      class="d-flex flex-wrap align-items-center"
                      style="column-gap: 1rem"
                    >
                      <b-form-group
                        label="Semester"
                        invalid-feedback="Semester is required."
                        ref="sem"
                        style="width: 150px"
                      >
                        <!-- <b-form-input
                          v-model.trim="reqObj.semester"
                          placeholder="Enter semester"
                        /> -->
                        <v-select
                          v-model="reqObj.semester"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :clearable="false"
                          :options="semOptions"
                          placeholder="Select semester"
                          @input="checkSem()"
                        />
                      </b-form-group>
                      <b-form-group
                        invalid-feedback="Type is required."
                        ref="type"
                        class="mb-0"
                      >
                        <b-form-checkbox
                          v-for="(item, ind) in typeOptions"
                          :key="ind"
                          v-model="reqObj.examType"
                          :value="item.value"
                          unchecked-value=""
                          class="mb-50"
                          inline
                        >
                          {{ item.text }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <h5>
                        Subjects papers of Examination required to appear in
                        <span class="font-small-3">(Max - 6)</span>
                        :
                      </h5>
                      <b-form-checkbox-group
                        id="checkbox-group-1"
                        v-model="selectedSubs"
                        stacked
                      >
                        <!-- :options="allSubjects"
                        value-field="id"
                        text-field="subject" -->
                        <b-row>
                          <b-col
                            lg="3"
                            md="4"
                            sm="6"
                            v-for="item in allSubjects"
                            :key="item.id"
                          >
                            <b-form-checkbox
                              :value="item.id"
                              @change="setSelected(item.id)"
                              class="mb-50"
                              >{{ item.subject }}</b-form-checkbox
                            >
                          </b-col>
                        </b-row>
                      </b-form-checkbox-group>
                    </b-col>
                  </b-row>
                  <b-row align-h="end" class="mt-1">
                    <b-col md="3">
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        block
                        :disabled="submitting"
                        @click="sendRequest()"
                      >
                        <b-spinner v-if="submitting" small type="grow" />
                        <span v-else>Request Admit Card</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </tab-content>
                <tab-content title="Challan" icon="feather icon-file">
                  <b-row>
                    <b-col md="3">
                      <b-button
                        variant="primary"
                        class="btn-icon mb-1"
                        block
                        @click="printChallan()"
                        :disabled="printing"
                      >
                        <b-spinner v-if="printing" small type="grow" />
                        <span v-else> Print Challan </span>
                      </b-button>
                    </b-col>
                  </b-row>
                </tab-content>
                <template slot="footer" slot-scope="props">
                  <div class="wizard-footer-left d-none"></div>
                  <div class="wizard-footer-right d-none"></div>
                </template>
              </form-wizard>
            </b-col>
          </b-row>
        </template>
      </template>
    </template>
  </div>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BMediaBody,
  BSpinner,
  BCardTitle,
  BCardText,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    FormWizard,
    TabContent,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BForm,
    BContainer,
    BMediaAside,
    BMediaBody,
    BSpinner,
    BCardTitle,
    BCardText,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkZW1vQG15c2tvb2wuYXBwIiwianRpIjoiZmRjZjdhYzQtNmY1OS00Nzg0LTkyNDItZjFjNjRhOTdjODQ0IiwiZXhwIjoxOTkzNzk2MDgwLCJpc3MiOiJteXNrb29sLmFwcCIsImF1ZCI6Im15c2tvb2wuYXBwIn0._pMXprq3AJApizQQlcrVXS3-dPyGFMi0nT9OfLQKBlo",
      dataLoading: false,
      schLoading: false,
      showError: false,
      school: {
        logo: "",
        name: "",
        address: "",
        phone: "",
      },
      db: "",
      cID: 0,
      sessions: [],
      allSubjects: [],
      grNo: "",
      grEntered: false,
      grLoading: false,
      dues: [],
      history: [],
      duesTotal: 0,
      myObj: null,
      // myObj: {
      //   id: 20,
      //   gr: "95",
      //   familyCode: 20,
      //   name: "Ahmed",
      //   fatherName: "Iqbal",
      //   classID: 20064,
      //   meta_class: "XII-Sci",
      //   secID: 1,
      //   meta_section: "Boys",
      //   campusID: 1,
      //   pic: "https://cdn.cloudious.net/file-1674048088395-571856006.jpg",
      //   doc: null,
      //   status: "Present",
      // },
      submitting: false,
      selectedSubs: [],
      typeOptions: [
        { text: "Fresh", value: "Fresh" },
        { text: "Improver", value: "Improver" },
        { text: "Failure", value: "Failure" },
      ],
      semOptions: ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th"],
      reqObj: {
        id: 0,
        sID: 0,
        type: "admitcard",
        requestDate: null,
        approvedBy: 0,
        approvedOn: null,
        status: "pending",
        note: "",
        attachments: "",
        semester: "",
        examType: "",
        subjects: "",
        campusID: 0,
      },
      printing: false,
      reportDomain: "",
    };
  },
  computed: {
    imgUrl() {
      let img = require("@/assets/images/pages/error.svg");
      if (this.$store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        img = require("@/assets/images/pages/error-dark.svg");
        return img;
      }
      return img;
    },
  },
  created() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "full");
    if (!this.$route.query.db || !this.$route.query.cID) {
      this.showError = true;
    } else {
      this.db = this.$route.query.db;
      let x = parseInt(this.$route.query.cID);
      if (isNaN(x) || x <= 0) {
        this.showError = true;
      } else {
        this.cID = x;
        this.LoadSchool();
        this.LoadClient();
      }
    }
  },
  beforeDestroy() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");
  },
  methods: {
    ...mapActions({
      get: "get",
      post: "post",
      put: "put",
      delete: "delete",
      postObj: "postObj",
      getString: "getString",
    }),

    setSelected(id) {
      // console.log("ch", this.selectedSubs);
      if (this.selectedSubs.length > 6) {
        this.selectedSubs = this.selectedSubs.filter((el) => el != id);
        this.$bvToast.toast("You can only select upto 6 subjects.", {
          title: "Alert!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },

    async printChallan() {
      if (this.reportDomain) {
        this.printing = true;
        let controller = "GenerateChallanStudentLeft";
        let contr = "ChallanLeft";
        if (this.myObj.status.toLowerCase() == "present") {
          controller = "GenerateChallanStudent";
          contr = "Challan";
        }
        var pObj = {
          fCode: 0,
          sID: this.myObj.id,
          cID: [],
          secID: [],
          feeTypeID: [],
          months: [],
          year: 0,
          familyWise: true,
          userID: 1,
          feeIDs: [],
        };
        var objData = await this.postObj({
          url:
            this.$store.state.domain +
            "feesChallan/" +
            controller +
            "?db=" +
            this.db +
            "&cID=" +
            this.cID +
            "&exp=false",
          body: pObj,
          message: null,
          context: this,
          token: this.token,
        });
        if (Array.isArray(objData) && objData.length > 0) {
          let url =
            `https://${this.reportDomain}.myskool.app/Fee/${contr}?dbb=` +
            this.db +
            "&sID=" +
            this.myObj.id +
            "&cID=" +
            this.cID +
            "&cls=&sec=&feeType=&fCode=-1&fcID=" +
            objData +
            "&duedate=" +
            new Date().toJSON().split("T")[0] +
            "&header=false";

          window.open(url, "_blank");
        } else {
          this.$bvToast.toast("Nothing to print.", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        }
        this.printing = false;
      } else {
        this.$bvToast.toast("No dues found", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    checkSem() {
      var elem = this.$refs["sem"];
      if (this.reqObj.semester == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkType() {
      // var elem = this.$refs["type"];
      if (this.reqObj.examType == "") {
        return false;
      } else {
        return true;
      }
    },
    checkSubs() {
      if (this.selectedSubs.length == 0)
        return "Please select subjects papers of Examination";
      else if (this.selectedSubs.length > 6) {
        return "You can only select upto 6 subjects.";
      } else return "";
    },
    async sendRequest() {
      if (this.checkSem() == false) {
        this.$bvToast.toast("Please enter the semester", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else if (this.checkType() == false) {
        this.$bvToast.toast("Please choose Fresh/Improver/Failure", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        const check = this.checkSubs();
        // console.log(check);
        if (check) {
          this.$bvToast.toast(check, {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else {
          this.submitting = true;

          this.reqObj.requestDate = new Date().toJSON();
          let subs = this.allSubjects
            .filter((el) => this.selectedSubs.includes(el.id))
            .map((el) => el.subject)
            .join(",");
          this.reqObj.subjects = subs;
          // console.log(this.reqObj);

          var status = await this.post({
            url:
              this.$store.state.domain +
              "formRequests?db=" +
              this.db +
              "&cID=" +
              this.cID,
            body: this.reqObj,
            message: "Request send successfully.",
            context: this,
            token: this.token,
          });
          this.submitting = false;

          if (status) {
            this.reqObj = {
              id: 0,
              sID: this.myObj.id,
              type: "admitcard",
              requestDate: null,
              approvedBy: 0,
              approvedOn: null,
              status: "pending",
              note: "",
              attachments: "",
              semester: "",
              examType: "",
              subjects: "",
              campusID: this.cID,
            };
            this.selectedSubs = [];
          }
        }
      }
    },

    checkGR() {
      var elem = this.$refs["gr_input"];
      if (this.grNo == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async LoadStudent() {
      if (this.checkGR() == true) {
        this.grLoading = true;

        var obj = {
          url:
            this.$store.state.domain +
            "Students/LoadByGr?db=" +
            this.db +
            "&cID=" +
            this.cID +
            "&keyword=" +
            this.grNo,
          token: this.token,
        };
        let res = await this.get(obj);
        // console.log("res", res);

        if (Array.isArray(res) || !res) {
          this.myObj = null;
          this.grLoading = false;
          this.grEntered = false;
          this.$bvToast.toast("Please enter correct Enrollment No.", {
            title: "No record found.",
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: true,
          });
          this.$nextTick(() => {
            let elem = this.$refs.gr_field;
            if (elem) elem.focus();
          });
        } else {
          this.LoadData();
          this.myObj = res;
          this.grLoading = false;
          this.grEntered = true;
          this.$nextTick(() => {
            this.$refs.wizard.activateAll();
          });
          this.reqObj = {
            id: 0,
            sID: this.myObj.id,
            type: "admitcard",
            requestDate: null,
            approvedBy: 0,
            approvedOn: null,
            status: "pending",
            note: "",
            attachments: "",
            semester: "",
            examType: "",
            subjects: "",
            campusID: this.cID,
          };
        }
      }
    },

    async LoadSchool() {
      this.schLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Campuses/GetSelected?id=" +
          this.cID +
          "&db=" +
          this.db,
        token: this.token,
      };
      let res = await this.get(obj);
      this.schLoading = false;
      if (res == "NotFound" || Array.isArray(res)) this.showError = true;
      else {
        this.school = res;
        this.$nextTick(() => {
          let elem = this.$refs.gr_field;
          if (elem) elem.focus();
        });
      }
      // console.log("sch:", this.school);
    },
    async LoadClient() {
      var obj = {
        url: this.$store.state.domain + "Campuses/GetClientID?db=" + this.db,
        token: this.token,
      };
      let res = await this.getString(obj);
      // console.log(res);
      if (typeof res == "string") {
        this.reportDomain = res.replace(/_/g, "");
      } else {
        this.reportDomain = "";
      }
    },
    async LoadData() {
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.db +
          "&cId=" +
          this.cID,
        token: this.token,
      };
      this.allSubjects = await this.get(obj);

      // this.dataLoading = true;
      // var obj2 = {
      //   url:
      //     this.$store.state.domain +
      //     "Sessions?db=" +
      //     this.db +
      //     "&cID=" +
      //     this.cID,
      //   token: this.token,
      // };
      // this.sessions = await this.get(obj2);
      // this.dataLoading = false;
    },
  },
};
</script>
<style>
.wizard-tab-content {
  padding-top: 10px !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-wizard.scss";
</style>
