<template>
  <p class="clearfix mb-0">
    <span
      v-if="hostname == 'connect.hpgs.pk' || hostname.includes('szablc.edu.pk')"
      class="float-md-left d-block d-md-inline-block mt-25"
    >
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link class="ml-25" href="#" target="_blank">{{
        hostname == "connect.hpgs.pk"
          ? "HPGS Connect"
          : hostname.includes("szablc.edu.pk")
          ? "SZABLC"
          : "MySkool"
      }}</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>
    <span v-else class="float-md-left d-block d-md-inline-block mt-25">
      Powered by
      <b-link href="https://appicksolutions.com/" target="_blank"
        >APPICK LLC</b-link
      >
    </span>

    <!-- <span class="float-md-right d-none d-md-block">Hand-crafted &amp; Made with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span> -->
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
  computed: {
    hostname() {
      return window.location.hostname;
    },
  },
};
</script>
