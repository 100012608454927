<template>
  <div class="navbar-container d-flex content align-items-center navScroll">
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <!-- @hidden="checkStatus()" -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <!-- <h5 v-if="showError" class="mb-0">Unable to connect!</h5> -->
        <template>
          <h5
            class="mb-0"
            v-if="
              this.waObj != null &&
              this.waObj.qrCode == '' &&
              !this.waObj.connected
            "
          >
            Connecting to WhatsApp ⏳
          </h5>
          <h5 class="mb-0" v-if="this.waObj != null && this.waObj.qrCode != ''">
            Please Scan the QR Code 📱
          </h5>
          <h5 class="mb-0" v-if="this.waObj != null && this.waObj.connected">
            Connected ✔️
          </h5>
        </template>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row>
          <!-- Animation Loading -->
          <!-- <b-col md="12" v-if="showError">
            <b-button variant="primary" block @click="showSidebar()">
              Try Again
            </b-button>
          </b-col> -->
          <b-col
            md="12"
            v-if="
              this.waObj == null ||
              (this.waObj.qrCode == '' && !this.waObj.connected)
            "
          >
            <div md="12" style="height: 300px">
              <lottie-animation path="wa-loading.json" />
            </div>

            <b-button
              v-if="false"
              variant="primary"
              block
              :disabled="disRetry"
              @click="loadRetry()"
            >
              Retry
              {{
                retryCount < 10
                  ? `(00 : 00 : 0${retryCount})`
                  : `(00 : 00 : ${retryCount})`
              }}
            </b-button>
          </b-col>

          <!-- Animation Connected -->
          <b-col md="12" v-if="this.waObj != null && this.waObj.connected">
            <div md="12" style="height: 300px">
              <lottie-animation path="connected.json" />
            </div>
            <b-button
              :to="{ name: 'apps-chat' }"
              variant="success"
              block
              v-if="showSendMsg"
            >
              Start Sending Messages
            </b-button>
          </b-col>

          <!-- Video -->
          <b-col
            md="12"
            v-if="
              this.waObj != null &&
              this.waObj.qrCode != '' &&
              !this.waObj.connected
            "
            style="display: flex; align-items: center; justify-content: center"
          >
            <div md="12">
              <div md="12">
                <!-- <h5 style="width: 100%">Scan QR Code</h5> -->
                <video
                  style="width: 98%"
                  autoplay="true"
                  controls=""
                  controlslist="nodownload"
                >
                  <source
                    src="https://cdn.cloudious.net/file-1676857951060-6456090.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </b-col>

          <!-- QR Code -->
          <b-col
            md="12"
            class="mt-5"
            v-if="
              this.waObj != null &&
              this.waObj.qrCode != '' &&
              !this.waObj.connected
            "
            style="display: flex; align-items: center; justify-content: center"
          >
            <div md="12">
              <!-- <h5 style="width: 100%">Scan QR Code</h5> -->
              <b-img
                :src="this.waObj.qrCode"
                fluid
                style="width: 300px; height: 300px; display: flex"
                alt="qr_code"
              />
            </div>
          </b-col>

          <!-- GetQRCode -->
          <b-col md="12" v-if="false">
            <b-button
              v-i
              variant="primary"
              :disabled="qrLoading"
              class="mt-2 ml-2 mr-2"
              style="width: 90%"
              @click="GetQRCode()"
            >
              <div v-if="qrLoading">
                <b-spinner
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
              <span v-else>Get New QR Code</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-modal
      id="modal-campuses"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Business"
      body-class="p-0"
    >
      <div class="pt-2 pl-2 pr-2 pb-0">
        <b-form-group label="Search" ref="search">
          <b-form-input
            v-model="searchQuery"
            placeholder="enter name to search"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div class="scrollContent">
        <h5
          v-for="item in filteredOptions"
          :key="item.id"
          class="pt-50 pb-50 pl-2 pr-2 font-weight-300 mb-0 cursor-pointer"
          :class="[
            item.id == current && 'text-white bg-primary',
            item.id != current && 'search-type',
          ]"
        >
          <!-- @click="ChangeBranch(item)" -->
          {{ item.branch }}
        </h5>
      </div>
    </b-modal>

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <!-- <vertical-nav-menu-items
      :items="navMenuItems"
      class="navigation navigation-main"
    /> -->
    <div class="d-flex align-items-center justify-content-start">
      <b-button
        class="mr-1 btn-icon"
        v-for="item in menuItems"
        :key="item"
        :to="{ name: item.route }"
        :variant="
          routeVariant(item.route) ? 'outline-light' : 'outline-secondary'
        "
        :class="[routeVariant(item.route) && 'btn-gradient-primary']"
      >
        <span v-if="textAdjust">{{ item.title.substr(0, 3) }}</span>
        <span v-else>{{ item.title }}</span>
        <!-- <span>{{ item.title }}</span> -->
      </b-button>
    </div>

    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

      <bookmarks />
    </div> -->

    <b-navbar-nav
      class="nav align-items-center justify-content-end ml-auto adjustAlign"
    >
      <b-dropdown
        v-if="campusList.length > 1"
        class="adjust-dropdown"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        split
        size="sm"
        variant="outline-primary"
        :text="branchName"
        right
        :disabled="changingDb"
      >
        <b-dropdown-item
          v-for="(d, ind) in campusList"
          :key="ind"
          @click="ChangeDB(d)"
          :active="
            current == d.id && $store.state.userData.db == d.organizationType
              ? true
              : false
          "
        >
          {{ d.branch }}
        </b-dropdown-item>

        <b-dropdown-divider />
      </b-dropdown>

      <b-dropdown
        v-else-if="
          this.$store.state.userData.roles == 'administrator' ||
          this.$store.state.userData.roles == 'admin' ||
          showCampus
        "
        class="adjust-dropdown"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        split
        size="sm"
        variant="outline-primary"
        :text="branchName"
        right
      >
        <!-- @show="testing" -->
        <b-dropdown-item
          v-for="(d, ind) in branches"
          :key="ind"
          @click="ChangeBranch(d)"
          :active="current == d.id ? true : false"
        >
          {{ d.branch }}
        </b-dropdown-item>

        <b-dropdown-divider />
      </b-dropdown>
      <!-- whatsapp-Icon -->
      <div
        v-if="showWhatsapp"
        style="width: 35px; height: 35px"
        class="ml-75 mr-75 position-relative text-center cursor-pointer"
      >
        <b-spinner
          v-if="statusLoading"
          small
          variant="primary"
          label="loading"
          class="mt-75"
        ></b-spinner>

        <div v-else @click="showSidebar()">
          <!-- <b-avatar
            size="35"
            badge
            class="badge-minimal bg-white"
            :badge-variant="
              statusW
                ? statusW.toLowerCase() == 'connected'
                  ? 'success'
                  : 'danger'
                : 'danger'
            "
          >
            <b-img :src="waIcon" fluid-grow alt="whatsapp" />
          </b-avatar> -->

          <b-img :src="waIcon" fluid-grow alt="whatsapp" />
          <feather-icon
            v-if="
              statusW &&
              (statusW.toLowerCase() == 'connected' ||
                statusW.toLowerCase() == 'opening')
            "
            icon="CheckIcon"
            class="text-success status-icons"
            size="18"
          />
          <feather-icon
            v-else
            icon="XIcon"
            class="text-danger status-icons"
            size="18"
          />
        </div>
      </div>
      <!-- whatsapp-Icon -->

      <notification-dropdown-new
        v-if="(notiObj && notiObj.valueBool) || compNotify"
        :data="notificationData"
      />
      <user-dropdown
        :dwObj="dwObj"
        :appObj="appObj"
        :pauseObj="pauseObj"
        :status="statusW"
        :showWa="showWhatsapp"
        :loader="statusLoading"
        @set-loader="setLoader"
        @show-sidebar="showSidebar"
      />
    </b-navbar-nav>
  </div>
</template>

<script>
var axios = require("axios");

import {
  BLink,
  BNavbarNav,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BButton,
  BImg,
  BSidebar,
  BCol,
  BRow,
  BForm,
  BSpinner,
  BFormInput,
  BAvatar,
} from "bootstrap-vue";
import Bookmarks from "./components/Bookmarks.vue";
import Locale from "./components/Locale.vue";
import SearchBar from "./components/SearchBar.vue";
import DarkToggler from "./components/DarkToggler.vue";
import CartDropdown from "./components/CartDropdown.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import NotificationDropdownNew from "./components/NotificationDropdownNew.vue";
import UserDropdown from "./components/UserDropdown.vue";
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import navMenuItems from "@/navigation/vertical";
import router from "@/router";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { $themeConfig } from "@themeConfig";

// ============= Firebase ====================
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, set } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAkVYvsmEbqJ-QsKWtY_Qcw2D_0ifHkiGw",
  authDomain: "ms-whatsapp-node.firebaseapp.com",
  databaseURL: "https://ms-whatsapp-node-default-rtdb.firebaseio.com",
  projectId: "ms-whatsapp-node",
  storageBucket: "ms-whatsapp-node.appspot.com",
  messagingSenderId: "709237835697",
  appId: "1:709237835697:web:b03cf39091a3941fbc1f90",
  measurementId: "G-FR17GV0P2C",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
// ============= Firebase ====================

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
  components: {
    // VerticalNavMenuItems,
    LottieAnimation,
    BLink,
    BButton,
    vSelect,
    BFormGroup,
    BFormInput,
    // Navbar Components
    BSpinner,
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    NotificationDropdownNew,
    UserDropdown,
    BImg,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BRow,
    BCol,
    BForm,
    BAvatar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      disRetry: true,
      retryID: null,
      retryCount: 15,
      breakpoints: {
        1024: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
      i: 0,
      clientID: "",
      firebaseKey: "",
      qrLoading: false,
      qrCode: "",
      visibility: false,
      loading: "https://cdn.cloudious.net/file-1676704112267-760147267.gif",
      // waIcon: "https://cdn.cloudious.net/file-1676700522879-523223941.png",
      waIcon: "https://cdn.cloudious.net/file-1676700040613-583826971.svg",
      waObj: {
        qrCode: "",
        connected: false,
      },
      showError: false,
      items: [],
      current: this.$store.state.userData.cId,
      navMenuItems,
      // branches: this.$store.state.branches,
      // branchName: "",
      statusW: "",
      statusLoading: true,
      intervalId: null,
      notiInterval: null,
      rights: {},
      campusList: [],
      searchQuery: "",
      notificationData: {
        count: 0,
        data: [],
      },
      compNotify: false,
      notiObj: null,
      changingDb: false,
      dwObj: null,
      appObj: null,
      pauseObj: null,
      setupObj: null,
      hpgsCover: require("@/assets/images/logo/hpgs-logo.png"),
      hpgsSmall: require("@/assets/images/logo/hpgs-small.png"),
      szablcCover: require("@/assets/images/logo/szablc-cover.png"),
      szablcSmall: require("@/assets/images/logo/szablc-small.png"),
    };
  },
  computed: {
    filteredOptions() {
      return this.branches.filter((pro) => {
        return pro.branch.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
    textAdjust() {
      return window.innerWidth <= 768;
    },
    branches() {
      return this.$store.state.branches;
    },
    branchName() {
      return this.$store.state.branchName;
    },
    menuItems() {
      return this.$store.state.currentMenu.children;
    },
    showWhatsapp() {
      let show = false;
      //console.log(this.$store.state.menu);
      const msgRight = this.$store.state.menu.find(
        (el) => el.name == "Messages"
      );
      if (msgRight) {
        msgRight.children.forEach((el) => {
          if (el.route == "apps-chat" || el.route == "apps-groups") {
            show = true;
          }
        });
      }
      return show;
    },
    showSendMsg() {
      let show = true;
      const msgRight = this.$store.state.menu.find(
        (el) => el.name == "Messages"
      );
      if (msgRight && msgRight.children[0].route == "apps-groups") {
        show = false;
      }
      return show;
    },
    showCampus() {
      let show = this.$store.state.permissions == "switch-campus";
      // const camp = this.$store.state.menu.find((el) => el.name == "Home");
      // if (camp) {
      //   let ch = camp.children.find((el) => el.route == "manage-branches");
      //   show = ch && ch.permission == "switch-campus";
      // }
      return show;
    },
  },

  async created() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");
    // console.log(this.$store.state.dbList);
    // console.log(this.$store.state.report);

    if (
      !this.$store.state.userData.password ||
      !this.$store.state.userData.encPassword
    ) {
      this.logout();
    } else {
      const check = await this.checkLogin();
      if (check) {
        this.loadSetting();

        this.RefreshMenu();
        this.loadDbs();
        if (window.location.hostname == "connect.hpgs.pk") {
          this.$store.commit("appConfig/UPDATE_COVERIMAGE", this.hpgsCover);
          this.$store.commit("appConfig/UPDATE_APP_LOGOIMAGE", this.hpgsSmall);
        } else if (window.location.hostname.includes("szablc.edu.pk")) {
          this.$store.commit("appConfig/UPDATE_COVERIMAGE", this.szablcCover);
          this.$store.commit(
            "appConfig/UPDATE_APP_LOGOIMAGE",
            this.szablcSmall
          );
        } else this.loadBranding();

        this.LoadData();

        // === latest ===
        // this.statusLoading = true;
        // // let val = await this.checkClient();
        // let val = "";
        // this.statusLoading = false;
        // if (val) {
        //   // console.log(val);
        //   this.statusW = "";
        //   this.disconnect();
        // }
        // this.statusLoading = true;
        // var obj2 = {
        //   url:
        //     "https://" +
        //     this.$store.state.WAdomain +
        //     "/status?db=" +
        //     this.clientID +
        //     "&cID=" +
        //     this.$store.state.userData.cId +
        //     "&dbEnc=" +
        //     this.$store.state.userData.db,
        //   headers: {
        //     Authorization: "bearer " + this.$store.state.userData.token,
        //   },
        // };
        // var data = await axios(obj2);
        // // console.log(data);
        // this.statusW = data.data.state;
        // this.statusLoading = false;
        // this.intervalId = setInterval(this.checkStatus, 15000);
        // === latest ===

        // ================
        // if (this.intervalId) clearInterval(this.intervalId);
        // if (this.statusW.toLowerCase() == "connected") {
        //   this.intervalId = setInterval(this.checkStatus, 15000);
        // }
        // ================

        // const branch = this.branches.find((el) => el.id == this.current);
        // this.$store.commit("setBranchName", branch.branch);
        // let logo = branch.logo.split("/")[3];
        // this.$store.commit("setLogo", logo);
      } else {
        this.logout();
      }
    }
  },
  beforeDestroy() {
    if (this.intervalId) clearInterval(this.intervalId);
    if (this.notiInterval) clearInterval(this.notiInterval);
  },
  methods: {
    ...mapActions({
      get: "get",
      post: "post",
      put: "put",
      delete: "delete",
      postObj: "postObj",
      getString: "getString",
    }),

    testing(e) {
      // console.log(e);
      e.preventDefault();
      this.$bvModal.show("modal-campuses");
    },

    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "full");

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);
      this.$store.commit("setPassword", "");
      this.$store.commit("setEncPassword", "");
      this.$store.commit("setDBList", []);
      // Redirect to login page
      if (this.intervalId) clearInterval(this.intervalId);
      if (this.notiInterval) clearInterval(this.notiInterval);

      this.$router.push({ name: "auth-login" });
    },

    async checkLogin() {
      var config = {
        method: "post",
        url: "https://connect.myskool.app/api/Tokens/UserLogin",
        data: {
          username: this.$store.state.userData.username,
          password: this.$store.state.userData.password,
        },
      };
      try {
        var response = await axios(config);
        // console.log(response.data);
        if (
          response.data.data.role !== "parent" &&
          response.data.data.role !== "student" &&
          response.data.data.role !== "teacher"
        ) {
          var obj = {
            url:
              this.$store.state.domain +
              "Campuses/GetClientID?db=" +
              this.$store.state.userData.db,
            token: this.$store.state.userData.token,
          };
          this.clientID = await this.getString(obj);
          this.$store.commit("setClientID", this.clientID);
          this.$store.commit("setReport", this.$store.state.clientID);
          // console.log(this.$store.state.clientID);
          this.firebaseKey =
            this.clientID + "_" + this.$store.state.userData.cId;

          // this.saveLog(response.data.data);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        // console.log(error);
        return false;
      }
    },
    async saveLog(item) {
      // for timezone issue
      const dt = new Date();
      const date = `${dt.getFullYear()}-${(dt.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")}`;
      const time = new Date().toTimeString().split(" ")[0];

      const logdate = `${date}T${time}`;

      let obj = {
        id: 0,
        localID: item.localID,
        campusID: item.campusID,
        userType: "user",
        loggedInAt: logdate,
        duration: "",
      };

      let resp = await this.postObj({
        url:
          this.$store.state.domain +
          "UserLoginLog?db=" +
          this.$store.state.userData.db,
        body: obj,
        message: null,
        context: this,
        token: this.$store.state.userData.token,
        ignoreMessage: true,
      });
    },

    RefreshMenu() {
      var config = {
        url:
          this.$store.state.domain +
          "Users/GetSelected?id=" +
          this.$store.state.userData.userID +
          "&db=" +
          this.$store.state.userData.db,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          this.$store.commit("setLoggedUser", res.data.data);

          let mm = JSON.parse(res.data.data.forms);
          let obj = mm.find((el) =>
            el.children.find(
              (ch) =>
                !ch.view && !ch.add && !ch.edit && !ch.delete && ch.permission
            )
          );
          // console.log(obj);
          if (obj) {
            let child = obj.children.find(
              (ch) =>
                !ch.view && !ch.add && !ch.edit && !ch.delete && ch.permission
            );
            // console.log(child)
            this.$store.commit("setPermissions", child.permission);

            obj.children = obj.children.filter(
              (el) => el.view || el.add || el.edit || el.delete
            );
            if (obj.children.length == 0) {
              mm = mm.filter((el) => el.id != obj.id);
            } else {
              let ind = mm.findIndex((el) => el.id == obj.id);
              mm.splice(ind, 1, obj);
            }
          } else {
            let other = mm.find((el) =>
              el.children.find((ch) => ch.permission)
            );
            // console.log(other);
            if (other) {
              let otherCh = other.children.find((ch) => ch.permission);
              this.$store.commit("setPermissions", otherCh.permission);
              // console.log(otherCh);
            } else this.$store.commit("setPermissions", "");
          }
          // console.log(mm);

          this.$store.commit("setMenu", mm);
          // this.$store.commit("setMenu", JSON.parse(res.data.data.forms));
          //this.$store.commit("setCurrentMenu", this.$store.state.menu[0]);

          this.$store.state.menu.forEach((el) => {
            el.children.forEach((ch) => {
              this.rights[ch.route] = {
                view: ch.view,
                add: ch.add,
                edit: ch.edit,
                delete: ch.delete,
              };
            });
          });

          // console.log(this.rights);
          this.$store.commit("setRights", this.rights);
          if (
            this.clientID.replace(/_/g, "") == "myskoolstjacob" &&
            this.rights["school-complaints"]
          ) {
            this.LoadComplaints();
            this.compNotify = true;
          }
        })
        .catch((error) => {});
    },
    async LoadComplaints() {
      var obj = {
        url:
          this.$store.state.domain +
          "SchoolComplaints/LoadData?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&categoryID=0",
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      if (Array.isArray(res)) {
        let count = res.filter(
          (el) => el.us.status.toLowerCase() == "pending"
        ).length;

        if (count > 0) {
          this.notificationData = {
            count: 1,
            data: [
              {
                title: "Complaints",
                body: `You have ${count} pending complaints`,
                isRead: true,
                route: "school-complaints",
              },
            ],
          };
        } else {
          this.notificationData = {
            count: 0,
            data: [],
          };
        }
      }
    },
    loadDbs() {
      var config = {
        method: "post",
        url: "https://connect.myskool.app/api/Tokens/GetDbList",
        data: {
          username: this.$store.state.userData.username,
          password: this.$store.state.userData.encPassword,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.$store.commit("setDBList", res.data.data);
          if (res.data.data.length > 1) {
            this.loadCampusList();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadCampusList() {
      var config = {
        method: "post",
        url: this.$store.state.domain + "Campuses/GetUserCampusList",
        data: this.$store.state.dbList,
      };
      axios(config)
        .then((res) => {
          // console.log(res.data);
          this.campusList = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async loadBranding() {
      var obj = {
        url:
          this.$store.state.domain +
          "branding/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      if (res.length > 0) {
        let obj = res[0];
        if (!obj.webLoginCover || obj.webLoginCover == "-") {
          this.$store.commit(
            "appConfig/UPDATE_COVERIMAGE",
            $themeConfig.app.coverImage
          );
        } else {
          this.$store.commit("appConfig/UPDATE_COVERIMAGE", obj.webLoginCover);
        }
        if (!obj.webLogo || obj.webLogo == "-") {
          this.$store.commit(
            "appConfig/UPDATE_APP_LOGOIMAGE",
            $themeConfig.app.appLogoImage
          );
        } else {
          this.$store.commit("appConfig/UPDATE_APP_LOGOIMAGE", obj.webLogo);
        }
      } else {
        this.$store.commit(
          "appConfig/UPDATE_COVERIMAGE",
          $themeConfig.app.coverImage
        );
        this.$store.commit(
          "appConfig/UPDATE_APP_LOGOIMAGE",
          $themeConfig.app.appLogoImage
        );
      }
    },

    routeVariant(route) {
      return this.$route.name == route;
    },
    async checkClient() {
      var obj = {
        url:
          this.$store.state.domain +
          "Campuses/validateClient?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      return res;
    },
    async disconnect() {
      var obj2 = {
        url:
          "https://" +
          this.$store.state.WAdomain +
          "/disconnect?db=" +
          this.clientID +
          "&cID=" +
          this.$store.state.userData.cId +
          "&dbEnc=" +
          this.$store.state.userData.db,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      var data = await axios(obj2);
    },
    async checkStatus() {
      // ================
      // this.statusLoading = true;
      // ================
      // console.log("general");
      try {
        var obj2 = {
          url:
            "https://" +
            this.$store.state.WAdomain +
            "/status?db=" +
            this.clientID +
            "&cID=" +
            this.$store.state.userData.cId +
            "&dbEnc=" +
            this.$store.state.userData.db,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };
        var data = await axios(obj2);
        // console.log(data);
        this.statusW = data.data.state;
      } catch (error) {
        // console.log("===", error);
        this.statusW = "";
      }

      // ================
      // this.statusLoading = false;
      // if (this.statusW.toLowerCase() !== "connected") {
      //   clearInterval(this.intervalId);
      // }
    },
    async WhatsApp() {
      //Read from Firebase
      // console.log("==========");
      const childRef = ref(database, this.firebaseKey);
      onValue(childRef, (snapshot) => {
        var data = snapshot.val();
        if (data == null) {
          data = {
            qrCode: "",
            connected: false,
            newQR: false,
          };
        }
        // console.log("=========== DATA CHANGED ==============");

        this.waObj = data;
        this.i = this.i + 1;
        // console.log(this.waObj);
        if (data != null) {
          // clearInterval(this.retryID);
          this.qrCode = data.qrCode;
          if (data.connected) {
            // console.log("w-connected");
            this.waIcon =
              "https://cdn.cloudious.net/file-1676700040613-583826971.svg";
            //this.visibility = false;

            //  ================
            //if (this.intervalId) clearInterval(this.intervalId);
            this.statusW = "connected";
            //this.intervalId = setInterval(this.checkStatus, 15000);
          } else {
            // console.log("dis");
            // if (this.retryID) clearInterval(this.retryID);
            // this.retryCount = 15;
            // this.disRetry = true;
            // this.retryID = setInterval(this.setRetry, 1000);

            // console.log(data);
            this.qrCode = "";
            this.waIcon =
              "https://cdn.cloudious.net/file-1676700040613-583826971.svg";
          }
        } else {
          this.qrCode = "";
          this.waIcon =
            "https://cdn.cloudious.net/file-1676700040613-583826971.svg";
        }
      });
    },
    async GetQRCode() {
      this.qrLoading = true;
      set(ref(database, this.firebaseKey), {
        connected: false,
        qrCode: "",
        newQR: true,
      });

      //Hit API
      await this.ConnectAndSend();
    },
    async ConnectAndSend() {
      console.log("Connecting to API...");
      try {
        var params = {
          clientID: this.clientID,
          timeOut: 8000,
          db: this.$store.state.userData.db,
          cID: this.$store.state.userData.cId,
        };
        let resp = await this.postObj({
          url: "https://" + this.$store.state.WAdomain + "/connect",
          body: params,
          message: null,
          context: this,
          token: this.$store.state.userData.token,
          ignoreMessage: true,
        });
        // console.log(resp, this.waObj);
        if (resp != false) {
          this.WhatsApp();
        }
        // else {
        //   this.showError = true;
        // }
      } catch (ex) {
        console.error(ex);
      } finally {
        this.qrLoading = false;
      }
    },
    hideSideBar() {
      this.visibility = false;
    },
    async showSidebar() {
      //  ================
      this.showError = false;
      this.statusLoading = true;
      // let val = await this.checkClient();
      let val = "";
      this.statusLoading = false;
      if (val) {
        // console.log(val);
        this.statusW = "";
        this.disconnect();
        this.$bvToast.toast(
          "Your WhatsApp connection is disconnected due to pending payment.",
          {
            title: "WhatsApp Disconnected!",
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: true,
          }
        );
      } else {
        this.statusLoading = true;
        await this.checkStatus();
        this.statusLoading = false;
        //  ================
        if (this.statusW && this.statusW.toLowerCase() == "connected") {
          this.$bvToast.toast("WhatsApp is already connected.", {
            title: "Connected!",
            variant: "success",
            toaster: "b-toaster-top-center",
            solid: true,
          });
        } else {
          this.waObj.connected = false;
          this.waObj.qrCode = "";
          this.visibility = true;

          // this.retryCount = 15;
          // this.disRetry = true;
          // this.retryID = setInterval(this.setRetry, 1000);

          //this.waIcon = this.loading;
          var resp = await this.ConnectAndSend();
          // console.log(resp);
          this.visibility = true;
        }
      }
    },
    setRetry() {
      // console.log(this.retryCount);
      if (this.retryCount == 0) {
        this.disRetry = false;
        clearInterval(this.retryID);
      } else {
        this.retryCount -= 1;
      }
    },
    async loadRetry() {
      this.disRetry = true;
      var obj2 = {
        url:
          "https://" +
          this.$store.state.WAdomain +
          "/retry?db=" +
          this.clientID +
          "&cID=" +
          this.$store.state.userData.cId +
          "&dbEnc=" +
          this.$store.state.userData.db,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      var data = await axios(obj2);
      // console.log(data);
      // var resp = await this.ConnectAndSend();
      if (data.data.state == "-") {
        this.retryCount = 15;
        this.disRetry = true;
        this.retryID = setInterval(this.setRetry, 1000);
        var resp = await this.ConnectAndSend();
      }

      // this.retryCount = 15;
    },
    async loadSetting() {
      let res2 = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettingsAll?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["domain"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      let domain = res2.find((el) => el.key == "domain");
      if (domain && domain.value) {
        this.$store.commit("setDomain", domain.value);
      } else {
        this.$store.commit("setDomain", "https://api.myskool.app/api/");
      }

      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: [
          "notifications",
          "dedicatedWhatsApp",
          "applicationId",
          "allowPause",
          "setupURL",
          // "pausedStatus",
        ],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      // console.log(res);
      this.dwObj = res.find((el) => el.key == "dedicatedWhatsApp");
      if (this.dwObj && this.dwObj.valueBool && this.dwObj.value) {
        this.$store.commit("setWAdomain", this.dwObj.value);
      } else {
        this.$store.commit("setWAdomain", "whatsapp.myskool.app");
      }

      this.appObj = res.find((el) => el.key == "applicationId");
      if (this.appObj && this.appObj.valueBool && this.appObj.value) {
        this.$store.commit("setAppID", this.appObj.value);
      } else {
        this.$store.commit("setAppID", "");
      }
      this.setupObj = res.find((el) => el.key == "setupURL");
      if (this.setupObj && this.setupObj.value) {
        this.$store.commit("setSetupUrl", this.setupObj.value);
      } else {
        this.$store.commit("setSetupUrl", "https://setup.whatsappbusiness.ae");
      }
      // console.log(this.$store.state.setupUrl, this.$store.state.appID);

      this.pauseObj = res.find((el) => el.key == "allowPause");
      // console.log(this.pauseObj);
      // console.log(this.dwObj, this.appObj);
      // console.log(this.$store.state.WAdomain, this.$store.state.appID);
      this.notiObj = res.find((el) => el.key == "notifications");
      // console.log(this.notiObj);
      if (this.notiObj && this.notiObj.valueBool) {
        this.loadNoti();
        this.notiInterval = setInterval(this.loadNoti, 15000);
      }

      this.statusLoading = true;
      await this.checkStatus();
      this.statusLoading = false;
      this.intervalId = setInterval(this.checkStatus, 15000);
      // let report = res.find((el) => el.key == "report");
      // console.log(domain);

      // if (report && report.value == "general") {
      //   this.$store.commit("setReport", "reports");
      // } else {
      //   this.$store.commit("setReport", this.$store.state.clientID);
      //   // console.log(this.$store.state.report);
      // }
    },
    setLoader(val) {
      // console.log(val);
      this.statusLoading = val;
      if (val) {
        if (this.intervalId) clearInterval(this.intervalId);
        setTimeout(() => {
          this.checkAfterLogout();
        }, 15000);
      }
    },
    async checkAfterLogout() {
      // console.log("after");
      try {
        var obj2 = {
          url:
            "https://" +
            this.$store.state.WAdomain +
            "/status?db=" +
            this.clientID +
            "&cID=" +
            this.$store.state.userData.cId +
            "&dbEnc=" +
            this.$store.state.userData.db,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };
        var data = await axios(obj2);
        // console.log(data);
        this.statusW = data.data.state;
        this.statusLoading = false;
        this.intervalId = setInterval(this.checkStatus, 15000);
      } catch (error) {
        this.statusW = "";
        setTimeout(() => {
          this.checkAfterLogout();
        }, 15000);
      }
    },
    async loadNoti() {
      var obj = {
        url:
          this.$store.state.domain +
          "Notifications/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
        dashboard: true,
      };
      this.notificationData = await this.get(obj);
      // console.log(this.notificationData);
    },
    async LoadData() {
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      // console.log("item", this.items);
      this.$store.commit("setBranches", this.items);
      const branch = this.items.find(
        (el) => el.id == this.$store.state.userData.cId
      );
      this.$store.commit("setBranchName", branch.branch);
      // console.log(
      //   "Current Branch: ",
      //   JSON.stringify(this.$store.state.currentBranch)
      // );
      // if (JSON.stringify(this.$store.state.currentBranch) === "{}")
      this.$store.commit("setCurrentBranch", branch);
      // console.log("b", this.$store.state.currentBranch);

      // this.$store.commit("setDB", branch.db);
      // console.log("db", this.$store.state.userData.db);
    },
    async LoadDataRefresh() {
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      // console.log("item", this.items);
      this.$store.commit("setBranches", this.items);
      const branch = this.items.find(
        (el) => el.id == this.$store.state.userData.cId
      );
      this.$store.commit("setBranchName", branch.branch);
      this.$store.commit("setCurrentBranch", branch);
      // console.log("b", this.$store.state.currentBranch);
    },
    ChangeBranch(data) {
      // console.log(id, this.$store.state.userData.cId);
      this.$store.commit("campusId", data.id);
      this.$store.commit("setBranchName", data.branch);
      //this.$store.commit("setCurrentBranch", data);

      this.$router.go();
    },
    async ChangeDB(data) {
      // console.log(id, this.$store.state.userData.cId);
      this.$store.commit("campusId", data.id);
      this.$store.commit("setBranchName", data.branch);

      this.$store.commit("setDB", data.organizationType);

      this.changingDb = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Campuses/GetClientID?db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      let res = await this.getString(obj);
      this.$store.commit("setClientID", res);
      this.$store.commit("setReport", res);
      this.$router.go();
    },
  },
};
</script>
<style lang="css">
.mygradient {
  background-image: linear-gradient(47deg, #8074e5, #a79fed);
  /* color: white;
  border: none; */
}
</style>
<style scoped>
@media only screen and (max-width: 768px) {
  .navScroll {
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
  .navScroll::-webkit-scrollbar {
    display: none;
  }
  .adjustAlign {
    flex-wrap: unset;
  }
}
.scrolling-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.scrolling-wrapper .btn {
  flex: 0 0 auto;
}

.status-icons {
  position: absolute;
  bottom: -4px;
  right: -10px;
}

.adjust-dropdown ::v-deep .dropdown-menu {
  max-height: 350px;
  overflow-y: auto;
}

.search-type:hover {
  background-color: rgba(128, 116, 229, 0.12);
  color: var(--primary);
  transform: scaleY(1.03);
}
.scrollContent {
  height: 50vh;
  overflow-y: auto;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
