<template>
  <div class="p-1">
    <div v-if="showError" class="w-100 text-center pt-4">
      <h2 class="mb-1">Page Not Found 🕵🏻‍♀️</h2>
      <p class="mb-2">
        Oops! 😖 The requested URL was not found on this server.
      </p>
      <b-img fluid :src="imgUrl" alt="Error page" />
    </div>
    <template v-else>
      <b-row v-if="schLoading" align-h="center" class="mb-1">
        <b-col class="text-center" lg="7" md="9">
          <b-spinner
            class="m-5"
            style="width: 4rem; height: 4rem"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </b-col>
      </b-row>
      <template v-else>
        <b-row align-h="center">
          <b-col class="text-center" md="7">
            <b-img
              v-if="school.logo == null || school.logo == ''"
              ref="previewEl"
              src="https://cdn.cloudious.net/file-1678110140675-811622963.png"
              fluid
              height="100px"
              width="100px"
            />
            <b-img
              v-else
              ref="previewEl"
              :src="school.logo"
              fluid
              style="max-width: 180px; max-height: 150px"
            />
            <div class="mt-1 details-adjust">
              <h3>{{ school.name }}</h3>
              <h5>
                <feather-icon icon="MapPinIcon" class="mr-50" />
                {{ school.address }}
              </h5>
              <h5>
                <feather-icon icon="PhoneIcon" class="mr-50" />
                {{ school.phone || school.mobile }}
              </h5>
            </div>
          </b-col>
        </b-row>

        <b-row align-h="center" v-if="!grEntered">
          <b-col md="6">
            <b-card>
              <div v-if="grLoading" class="text-center">
                <b-spinner
                  class="m-1"
                  style="width: 2.5rem; height: 2.5rem"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
              <b-row v-else>
                <b-col md="12">
                  <h5 class="mb-1">
                    Please enter your Enrollment No. to proceed.
                  </h5>
                </b-col>
                <b-col xl="9" sm="8">
                  <b-form-group
                    invalid-feedback="Enrollment No. is required."
                    class="mb-0"
                    ref="gr_input"
                  >
                    <b-form-input
                      ref="gr_field"
                      v-model.trim="grNo"
                      placeholder="Enter Enrollment no"
                      @keyup.enter="LoadStudent()"
                    />
                  </b-form-group>
                </b-col>
                <b-col xl="3" sm="4">
                  <b-button
                    variant="primary"
                    block
                    class="btn-icon"
                    @click="LoadStudent()"
                  >
                    Confirm
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <template v-else-if="myObj">
          <b-row align-h="center" class="mt-1">
            <b-col lg="7" md="9">
              <b-card class="card">
                <h3 class="">Student Information</h3>
                <hr />
                <b-row align-h="center">
                  <b-col class="ml-3" cols="4">
                    <b-form-group
                      class="text-center"
                      invalid-feedback="Picture is required."
                      ref="picture"
                    >
                      <b-media no-body>
                        <div v-if="logoloading">
                          <b-spinner
                            variant="primary"
                            type="grow"
                            class="m-5"
                            label="Spinning"
                          ></b-spinner>
                        </div>

                        <b-media-aside v-else>
                          <b-link v-if="myObj.picture">
                            <div class="text-right" style="margin-right: 35px">
                              <b-button
                                @click="deleteLogo()"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                class="position-absolute btn-icon"
                                variant="danger"
                              >
                                <feather-icon icon="Trash2Icon" />
                              </b-button>
                            </div>

                            <b-img
                              class="rcorners2"
                              :src="myObj.picture"
                              height="120px"
                              width="120px"
                              style="object-fit: cover"
                            />

                            <br />
                          </b-link>

                          <b-link v-else>
                            <b-img
                              ref="previewEl"
                              src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                              @click="$refs.fileProfile.click()"
                              style="border-radius: 100px"
                              fluid
                              height="120px"
                              width="120px"
                            />
                          </b-link>

                          <input
                            type="file"
                            id="fileProfile"
                            hidden
                            ref="fileProfile"
                            @change="selectlogo()"
                            accept="image/*"
                          />
                        </b-media-aside>
                      </b-media>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />

                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Name *"
                      invalid-feedback="Name is required."
                      description="As per Matric Certificate"
                      label-class="font-weight-bold"
                    >
                      <b-form-input
                        placeholder="Enter name."
                        ref="name"
                        v-model.trim="myObj.name"
                        @focusout="CheckName()"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                      label="Father name *"
                      invalid-feedback="Father name is required."
                      label-class="font-weight-bold"
                    >
                      <b-form-input
                        ref="fname"
                        placeholder="Enter father name."
                        v-model.trim="myObj.father_name"
                        @focusout="CheckFName()"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Gender *"
                      invalid-feedback="Gender is required."
                      ref="gender"
                      label-class="font-weight-bold"
                    >
                      <v-select
                        v-model="myObj.gender"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="genders"
                        :clearable="false"
                        placeholder="Select gender"
                        @input="CheckGender()"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Date of Birth *"
                      invalid-feedback="Date of birth is required."
                      ref="dob"
                      label-class="font-weight-bold"
                    >
                      <flat-pickr
                        :config="config"
                        v-model="myObj.date_of_birth"
                        class="form-control"
                        placeholder="Select date of birth."
                        @on-change="formatDate()"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="false">
                  <b-col md="6">
                    <b-form-group
                      label="Reg number"
                      invalid-feedback="Reg number"
                    >
                      <b-form-input
                        v-model="myObj.grNo"
                        class="form-control"
                        placeholder="Enter reg number"
                        @focusout="CheckGrNo()"
                        disabled
                        ref="grNo"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Family code"
                      invalid-feedback="Family code is required."
                    >
                      <b-form-input
                        type="number"
                        v-model="myObj.family_code"
                        class="form-control"
                        placeholder="Enter family code"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="CNIC No. *"
                      invalid-feedback="CNIC No. is required."
                      ref="cnic"
                      label-class="font-weight-bold"
                    >
                      <b-form-input
                        placeholder="Enter CNIC No."
                        v-model.trim="myObj.bform_number"
                        @focusout="CheckCnic()"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Contact *"
                      invalid-feedback="Please enter correct phone number."
                      label-class="font-weight-bold"
                    >
                      <vue-tel-input
                        :inputOptions="vueTelOptions"
                        @input="CheckContact"
                        v-model="myObj.contact_father"
                      ></vue-tel-input>
                      <small class="mr-1 text-danger" v-if="phoneerror"
                        >Phone number is required.</small
                      >
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Place of birth"
                      invalid-feedback="Place of birth is required."
                    >
                      <b-form-input
                        placeholder="Enter place of birth"
                        v-model.trim="myObj.place_of_birth"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Nationality"
                      invalid-feedback="Nationality is required."
                    >
                      <b-form-input
                        placeholder="Enter nationality"
                        v-model.trim="myObj.nationality"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Marital Status">
                      <v-select
                        v-model="otherObj.maritalStatus"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="['Married', 'Single']"
                        :clearable="false"
                        placeholder="Select marital status"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Class Applied for *"
                      invalid-feedback="Class is required."
                      ref="class_admitted"
                      label-class="font-weight-bold"
                    >
                      <v-select
                        v-model="myObj.class_admitted"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="classesOptions"
                        :clearable="false"
                        :reduce="(val) => val.id"
                        label="name"
                        :disabled="dataloading"
                        placeholder="Select class"
                        @input="CheckClassAdmit()"
                      />
                      <!-- @input="FillSections()" -->
                    </b-form-group>
                  </b-col>
                  <!--  
                  <b-col md="12">
                    <b-form-group
                      label="Date of birth in words."
                      invalid-feedback="Contact number is required."
                    >
                      <b-form-input
                        placeholder="Enter date of birth in words."
                        v-model="myObj.date_of_birth_words"
                      />
                    </b-form-group>
                  </b-col> -->
                  <b-col md="12">
                    <b-form-group
                      label="Postal Address"
                      invalid-feedback="Postal address is required."
                    >
                      <b-form-input
                        placeholder="Enter postal address"
                        v-model.trim="myObj.address_current"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Affiliated College"
                      invalid-feedback="Affiliated College is required."
                      ref="college"
                    >
                      <b-form-input
                        placeholder="Enter affiliated college"
                        v-model.trim="otherObj.affiliatedCollege"
                      />
                      <!-- @focusout="CheckCollege()" -->
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Extra-Curricular Activities"
                      invalid-feedback="Extra-Curricular Activities is required."
                    >
                      <b-form-input
                        placeholder="Enter extra-curricular activities"
                        v-model="otherObj.extra_curricular"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <!--  <b-row>
                  
                   <b-col md="6">
                    <b-form-group
                      label="Section"
                      invalid-feedback="Section is required."
                      ref="section_admitted"
                    >
                      <v-select
                        v-model="myObj.section_admitted"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="admittedSections"
                        :reduce="(val) => val.id"
                        label="section"
                        :clearable="false"
                        :disabled="dataloading"
                        placeholder="Select section admitted."
                        @input="CheckAdmitSec()"
                      />
                    </b-form-group>
                  </b-col> -->

                <!-- <b-col md="6">
                    <b-form-group
                      label="Date of Provisional Admission"
                      invalid-feedback="Date is required."
                    >
                      <flat-pickr
                        :config="config"
                        v-model="myObj.date_of_registraion"
                        class="form-control"
                        placeholder="Select date of registration."
                      />
                    </b-form-group>
                  </b-col> 
                </b-row> -->

                <!-- <b-row class="mt-1">
                  <b-col md="12">
                    <div class="file-uploader">
                      <h5>
                        <b-spinner
                          variant="dark"
                          label="Spinning"
                          v-if="uploading"
                        ></b-spinner>
                        <span v-else> Upload Documents </span>
                      </h5>
                      <input
                        ref="studentdocs"
                        type="file"
                        class="file_upload"
                        @change="uploadDocument()"
                        multiple="true"
                      />
                    </div>
                  </b-col>
                </b-row>
  
                <b-row>
                  <b-col md="12">
                    <template v-for="(file, index) in documents">
                      <div class="file">
                        <div style="display: flex; align-items: center">
                          <div class="file-type">
                            {{ file.split(".").pop().toUpperCase() }}
                          </div>
                          <p class="file-name">{{ file }}</p>
                        </div>
                        <div class="file-delete">
                          <i
                            class="fa fa-trash"
                            aria-hidden="true"
                            @click="deleteDocument(index)"
                          ></i>
                        </div>
                      </div>
                    </template>
                  </b-col>
                </b-row> -->
              </b-card>

              <b-card class="card">
                <h3 class="">Academic History</h3>
                <hr />
                <template v-for="(history, index) in historyData">
                  <h4>{{ history.degree }}</h4>
                  <b-row>
                    <template v-if="history.degree != 'LAT'">
                      <b-col md="6">
                        <b-form-group label="University">
                          <b-form-input
                            v-model.trim="history.university"
                            placeholder="Enter university name"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Board">
                          <b-form-input
                            v-model.trim="history.board"
                            placeholder="Enter board name"
                          />
                        </b-form-group>
                      </b-col>
                    </template>
                    <b-col md="6">
                      <b-form-group label="Passing Year">
                        <b-form-input
                          v-model.trim="history.passingYear"
                          placeholder="Enter passing year"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Seat Number">
                        <b-form-input
                          v-model.trim="history.seatNo"
                          placeholder="Enter seat number"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6" v-if="history.degree != 'LAT'">
                      <b-form-group label="Annual / Supply">
                        <v-select
                          v-model="history.examStatus"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="['Annual', 'Supply']"
                          :clearable="false"
                          placeholder="Select annual / supply"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Division / Grade">
                        <b-form-input
                          v-model.trim="history.division"
                          placeholder="Enter division"
                        />
                      </b-form-group>
                    </b-col>
                    <template v-if="history.degree != 'LAT'">
                      <b-col md="6">
                        <b-form-group label="Discipline">
                          <b-form-input
                            v-model.trim="history.discipline"
                            placeholder="Enter discipline"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="College Affiliated to">
                          <b-form-input
                            v-model.trim="history.affiliatedCollege"
                            placeholder="Enter college affiliated to"
                          />
                        </b-form-group>
                      </b-col>
                    </template>
                  </b-row>
                  <hr v-if="index !== historyData.length - 1" />
                </template>
              </b-card>

              <b-card class="card" v-if="false">
                <h3 class="">Status of Cleared Semesters</h3>
                <hr />
                <b-row>
                  <b-col md="6" v-for="(sem, ind) in semData" :key="ind">
                    <b-form-group :label="`${sem.semester}`">
                      <v-select
                        v-model="sem.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="['Cleared', 'Not Cleared']"
                        :clearable="false"
                        placeholder="Select status"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>

              <h3 class="">Documents</h3>
              <hr />
              <b-row
                v-for="(dd, ind) in docData"
                :key="ind"
                class="mb-1 align-items-center"
              >
                <b-col :md="dd.url ? '11' : '12'">
                  <b-card
                    body-class="p-75"
                    class="rounded cursor-pointer mb-0"
                    @click="
                      dd.url
                        ? openFile(dd.url)
                        : !docLoading && $refs['doc' + ind][0].click()
                    "
                  >
                    <div
                      class="d-flex align-items-start justify-content-between"
                    >
                      <div>
                        <feather-icon
                          class="mr-50"
                          :icon="dd.url ? 'CheckCircleIcon' : 'AlertCircleIcon'"
                          :class="dd.url ? 'text-success' : 'text-warning'"
                          size="20"
                        />
                        <span class="font-weight-bold">{{
                          dd.meta_title
                        }}</span>
                      </div>

                      <b-spinner
                        v-if="docLoading && currentIndex == ind"
                        small
                        variant="primary"
                      />
                      <feather-icon
                        v-else
                        :icon="dd.url ? 'DownloadIcon' : 'UploadIcon'"
                        size="20"
                      />
                    </div>
                  </b-card>
                  <input
                    type="file"
                    hidden
                    :ref="'doc' + ind"
                    @change="selectDoc(dd, ind)"
                    accept="image/*,.pdf"
                  />
                </b-col>
                <b-col v-if="dd.url" md="1" class="pl-md-0 mt-50 mt-md-0">
                  <feather-icon
                    icon="TrashIcon"
                    class="text-danger cursor-pointer"
                    size="20"
                    @click="dd.url = ''"
                  />
                </b-col>
              </b-row>

              <b-row class="mb-1">
                <b-col
                  md="12"
                  class="d-flex flex-wrap align-items-center justify-content-between"
                >
                  <h4 class="mb-0">Additional Docs</h4>
                  <b-button
                    @click="$refs.studentdocs.click()"
                    variant="primary"
                    class="btn-icon"
                    :disabled="uploading"
                  >
                    <input
                      ref="studentdocs"
                      type="file"
                      class="file_upload"
                      @change="uploadDocument()"
                      multiple="true"
                      accept="image/*,.pdf"
                      hidden
                    />
                    Upload More
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                v-for="(file, index) in documents"
                :key="index"
                class="mb-1 align-items-center"
              >
                <b-col md="11">
                  <b-card
                    body-class="p-75"
                    class="rounded cursor-pointer mb-0"
                    @click="openFile(file)"
                  >
                    <div
                      class="d-flex align-items-start justify-content-between"
                    >
                      <div>
                        <feather-icon
                          icon="CheckCircleIcon"
                          class="text-success mr-50"
                          size="20"
                        />
                        <span class="font-weight-bold">
                          {{ file.slice(0, 30) }} ...</span
                        >
                      </div>
                      <feather-icon icon="DownloadIcon" size="20" />
                    </div>
                  </b-card>
                </b-col>
                <b-col md="1" class="pl-md-0 mt-50 mt-md-0">
                  <feather-icon
                    icon="TrashIcon"
                    class="text-danger cursor-pointer"
                    size="20"
                    @click="deleteDocument(index)"
                  />
                </b-col>
              </b-row>

              <b-card class="card" v-if="false">
                <h3 class="">Father Information</h3>
                <hr />
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Occupation"
                      invalid-feedback="School name is required."
                    >
                      <b-form-input
                        v-model="myObj.father_occupation"
                        placeholder="Enter Occupation"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="CNIC no "
                      invalid-feedback="CNIC is required."
                    >
                      <b-form-input
                        ref="fCnic"
                        v-model="myObj.father_cnic"
                        placeholder="Enter CNIC number"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Father's Contact"
                      invalid-feedback="Please enter correct contact number."
                    >
                      <vue-tel-input
                        v-model="myObj.contact_father"
                        :inputOptions="vueTelOptions"
                      ></vue-tel-input>
                      <small class="mr-1 text-danger" v-if="fphoneerror"
                        >Phone number is required.</small
                      >
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Email"
                      invalid-feedback="Email is required."
                    >
                      <b-form-input
                        ref="fEmail"
                        v-model="myObj.father_email"
                        placeholder="Enter Email"
                      />
                    </b-form-group>
                    <!-- @focusout="CheckEmail()" -->
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Education"
                      invalid-feedback="School name is required."
                    >
                      <b-form-input
                        v-model="myObj.father_education"
                        placeholder="Enter Education"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Employer"
                      invalid-feedback="Contact number is required."
                    >
                      <b-form-input
                        v-model="myObj.father_employer"
                        placeholder="Enter Employer"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Monthly income">
                      <b-form-input
                        type="number"
                        v-model="myObj.monthly_income"
                        placeholder="Enter monthly income"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Office Address"
                      invalid-feedback="Contact number is required."
                    >
                      <b-form-input
                        placeholder="Enter office address"
                        v-model="myObj.office_address"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="card" v-if="false">
                <h3 class="">Mother Information</h3>
                <hr />
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Name"
                      invalid-feedback="School name is required."
                    >
                      <b-form-input
                        v-model="myObj.mother_name"
                        placeholder="Enter name"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                      label="Occupation"
                      invalid-feedback="School name is required."
                    >
                      <b-form-input
                        v-model="myObj.mother_occupation"
                        placeholder="Enter occupation"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="CNIC no"
                      invalid-feedback="CNIC is required."
                    >
                      <b-form-input
                        ref="mCnic"
                        v-model="myObj.mother_cnic"
                        placeholder="Enter CNIC number"
                      />
                      <!-- @focusout="CheckMCnic()" -->
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Mother's Contact"
                      invalid-feedback="Please enter correct phone number."
                    >
                      <vue-tel-input
                        :inputOptions="vueTelOptions"
                        v-model="myObj.contact_mother"
                      ></vue-tel-input>
                      <small class="mr-1 text-danger" v-if="mphoneerror"
                        >Phone number is required.</small
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Email"
                      invalid-feedback="School name is required."
                    >
                      <b-form-input
                        v-model="myObj.mother_email"
                        placeholder="Enter email address"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Education"
                      invalid-feedback="School name is required."
                    >
                      <b-form-input
                        v-model="myObj.mother_education"
                        placeholder="Enter education."
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Employer"
                      invalid-feedback="School name is required."
                    >
                      <b-form-input
                        v-model="myObj.mother_employer"
                        placeholder="Enter employer"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Office Address"
                      invalid-feedback="School name is required."
                    >
                      <b-form-input
                        v-model="myObj.mother_office_address"
                        placeholder="Enter office address"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <!-- <b-col md="6">
                <b-card class="p-1 card">
                  <h3 class="mt-1">Guardian Information</h3>
                  <hr />
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label="Name"
                        invalid-feedback="School name is required."
                      >
                        <b-form-input
                          placeholder="Enter guardian name."
                          v-model="myObj.guardian_name"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Relation"
                        invalid-feedback="School name is required."
                      >
                        <b-form-input
                          placeholder="Enter guardian relation."
                          v-model="myObj.guardian_relationship"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
        
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label="Occupation"
                        invalid-feedback="School name is required."
                      >
                        <b-form-input
                          placeholder="Enter guardian occupation."
                          v-model="myObj.guardian_occupation"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="CNIC no"
                        invalid-feedback="Contact number is required."
                      >
                        <b-form-input
                          ref="gCnic"
                          placeholder="Enter CNIC number."
                          v-model="myObj.guardian_cnic"
                        />
                      </b-form-group>
                    </b-col>
                  
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label="Email"
                        invalid-feedback="Contact number is required."
                      >
                        <b-form-input
                          ref="gEmail"
                          placeholder="Enter email addresss."
                          v-model="myObj.guardian_email"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Employer"
                        invalid-feedback="Contact number is required."
                      >
                        <b-form-input
                          placeholder="Enter employer."
                          v-model="myObj.guardian_employer"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label="Office Contact"
                        invalid-feedback="Contact number is required."
                      >
                        <b-form-input
                          placeholder="Enter office number"
                          v-model="myObj.guardian_office_phone"
                        />
                      </b-form-group>
                    </b-col>
        
                    <b-col md="6">
                      <b-form-group
                        label="Education"
                        invalid-feedback="Contact number is required."
                      >
                        <b-form-input
                          placeholder="Enter Education"
                          v-model="myObj.guardian_education"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label="Office Address"
                        invalid-feedback="Contact number is required."
                      >
                        <b-form-input
                          placeholder="Enter office contact number"
                          v-model="myObj.guardian_office_address"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col> -->
          </b-row>
          <b-row align-h="center" class="mt-3">
            <b-col md="7">
              <b-button
                @click="shortcutSubmit()"
                variant="success"
                :disabled="request || uploading || docLoading"
                block
              >
                <b-spinner
                  v-if="request"
                  type="grow"
                  small
                  label="Loading..."
                />
                <span v-else>Submit</span>
              </b-button>
            </b-col>
          </b-row>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BMediaAside,
  BImg,
  BMedia,
  BLink,
  BSpinner,
  BBadge,
  BFormFile,
  BFormTextarea,
  BProgress,
  BProgressBar,
  BFormCheckbox,
} from "bootstrap-vue";
import { resolve } from "path";
import { reject } from "q";
export default {
  props: ["selected", "id"],
  components: {
    // DropZone,
    BFormCheckbox,
    BProgress,
    BProgressBar,
    BFormTextarea,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
    BCard,
    BButton,
    BMediaAside,
    BImg,
    BMedia,
    BLink,
    BSpinner,
    BBadge,
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      grNo: "",
      grEntered: false,
      grLoading: false,
      request: false,
      file: [],
      docList: [],
      docData: [],
      docLoading: false,
      currentIndex: 0,
      plus: "",
      logoloading: false,
      dataloading: false,
      admittedSections: [],
      currentSections: [],
      classes: [],
      genders: ["Male", "Female"],
      religions: ["Islam", "Christianity", "Hinduism", "Buddhists"],
      documents: [],
      uploading: false,
      school: {},
      myObj: null,
      fphoneerror: false,
      mphoneerror: false,
      phoneerror: false,
      vueTelOptions: { placeholder: "Enter contact number" },
      emerOpt: { placeholder: "Enter emergency contact" },
      classesOptions: [],
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        // disableMobile: true,
      },
      config2: {
        enableTime: true,
        defaultHour: 9,
        altFormat: "j M, Y h:i",
        altInput: true,
        dateFormat: "Y-m-d\\TH:i:S",
      },
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkZW1vQG15c2tvb2wuYXBwIiwianRpIjoiZmRjZjdhYzQtNmY1OS00Nzg0LTkyNDItZjFjNjRhOTdjODQ0IiwiZXhwIjoxOTkzNzk2MDgwLCJpc3MiOiJteXNrb29sLmFwcCIsImF1ZCI6Im15c2tvb2wuYXBwIn0._pMXprq3AJApizQQlcrVXS3-dPyGFMi0nT9OfLQKBlo",
      db: "",
      cID: 0,
      showError: false,
      schLoading: false,
      majorOptions: [
        "Science",
        "Arts",
        "Commerce",
        "Pre-Medical",
        "Pre-Engineering",
        "Computer Science",
        "General Science",
        "Humanities",
      ],
      historyData: [
        {
          id: 0,
          localID: 0,
          role: "student",
          degree: "Matriculation",
          board: "",
          university: "",
          passingYear: "",
          seatNo: "",
          examStatus: "",
          division: "",
          discipline: "",
          affiliatedCollege: "",
          campusID: 0,
        },
        {
          id: 0,
          localID: 0,
          role: "student",
          degree: "Intermediate",
          board: "",
          university: "",
          passingYear: "",
          seatNo: "",
          examStatus: "",
          division: "",
          discipline: "",
          affiliatedCollege: "",
          campusID: 0,
        },
        {
          id: 0,
          localID: 0,
          role: "student",
          degree: "LAT",
          board: "",
          university: "",
          passingYear: "",
          seatNo: "",
          examStatus: "",
          division: "",
          discipline: "",
          affiliatedCollege: "",
          campusID: 0,
        },
      ],
      otherObj: {
        id: 0,
        sID: 0,
        maritalStatus: "",
        spouse: "",
        extra_curricular: "",
        semester_status: "",
        affiliatedCollege: "",
        isEnrolled: false,
        enrollmentDate: null,
        approvalDate: null,
        approvedBy: 0,
        status: "pending",
        note: "",
        attachments: "",
        campusID: 0,
      },
      semData: [],
    };
  },
  created() {
    // console.log(this.$route.query);
    if (!this.$route.query.db || !this.$route.query.cID) {
      this.showError = true;
    } else {
      this.db = this.$route.query.db;
      let x = parseInt(this.$route.query.cID);
      if (isNaN(x) || x <= 0) {
        this.showError = true;
      } else {
        this.cID = x;
        this.LoadSchool();
        // this.myObj.campusID = this.cID;
      }
    }
    // console.log(this.db, this.cID);
  },

  computed: {
    ...mapGetters({ campusID: "campusID" }),

    imgUrl() {
      let img = require("@/assets/images/pages/error.svg");
      if (this.$store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        img = require("@/assets/images/pages/error-dark.svg");
        return img;
      }
      return img;
    },
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    openFile(url) {
      window.open(url, "_blank");
    },
    numberToWords(number) {
      const words = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ];
      const tens = [
        "",
        "",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ];

      if (number < 20) return words[number];
      if (number < 100)
        return (
          tens[Math.floor(number / 10)] +
          (number % 10 === 0 ? "" : " " + words[number % 10])
        );
      if (number < 1000)
        return (
          this.numberToWords(Math.floor(number / 100)) +
          " Hundred" +
          (number % 100 === 0 ? "" : " and " + this.numberToWords(number % 100))
        );

      // For this specific use case, we assume the year is always in the 2000s
      return (
        "Two Thousand" +
        (number % 1000 !== 0 ? " " + this.numberToWords(number % 1000) : "")
      );
    },
    formatDate() {
      if (this.myObj.date_of_birth) {
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const dayNames = [
          "",
          "First",
          "Second",
          "Third",
          "Fourth",
          "Fifth",
          "Sixth",
          "Seventh",
          "Eighth",
          "Ninth",
          "Tenth",
          "Eleventh",
          "Twelfth",
          "Thirteenth",
          "Fourteenth",
          "Fifteenth",
          "Sixteenth",
          "Seventeenth",
          "Eighteenth",
          "Nineteenth",
          "Twentieth",
          "Twenty First",
          "Twenty Second",
          "Twenty Third",
          "Twenty Fourth",
          "Twenty Fifth",
          "Twenty Sixth",
          "Twenty Seventh",
          "Twenty Eighth",
          "Twenty Ninth",
          "Thirtieth",
          "Thirty First",
        ];

        const [yearStr, monthStr, dayStr] = this.myObj.date_of_birth.split("-");
        const day = parseInt(dayStr, 10);
        const year = parseInt(yearStr, 10);
        const monthIndex = parseInt(monthStr, 10) - 1;

        this.myObj.date_of_birth_words = `${dayNames[day]} ${
          months[monthIndex]
        } ${this.numberToWords(year)}`;
      } else this.myObj.date_of_birth_words = null;
    },
    uploadDocument(e) {
      // console.log(e.target.files);
      // this.documents = Array.from(e.target.files);
      // console.log(this.documents);
      let docs = this.$refs.studentdocs.files;
      if (docs.length != 0) {
        this.uploading = true;
        let formData = new FormData();
        docs.forEach((el) => {
          formData.append("file", el);
        });
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp;
            resp.forEach((el) => {
              this.documents.push(el.path);
            });
            this.myObj.docs = this.documents.join(",");
            this.uploading = false;
            // console.log(this.myObj.docs);
          })
          .finally(() => (this.uploading = false));
      }
    },
    deleteDocument(index) {
      // console.log(index);
      this.documents.splice(index, 1);
      this.myObj.docs = this.documents.join(",");
      // console.log(this.myObj.docs);
    },
    selectDoc(item, ind) {
      let obj = this.$refs["doc" + ind][0].files;
      // console.log(obj);

      if (obj.length > 0) {
        this.currentIndex = ind;
        this.docLoading = true;
        let formData = new FormData();
        formData.append("file", obj[0]);

        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let res = e.data.myresp[0].path;
            item.url = res;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => (this.docLoading = false));
      }
    },

    selectlogo() {
      // console.log("Osama");
      var axios = require("axios");

      this.fileProfile = this.$refs.fileProfile.files[0];
      //console.log(this.fileProfile);

      if (this.fileProfile !== "") {
        this.logoloading = true;

        let formData = new FormData();
        formData.append("file", this.$refs.fileProfile.files[0]);
        //console.log(this.fileProfile);
        axios
          .post("https://upload.myskool.app/profile-picture", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            var fn1 = e.data.file.path;
            this.plus = fn1;
            this.myObj.picture = this.plus;
            this.logoloading = false;

            //  console.log(this.loading);
          })
          .catch((err) => {
            this.logoloading = false;
            console.log(err);
          });
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.picture = null;
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    FillSections(text) {
      // console.log(this.myObj.class_admitted);
      this.classes.forEach((elem) => {
        if (elem.cls.id === this.myObj.class_admitted) {
          if (elem.sections.length != 0) {
            this.admittedSections = elem.sections;
          } else {
            // console.log("null");
            this.admittedSections = [];
          }
        }
      });
      if (text !== "filled") this.myObj.section_admitted = 0;
      // console.log("Thisis", this.admittedSections);
    },

    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "" || this.myObj.name < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckFName() {
      var elem = this.$refs["fname"];
      if (this.myObj.father_name == "" || this.myObj.father_name < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGender() {
      var elem = this.$refs["gender"];
      if (!this.myObj.gender) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDob() {
      var elem = this.$refs["dob"];
      if (this.myObj.date_of_birth == "" || this.myObj.date_of_birth == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCnic() {
      var elem = this.$refs["cnic"];
      if (!this.myObj.bform_number) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckContact(s, e) {
      if (this.myObj.contact_father != "") {
        if (e.valid) {
          this.phoneerror = false;
          this.myObj.contact_father = e.number;
        } else {
          this.phoneerror = true;
        }
      } else {
        this.phoneerror = false;
      }
    },
    CheckCollege() {
      var elem = this.$refs["college"];
      if (this.otherObj.affiliatedCollege == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckClassAdmit() {
      var elem = this.$refs["class_admitted"];
      if (this.myObj.class_admitted == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAdmitSec() {
      var elem = this.$refs["section_admitted"];
      if (this.myObj.section_admitted == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckClassCurrent() {
      var elem = this.$refs["class_current"];
      if (this.myObj.class_current == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCurrentSec() {
      var elem = this.$refs["section_current"];
      if (this.myObj.section_current == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    CheckMCnic() {
      var elem = this.$refs["mCnic"];
      if (this.myObj.mother_cnic == null || this.myObj.mother_cnic == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmail() {
      var elem = this.$refs["fEmail"];
      if (this.myObj.father_email == null || this.myObj.father_email == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGCnic() {
      var elem = this.$refs["gCnic"];
      if (this.myObj.guardian_cnic == null || this.myObj.guardian_cnic == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGEmail() {
      var elem = this.$refs["gEmail"];
      if (
        this.myObj.guardian_email == null ||
        this.myObj.guardian_email == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGrNo() {
      var elem = this.$refs["grNo"];
      if (this.myObj.grNo == null || this.myObj.grNo == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkHistory() {
      let state = true;
      this.historyData.forEach((el) => {
        el.campusID = this.cID;
        if (
          (el.degree != "LAT" &&
            (el.board == "" ||
              el.university == "" ||
              el.examStatus == "" ||
              el.discipline == "")) ||
          el.passingYear == "" ||
          el.seatNo == "" ||
          el.division == ""
        ) {
          state = false;
        }
      });
      return state;
    },
    async shortcutSubmit() {
      this.CheckName();
      this.CheckFName();
      this.CheckGender();
      this.CheckDob();
      this.CheckCnic();
      if (!this.myObj.contact_father) this.phoneerror = true;
      // this.CheckCollege();
      this.CheckClassAdmit();

      if (
        this.CheckName() == false ||
        this.CheckFName() == false ||
        this.CheckGender() == false ||
        this.CheckDob() == false ||
        this.CheckCnic() == false ||
        this.phoneerror ||
        // this.CheckCollege() == false ||
        this.CheckClassAdmit() == false
      ) {
        this.$bvToast.toast("Please fill the form correctly", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else if (this.checkHistory() == false) {
        this.$bvToast.toast("Please fill the Academic history correctly", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else {
        this.request = true;
        // this.myObj.campusID = this.cID;
        // this.myObj.status = "registered";
        // this.myObj.date_of_registraion = new Date().toJSON();
        // this.myObj.grNo = this.myObj.grNo.toString();
        if (this.myObj.family_code) {
          this.myObj.family_code = parseInt(this.myObj.family_code);
        } else this.myObj.family_code = 0;

        // this.myObj.class_current = this.myObj.class_admitted;
        // this.myObj.section_current = 0;
        // this.myObj.section_admitted = 0;

        if (this.myObj.date_of_birth == "") this.myObj.date_of_birth = null;

        // this.otherObj.semester_status = this.semData
        //   .map((sem) => `${sem.semester} - ${sem.status}`)
        //   .join(",");
        this.otherObj.status = "pending";
        this.otherObj.isEnrolled = true;
        this.otherObj.enrollmentDate = new Date().toJSON();
        this.otherObj.campusID = this.cID;

        let obj = {
          student: this.myObj,
          history: this.historyData,
          docs: this.docData,
          otherInfo: this.otherObj,
        };
        // console.log(obj);

        var status = await this.post({
          url:
            this.$store.state.domain +
            "students/updateEnrollment?db=" +
            this.db +
            "&cID=" +
            this.cID,
          body: obj,
          message: "Enrollment successful.",
          context: this,
          token: this.token,
        });
        this.request = false;
        if (status) {
          this.myObj = null;

          setTimeout(() => {
            this.phoneerror = false;
          }, 500);
          this.cleanStates();
          Swal.fire({
            title: "Thank you for submitting the form!",
            text: "We appreciate your interest and will get back to you as soon as possible.",
            icon: "success",
          }).then(() => {
            this.$nextTick(() => {
              let elem = this.$refs.gr_field;
              if (elem) elem.focus();
            });
          });
        } else {
          this.$bvToast.toast("Something went wrong!", {
            title: "Error!",
            variant: "danger",
            solid: true,
          });
          this.request = false;
        }
      }
    },
    cleanStates() {
      var elem = this.$refs["name"];
      elem.state = undefined;
      var elem = this.$refs["fname"];
      elem.state = undefined;
      var elem = this.$refs["dob"];
      elem.state = undefined;
      var elem = this.$refs["gender"];
      elem.state = undefined;
      var elem = this.$refs["cnic"];
      elem.state = undefined;
      var elem = this.$refs["class_admitted"];
      elem.state = undefined;
      // var elem = this.$refs["college"];
      // elem.state = undefined;
      this.phoneerror = false;

      this.documents = [];
      this.historyData = [];
      this.docData = [];
      this.otherObj = {
        id: 0,
        sID: 0,
        maritalStatus: "",
        spouse: "",
        extra_curricular: "",
        semester_status: "",
        affiliatedCollege: "",
        isEnrolled: false,
        enrollmentDate: null,
        approvalDate: null,
        approvedBy: 0,
        status: "pending",
        note: "",
        attachments: "",
        campusID: this.cID,
      };
      this.grEntered = false;
      this.grLoading = false;
      this.grNo = "";

      this.$nextTick(() => {
        let elem = this.$refs.gr_field;
        if (elem) elem.focus();
      });
    },

    checkGRInput() {
      var elem = this.$refs["gr_input"];
      if (this.grNo == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async LoadStudent() {
      if (this.checkGRInput() == true) {
        this.grLoading = true;

        var obj = {
          url:
            this.$store.state.domain +
            "Students/LoadEnrollment?db=" +
            this.db +
            "&cID=" +
            this.cID +
            "&gr=" +
            this.grNo,
          token: this.token,
        };
        let res = await this.get(obj);
        // console.log("res", res);

        if (Array.isArray(res) || !res) {
          this.grLoading = false;
          this.grEntered = false;
          this.myObj = null;
          this.$bvToast.toast("Please enter correct Enrollment No.", {
            title: "No record found.",
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: true,
          });
          this.$nextTick(() => {
            let elem = this.$refs.gr_field;
            if (elem) elem.focus();
          });
        } else {
          if (res.otherInfo && res.otherInfo.isEnrolled) {
            this.grLoading = false;
            this.grEntered = false;
            this.$bvToast.toast("You are already enrolled", {
              title: "Already enrolled.",
              variant: "danger",
              toaster: "b-toaster-top-center",
              solid: true,
            });
            this.$nextTick(() => {
              let elem = this.$refs.gr_field;
              if (elem) elem.focus();
            });
          } else {
            this.LoadData();
            this.myObj = res.student;
            this.docData = res.docs;

            this.grLoading = false;
            this.grEntered = true;

            if (res.history.length > 0) this.historyData = res.history;
            else {
              this.historyData = [
                {
                  id: 0,
                  localID: this.myObj.id,
                  role: "student",
                  degree: "Matriculation",
                  board: "",
                  university: "",
                  passingYear: "",
                  seatNo: "",
                  examStatus: "",
                  division: "",
                  discipline: "",
                  affiliatedCollege: "",
                  campusID: this.cID,
                },
                {
                  id: 0,
                  localID: this.myObj.id,
                  role: "student",
                  degree: "Intermediate",
                  board: "",
                  university: "",
                  passingYear: "",
                  seatNo: "",
                  examStatus: "",
                  division: "",
                  discipline: "",
                  affiliatedCollege: "",
                  campusID: this.cID,
                },
                {
                  id: 0,
                  localID: this.myObj.id,
                  role: "student",
                  degree: "LAT",
                  board: "",
                  university: "",
                  passingYear: "",
                  seatNo: "",
                  examStatus: "",
                  division: "",
                  discipline: "",
                  affiliatedCollege: "",
                  campusID: this.cID,
                },
              ];
            }

            if (res.otherInfo) this.otherObj = res.otherInfo;
            else {
              this.otherObj = {
                id: 0,
                sID: this.myObj.id,
                maritalStatus: "",
                spouse: "",
                extra_curricular: "",
                semester_status: "",
                affiliatedCollege: "",
                isEnrolled: false,
                enrollmentDate: null,
                approvalDate: null,
                approvedBy: 0,
                status: "pending",
                note: "",
                attachments: "",
                campusID: this.cID,
              };
            }

            // if (this.otherObj.semester_status) {
            //   this.semData = this.otherObj.semester_status
            //     .split(",")
            //     .map((el) => ({
            //       id: el.split(" - ")[0],
            //       semester: el.split(" - ")[0],
            //       status: el.split(" - ")[1],
            //     }));
            // } else {
            //   this.semData = new Array(10).fill().map((el, ind) => ({
            //     id: ind + 1,
            //     semester: ind + 1,
            //     status: "",
            //   }));
            // }
          }
        }
      }
    },

    async LoadInit() {
      var obj = {
        url:
          this.$store.state.domain +
          "Students/AddInit?campusId=" +
          this.cID +
          "&db=" +
          this.db,
        token: this.token,
      };
      let initData = await this.get(obj);
      // console.log("data:", initData);
      this.myObj.grNo = initData.newG;
      this.myObj.family_code = initData.fCode;
    },
    async LoadData() {
      this.dataloading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.db +
          "&cId=" +
          this.cID +
          "&dID=0",
        token: this.token,
      };
      this.classes = await this.get(obj);
      this.classesOptions = this.classes.map((el) => el.cls);
      // this.FillSections("filled");
      this.dataloading = false;
    },
    async LoadSchool() {
      this.schLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Campuses/GetSelected?id=" +
          this.cID +
          "&db=" +
          this.db,
        token: this.token,
      };
      let res = await this.get(obj);
      this.schLoading = false;
      if (res == "NotFound" || Array.isArray(res)) this.showError = true;
      else {
        this.school = res;
        this.$nextTick(() => {
          let elem = this.$refs.gr_field;
          if (elem) elem.focus();
        });
      }
      // console.log("sch:", this.school);
    },
    async LoadDocs() {
      var obj = {
        url:
          this.$store.state.domain +
          "DropDownList/LoadData?db=" +
          this.db +
          "&cID=" +
          this.cID +
          "&category=docs",
        token: this.token,
      };
      this.docList = await this.get(obj);
      // console.log(this.docList);
      this.docData = this.docList.map((el) => {
        return {
          id: 0,
          docID: el.id,
          url: null,
          localID: 0,
          typee: "student",
          meta_title: el.title,
          campusID: this.cID,
        };
      });
      // console.log(this.docData);
    },
  },
};
</script>
<style>
.heading {
  padding: 0;
  margin: 2.171303074670571vw 0;
  /* text-shadow: -4px 0 5px white, 0 4px 5px white, 4px 0 5px white,
      0 -4px 5px white; */
}
.input-form {
  margin: 0 70px;
  background: #fff;
}
#fileInput .custom-file-label {
  color: #707070;
  border: 1px dashed #33312f;
  text-align: center;
  padding: 2.5rem 1.5rem;
  width: 100%;
}
#fileInput input {
  display: none;
}
#imgInput input {
  display: none;
}
#imgInput {
  /* height: 8rem; */
  width: 50%;
}
#imgInput div {
  height: 8vw;
}
#imgInput .custom-file-label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.type {
  background-color: red;
  border-radius: 10px;
  font-size: 0.8rem;
  padding: 10px;
  color: white;
}
.filename {
  padding-left: 25px;
}
.delete {
  margin: 0 0 0 auto;
  color: red;
}
#checkbox-1 {
  margin-right: 10px !important;
}
.submit {
  background-color: #00c192;
  color: #fff;
  width: 16.5vw;
  height: 4vw;
  font-weight: bold;
  border-color: #00c192;
}
.form-file-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 20px;
}
/* #prop-det, #prop-det div {
    height: 100%;
  } */

.item-group {
  width: 50%;
  /* margin-top: 2.269399707174231vw; */
}
.inputfields,
.inputfields:focus {
  border: 1px solid #33312f;
  border-radius: 0.5856515373352855vw;
  height: 2.562225475841874vw;
  outline: none !important;
  resize: none;
}
textarea.form-control {
  height: 8vw !important;
}
@media only screen and (max-width: 990px) {
  .mob-setting {
    padding: 0 1rem !important;
    width: 100vw !important;
    margin: 0 auto !important;
  }
  .mobile-view {
    margin: 0 !important;
  }
  /* .col-form-label,
  .form-file-text,
  .custom-control-label {
    font-size: 3vw;
  } */
  .item-group {
    margin-top: 0;
  }
  .margin-top-mobile {
    margin-top: 5vw;
  }
  /* .col-form-label {
    display: none;
  } */
  .form-input-mobile-text {
    width: 92vw;
    margin: 10px auto;
    border-radius: 2.8301610541727673vw;
    height: 12.781844802342606vw;
    font-size: 5.171303074670571vw;
    background-color: #f2f2f2 !important;
    border: 1px solid #33312f;
  }
  textarea.form-control {
    height: 12.781844802342606vw !important;
  }
  .form-input-mobile-text:focus {
    background-color: #00c192 !important;
    color: #fff !important;
  }
  .form-input-mobile-text::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(190, 190, 190);
    opacity: 1; /* Firefox */
    font-family: DINNextRoundedLTW01-Regular;
  }

  .form-input-mobile-text:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgb(190, 190, 190);
  }

  .form-input-mobile-text::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgb(190, 190, 190);
  }

  .form-input-mobile-text:focus::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
  }
  .form-file-input-mobile {
    border-radius: 2.8301610541727673vw;
    height: 12.781844802342606vw;
    background-color: #f2f2f2 !important;
    color: #fff !important;
  }
  .custom-file-label {
    border-radius: 2.8301610541727673vw !important;
    height: 12.781844802342606vw;
    background-color: #f2f2f2 !important;
    color: #000 !important;
    height: 40vw;
    border: 1px solid #33312f;
  }
  .submit {
    width: 50vw;
    height: 10vw;
  }
  .filename {
    font-size: 3vw;
  }
  .shadowHead {
    font-size: 8vw !important;
  }
  #imgInput div {
    height: 29vw !important;
  }
  .item-group {
    height: 30vw !important;
  }
}

@media only screen and (min-width: 1366px) {
  .submit {
    width: 15.5vw;
    height: 7.8vh;
  }
}
@media only screen and (min-width: 1440px) {
  .submit {
    width: 14.7vw;
    height: 6.7vh;
  }
}
@media only screen and (min-width: 1536px) {
  .submit {
    width: 13.8vw;
    height: 6.9vh;
  }
}
@media only screen and (min-width: 1920px) {
  .submit {
    width: 11vw;
    height: 5.5vh;
  }
}
#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
</style>
<style scoped>
.form-text.text-muted {
  color: var(--dark) !important;
}
.rcorners2 {
  border-radius: 100px;
  border: 4px solid var(--primary);
  padding: 2px;
}
.sticky {
  position: fixed;
  /* top: 0; */
  right: 2rem;
  /* margin-bottom: 30px; */
  /* position: fixed;
      bottom: 5%;
      z-index: 1031;  */
}
.file-uploader {
  position: relative;
  border: 1px solid #000;
  width: 100%;
  line-height: 30px;
  text-align: center;
  border-radius: 10px;
  font-family: "Helvetica", sans-serif;
  height: 70px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.file_upload {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.file {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.file-type {
  background-color: #e62e2d;
  font-family: "Helvetica", sans-serif;
  color: white;
  border-radius: 10px;
  margin-right: 15px;
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.file-name {
  font-family: "Helvetica", sans-serif;
  margin: 0px;
}

.file-delete i {
  color: #e62e2d;
  font-size: 2rem;
  cursor: pointer;
}
.modal-header {
  display: none;
}
.details-adjust h5 {
  word-wrap: break-word;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
