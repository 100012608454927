<template>
  <div>
    <div>
      <div>
        <b-sidebar
          id="sidebar-cat"
          bg-variant="white"
          v-model="visibility8"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">Left Class - {{ leftName }}</h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="visibility8 = false"
            />
          </div>

          <b-form class="p-2" @submit.prevent>
            <b-row>
              <b-col cols="12" class="mb-1">
                <b-form-group invalid-feedback="Category is required.">
                  <div class="">
                    <label class="bv-no-focus-ring col-form-label pt-0"
                      >Category</label
                    >
                    <feather-icon
                      class="cursor-pointer ml-50"
                      icon="EditIcon"
                      size="18"
                      v-b-tooltip.hover.top
                      title="Manage Categories"
                      @click="openCat()"
                    />
                  </div>
                  <v-select
                    v-model="leftSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="[{ title: 'None' }, ...leftOptions]"
                    :reduce="(opt) => opt.title"
                    label="title"
                    :clearable="false"
                    placeholder="Select category"
                  />
                </b-form-group>
              </b-col>
              <b-col class="mt-1" md="12">
                <b-button
                  type="submit"
                  variant="success"
                  @click="leftNew()"
                  block
                  :disabled="lefting"
                >
                  <b-spinner v-if="lefting" small type="grow" />
                  <span v-else> Left Class</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-sidebar>

        <b-sidebar
          id="sidebar-cat"
          bg-variant="white"
          v-model="visibility9"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">Manage Categories</h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="visibility9 = false"
            />
          </div>

          <b-form class="p-2" @submit.prevent>
            <b-row class="d-flex align-items-center">
              <b-col md="12">
                <b-form-group
                  label="Category Name"
                  invalid-feedback="Category name is required."
                >
                  <b-form-input
                    ref="cat_name"
                    v-model.trim="catObj.title"
                    placeholder="Enter category name"
                    @focusout="checkCat()"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-button
                  variant="success"
                  class="btn-icon mt-1"
                  @click="saveCat()"
                  :disabled="savingCat"
                  block
                >
                  <b-spinner v-if="savingCat" small type="grow" />
                  <span v-else>Save</span>
                </b-button>
              </b-col>
            </b-row>
            <b-table
              class="mt-2"
              show-empty
              :items="leftOptions"
              :fields="catFields"
              responsive
              hover
            >
              <template #head(actions)="data">
                <div class="text-right">
                  <span>{{ data.label }}</span>
                </div>
              </template>

              <template #cell(actions)="data">
                <div class="text-right">
                  <b-button
                    variant="primary"
                    class="btn-icon mr-50"
                    @click="editCat(data.item)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <!-- <b-button
                  variant="outline-danger"
                  class="btn-icon"
                  @click="deleteCat(data.item.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button> -->
                </div>
              </template>
            </b-table>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <!-- SideBar For Department -->
        <b-sidebar
          id="sidebar-Department"
          bg-variant="white"
          v-model="visibility1"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">
              {{ sidebarTitle }}
            </h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hideDepSideBar()"
            />
          </div>
          <b-form class="p-2" @submit.prevent>
            <b-row class="mt-1">
              <b-col md="12">
                <b-form-group
                  label="Department name *"
                  invalid-feedback="Department name is required."
                >
                  <b-form-input
                    id="mc-first-name"
                    placeholder="Enter department name"
                    ref="depname"
                    v-model.trim="depObj.name"
                    @focusout="CheckDepName()"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12" v-if="settObj">
                <b-form-group
                  label="WhatsApp From *"
                  invalid-feedback="WhatsApp from is required."
                >
                  <v-select
                    ref="wcampus"
                    v-model="depObj.whatsAppCampusID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="$store.state.branches"
                    :clearable="false"
                    label="branch"
                    :reduce="(val) => val.id"
                    placeholder="Select"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="success"
                  class="mr-1 fullWidth"
                  @click="DepAdd()"
                  :disabled="request"
                  block
                >
                  <b-spinner v-if="request" small type="grow" />
                  <span v-if="request == false"> {{ sidebarButton }} </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <!-- SideBar For Classes -->
        <b-sidebar
          id="sidebar-right"
          ref="mySidebar"
          bg-variant="white"
          v-model="visibility"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">
              {{ sidebarTitle }}
            </h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hideSideBar()"
            />
          </div>
          <b-form class="p-2" @submit.prevent>
            <b-row class="mt-1">
              <b-col md="12">
                <b-form-group
                  label="Department *"
                  invalid-feedback="Department is required."
                  ref="department"
                >
                  <v-select
                    v-model="myObj.cls.departmentID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="this.myDepartments"
                    :clearable="false"
                    ref="status"
                    label="name"
                    :reduce="(val) => val.id"
                    :disabled="dataloading"
                    placeholder="Select Department"
                    @input="CheckDepartment()"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Class name *"
                  invalid-feedback="Class is required."
                >
                  <b-form-input
                    id="mc-first-name"
                    placeholder="Enter class name"
                    v-validate="'required'"
                    name="classField"
                    ref="name"
                    v-model="myObj.cls.name"
                    @input="CheckName()"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12" v-if="selecting">
                <b-form-group
                  label="Select Sections *"
                  invalid-feedback="Sections is required."
                  ref="sections"
                >
                  <!-- <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    v-model="myObj.sections"
                    taggable
                    push-tags
                    placeholder="Add Sections"
                    label="title"
                  /> -->
                  <div
                    style="
                      border: 1px solid lightgray;
                      padding: 5px;
                      border-radius: 5px;
                    "
                  >
                    <b-badge
                      class="sectionBadge"
                      v-for="sec in sections"
                      :key="sec"
                      :id="'sectionBadge' + sec.id"
                      @click="selectSection(sec.id)"
                    >
                      {{ sec.section }}
                    </b-badge>
                    <!-- <b-badge
                    class="sectionBadge"
                    style=""
                    v-for="sec in sections"
                    :key="sec"
                  >
                    {{ sec.section }}
                  </b-badge> -->
                    <b-button
                      @click="addSection()"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                  </div>
                  <!-- <div></div> -->
                </b-form-group>
              </b-col>
              <b-col md="12" v-if="!selecting">
                <b-form-group label="Add new section">
                  <div style="display: flex">
                    <b-form-input
                      placeholder="Enter section name"
                      v-validate="'required'"
                      name="classField"
                      v-model="sectionObj.section"
                    />
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon mr-0 ml-sm-1"
                      @click="HideSection()"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      class="btn-icon mr-0 ml-sm-1"
                      @click="postSection()"
                    >
                      <feather-icon icon="CheckIcon" />
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Arrival time"
                  invalid-feedback="Arrival time is required."
                  ref="arrival"
                >
                  <flat-pickr
                    v-model="myObj.cls.startsAt"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      noCalendar: true,
                      altFormat: 'h:i',
                      altInput: true,
                      dateFormat: 'Y-m-d\\TH:i:S',
                      defaultHour: 13,
                    }"
                    placeholder="Enter time"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12" v-if="selecting">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="success"
                  class="mr-1 fullWidth"
                  @click="Add()"
                  :disabled="request"
                  block
                >
                  <b-spinner v-if="request" small type="grow" />
                  <span v-if="request == false"> {{ sidebarButton }} </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <b-sidebar
          id="sidebar-Department"
          bg-variant="white"
          v-model="visibility3"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">Shift Classes</h5>

            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="visibility3 = false"
            />
          </div>
          <b-form class="p-2" @submit.prevent>
            <b-row class="mt-1">
              <b-table
                :items="shiftData"
                :fields="shiftFields"
                show-empty
                responsive
                hover
              >
                <template #cell(current_class)="data">
                  <!-- style="outline" -->
                  {{ getClassName(data.item.current_class) }}
                  <!-- <v-select
                    v-model="data.item.current_class"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="allClasses"
                    :reduce="(val) => val.id"
                    label="name"
                    :clearable="false"
                    placeholder=""
                    disabled
                  /> -->
                </template>
                <template #cell(move_to)="data">
                  <v-select
                    v-model="data.item.move_to"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="allClasses"
                    :reduce="(val) => val.id"
                    label="name"
                    :clearable="false"
                    placeholder=""
                  />
                </template>
              </b-table>

              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  @click="Shift()"
                  :disabled="shifting"
                  block
                >
                  <b-spinner v-if="shifting" small type="grow" />
                  <span v-else> Shift </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <b-sidebar
          id="sidebar-Department"
          bg-variant="white"
          v-model="visibility4"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">Shift Class</h5>

            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="visibility4 = false"
            />
          </div>
          <b-form class="p-2" @submit.prevent>
            <b-row class="mt-1">
              <b-col md="12">
                <b-form-group
                  label="From Class"
                  invalid-feedback="Class is required."
                  ref="from_cls"
                >
                  <v-select
                    v-model="singleObj.clsFrom"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="allClasses"
                    :reduce="(val) => val.id"
                    label="name"
                    :clearable="false"
                    placeholder="select class"
                    @input="fillCurrent()"
                  />
                  <!-- :options="
                      allClasses.filter((el) => el.id != singleObj.clsTo)
                    " -->
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="From Section"
                  invalid-feedback="Section is required."
                  ref="from_sec"
                >
                  <v-select
                    v-model="singleObj.secFrom"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="currentSections"
                    :reduce="(val) => val.id"
                    label="section"
                    :clearable="false"
                    placeholder="select section"
                    @input="checkSame()"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Move To"
                  invalid-feedback="Class is required."
                  ref="move_cls"
                >
                  <v-select
                    v-model="singleObj.clsTo"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="allClasses"
                    :reduce="(val) => val.id"
                    label="name"
                    :clearable="false"
                    placeholder="select class"
                    @input="fillMove()"
                  />
                  <!-- :options="
                      allClasses.filter((el) => el.id != singleObj.clsFrom)
                    " -->
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Move Section"
                  invalid-feedback="Section is required."
                  ref="move_sec"
                >
                  <v-select
                    v-model="singleObj.secTo"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="moveSections"
                    :reduce="(val) => val.id"
                    label="section"
                    :clearable="false"
                    placeholder="select section"
                    @input="checkSame()"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12" class="mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  @click="ShiftSingle()"
                  :disabled="shifting"
                  block
                >
                  <b-spinner v-if="shifting" small type="grow" />
                  <span v-else> Shift </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <b-sidebar
          id="sidebar-timing"
          bg-variant="white"
          v-model="visibility5"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
          width="500px"
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">
              Department Timings
              <template v-if="stdObj && stdObj.valueBool">
                <b-spinner v-if="syncing" small class="ml-1" />
                <feather-icon
                  v-else
                  class="cursor-pointer ml-1"
                  icon="RefreshCcwIcon"
                  size="16"
                  v-b-tooltip.hover.bottomright
                  title="Sync student timings"
                  @click="!savingTime && syncTimings()"
                />
              </template>
              <b-form-checkbox
                class="mr-0 ml-1"
                v-model="sameDays"
                switch
                inline
                v-b-tooltip.hover.bottomright
                title="Everyday schedule same as monday"
                @input="setDays(timingInfo[0])"
              />
            </h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="visibility5 = false"
            />
          </div>
          <b-form class="px-1 py-2" @submit.prevent>
            <b-row align-h="center" v-if="timeLoading">
              <b-spinner
                class="my-4"
                style="width: 2.5rem; height: 2.5rem"
                variant="primary"
                type="grow"
                label="Spinning"
              ></b-spinner>
            </b-row>
            <template v-else>
              <b-row v-for="(item, index) in timingInfo" :key="index">
                <b-col md="4" class="pr-md-0">
                  <b-form-group invalid-feedback="Day is required." ref="day">
                    <div class="">
                      <label class="bv-no-focus-ring col-form-label pt-0"
                        >Day
                        <b-form-checkbox
                          v-model="item.isActive"
                          switch
                          inline
                          v-b-tooltip.hover.bottomright
                          title="Active"
                          class="mr-0 ml-1"
                          size="lg"
                        />
                      </label>
                    </div>

                    <v-select
                      v-model="item.day"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="daysList"
                      :reduce="(op) => op.value"
                      :selectable="(opt) => !currDays.includes(opt.value)"
                      :clearable="false"
                      label="text"
                      @input="changeDay()"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3" class="pr-md-0">
                  <b-form-group
                    label="Joins at"
                    invalid-feedback="Joins at is required."
                    ref="join"
                  >
                    <flat-pickr
                      v-model="item.timeIn"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        altFormat: 'h:i K',
                        altInput: true,
                        dateFormat: 'Y-m-d\\TH:i:S',
                        defaultHour: 8,
                      }"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3" class="pr-md-0">
                  <b-form-group
                    label="Leaves at"
                    invalid-feedback="Leaves at is required."
                    ref="leave"
                  >
                    <flat-pickr
                      v-model="item.timeOut"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        altFormat: 'h:i K',
                        altInput: true,
                        dateFormat: 'Y-m-d\\TH:i:S',
                        defaultHour: 13,
                      }"
                      @on-close="setDays(item)"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="2" class="pr-md-0 align-self-center">
                  <feather-icon
                    v-if="index == timingInfo.length - 1"
                    icon="PlusIcon"
                    size="16"
                    class="cursor-pointer ml-50"
                    @click="addDay()"
                  />
                  <feather-icon
                    v-if="timingInfo.length > 1"
                    icon="TrashIcon"
                    size="16"
                    class="cursor-pointer ml-50"
                    @click="removeDay(item, index)"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col md="12">
                  <b-button
                    variant="success"
                    @click="saveTimings()"
                    :disabled="savingTime || syncing"
                    block
                  >
                    <b-spinner v-if="savingTime" small type="grow" />
                    <span v-else> Save </span>
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <b-card>
          <b-row align-h="end" class="text-right">
            <b-col class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1" cols="11">
              <b-dropdown
                style="margin-inline: 2px; margin-bottom: 2px"
                v-for="(d, ind) in this.myDepartments"
                :key="ind"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                split
                :text="d.name"
                :variant="current == d.id ? 'primary' : 'outline-primary'"
                right
                @click="LoadClasses(d.id)"
              >
                <b-dropdown-item @click="EditDep(d)" v-if="rights.edit">
                  Edit
                </b-dropdown-item>

                <b-dropdown-item
                  @click="openTimings(d)"
                  v-if="dtObj && dtObj.valueBool && (rights.add || rights.edit)"
                >
                  Timings
                </b-dropdown-item>

                <b-dropdown-item @click="DeleteDep(d.id)" v-if="rights.delete">
                  Delete
                </b-dropdown-item>

                <b-dropdown-divider />
              </b-dropdown>
            </b-col>
            <b-col
              v-if="rights.add"
              class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1"
              style="margin-right: 8px"
              cols="*"
            >
              <b-button
                @click="OpenDepartmentF()"
                style=""
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon rounded-circle"
              >
                <feather-icon size="16" icon="PlusIcon" />
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-1 mb-50">
            <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
              <b-button
                @click="AddOpen()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">Add Class</span>
              </b-button>
            </b-col>

            <b-col
              class="mt-lg-0 mt-md-0 mt-sm-1 mt-1 d-flex align-items-center justify-content-between"
              xl="10"
              lg="9"
              md="8"
              sm="12"
              cols="12"
            >
              <b-form-group class="mb-0 pr-50" style="width: 100%">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>

                  <b-form-input v-model="searchQuery" placeholder="Search...">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <!-- <b-button
                v-if="rights.edit"
                variant="outline-primary"
                v-b-tooltip.hover.top
                title="Shift Classes"
                class="btn-icon mr-50"
                :disabled="loadingSh"
                @click="openShift()"
              >
                <b-spinner v-if="loadingSh" small />
                <feather-icon v-else icon="CheckCircleIcon" class="" />
              </b-button> -->
              <b-button
                v-if="rights.edit"
                variant="outline-primary"
                v-b-tooltip.hover.top
                title="Shift Class"
                class="btn-icon"
                :disabled="loadingSh"
                @click="openSingle()"
              >
                <feather-icon icon="ShuffleIcon" class="" />
              </b-button>
            </b-col>
          </b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
          <b-table
            class="mt-1"
            ref="selectableTable"
            :items="items"
            :fields="fields"
            :busy="gridLoading"
            :per-page="perPage"
            :current-page="currentPage"
            show-empty
            responsive
            hover
            :selectable="rights.edit"
            select-mode="single"
            @row-selected="Edit($event[0].cls.id)"
          >
            <template #empty="scope">
              <h3 class="mt-1 mb-1" style="text-align: center">
                No records found
              </h3>
            </template>
            <template #table-busy>
              <div
                class="d-flex justify-content-center mb-3"
                style="margin-top: 50px"
              >
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  type="grow"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
            </template>
            <template #cell(name)="data">
              <b-badge style="margin-inline: 2px" variant="light-primary">
                {{ data.item.cls.name }}
              </b-badge>
            </template>

            <template #cell(sections)="data">
              <div v-if="data.item.sections != null">
                <b-badge
                  style="margin-inline: 2px"
                  v-for="i in data.item.sections"
                  :key="i"
                  variant="light-primary"
                >
                  {{ i.section }}
                </b-badge>
              </div>
            </template>

            <template #cell(actions)="data">
              <div class="">
                <b-button
                  v-if="rights.edit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-b-tooltip.hover.left
                  title="Edit"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Edit(data.item.cls.id)"
                >
                  <feather-icon icon="EditIcon" class="" />
                </b-button>

                <!-- <b-button
                  v-if="rights.delete"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  v-b-tooltip.hover.right
                  title="Delete"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Delete(data.item.cls.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button> -->

                <b-button
                  v-if="rights.edit"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.right
                  title="Left Class"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="openLeft(data.item.cls.id, data.item.cls.name)"
                >
                  <!-- @click="leftClass(data.item.cls.id, '')" -->
                  <feather-icon icon="LogOutIcon" />
                </b-button>
                <!-- <b-button
                  v-if="rights.edit"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.right
                  title="Mark Alumni"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="leftClass(data.item.cls.id, 'alumni')"
                >
                  <feather-icon icon="UserCheckIcon" />
                </b-button> -->

                <!-- <b-button
                  v-if="
                    rights.edit &&
                    (currentPage - 1) * perPage + data.index + 1 <=
                      items.length - 1
                  "
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.right
                  title="Move Down"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                >
                  <feather-icon icon="ArrowDownIcon" />
                </b-button>
                <b-button
                  v-if="
                    rights.edit &&
                    (currentPage - 1) * perPage + data.index + 1 != 1
                  "
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.right
                  title="Move Up"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                >
                  <feather-icon icon="ArrowUpIcon" />
                </b-button> -->
              </div>
            </template>
          </b-table>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    flatPickr,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });
    // console.log(this.right);
    this.$store.commit("setRights", right);
    // console.log(this.$store.state.rights);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadDepartments();
      this.LoadClasses(0);
      this.loadSettings();
      this.LoadLeftCat();
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    filters: function () {
      return this.items.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      rights: {},
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,
      visibility3: false,
      visibility4: false,
      singleObj: {
        clsFrom: 0,
        secFrom: 0,
        clsTo: 0,
        secTo: 0,
      },
      currentSections: [],
      moveSections: [],
      color: [
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
      ],

      sidebarTitle: "Add Class",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataloading: false,
      gridLoading: false,
      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: null,

      errors: {
        status: false,
      },

      index: null,
      loadingSh: false,
      shifting: false,
      shiftData: [],
      shiftFields: [
        { label: "Class", key: "current_class" },
        { label: "Move to", key: "move_to" },
      ],
      fields: [
        { label: "Class", key: "name" },
        { label: "Sections", key: "sections" },
        { key: "actions", label: "actions" },
      ],
      allClasses: [],
      items: [],
      sections: [],
      selecting: true,
      selected: null,
      sectionObj: {
        id: 0,
        section: "",
        campusID: this.$store.state.userData.cId,
      },
      allSectionIDs: [],
      rangeDate: null,
      myObj: {
        cls: {
          id: 0,
          name: "",
          sections: "",
          departmentID: 0,
          campusID: this.$store.state.userData.cId,
          startsAt: null,
        },
        sectionIDs: [],
      },
      depObj: {
        id: 0,
        name: "",
        sequence: 0,
        whatsAppCampusID: 0,
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      timeout: 10000,
      current: 0,
      settObj: null,
      dtObj: null,
      stdObj: null,
      leftCls: 0,
      leftName: "",
      lefting: false,
      visibility8: false,
      leftSelected: "None",
      leftOptions: [],
      catFields: [
        { label: "Name", key: "title" },
        { key: "actions", label: "actions" },
      ],
      visibility9: false,
      catObj: {},
      savingCat: false,
      sameDays: this.$store.state.sameTimings,
      timeObj: null,
      currDays: [],
      visibility5: false,
      timeLoading: false,
      savingTime: false,
      timingInfo: [],
      daysList: [
        { text: "Monday", value: "monday" },
        { text: "Tuesday", value: "tuesday" },
        { text: "Wednesday", value: "wednesday" },
        { text: "Thursday", value: "thursday" },
        { text: "Friday", value: "friday" },
        { text: "Saturday", value: "saturday" },
        { text: "Sunday", value: "sunday" },
      ],
      currentDepID: 0,
      currentDepName: 0,
      syncing: false,
      reportDomain: this.$store.state.report.replace(/_/g, ""),
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({
      get: "get",
      post: "post",
      put: "put",
      delete: "delete",
      postObj: "postObj",
    }),

    openTimings(item) {
      this.currentDepID = item.id;
      this.currentDepName = item.name;
      this.visibility5 = true;

      this.LoadTimings();
    },
    async LoadTimings() {
      this.timeLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "DepartmentTimings/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&id=" +
          this.currentDepID,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      this.timeLoading = false;

      if (res.length == 0) {
        this.timingInfo = [
          {
            id: 0,
            depID: this.currentDepID,
            day: "monday",
            timeIn: "",
            timeOut: "",
            isActive: true,
            campusID: this.$store.state.userData.cId,
          },
        ];
      } else this.timingInfo = res;
      this.changeDay();
    },
    changeDay() {
      this.currDays = this.timingInfo.reduce((acc, el) => {
        acc.push(el.day);
        return acc;
      }, []);
      // console.log(this.currDays);
    },
    setDays(item) {
      this.$store.commit("setSameTimings", this.sameDays);

      this.$nextTick(() => {
        // console.log(item.timeOut);
        if (
          this.timingInfo.length == 1 &&
          this.sameDays &&
          item.day == "monday" &&
          item.timeIn &&
          item.timeOut
        ) {
          // console.log(item);
          let days = ["tuesday", "wednesday", "thursday", "friday", "saturday"];
          let data = [item];
          days.forEach((el) => {
            data.push({
              id: 0,
              depID: item.depID,
              day: el,
              timeIn: item.timeIn,
              timeOut: item.timeOut,
              isActive: true,
              campusID: this.$store.state.userData.cId,
            });
          });
          this.timingInfo = data;
          this.changeDay();
        }
      });
    },
    addDay() {
      this.timingInfo.push({
        id: 0,
        depID: this.currentDepID,
        day: "",
        timeIn: "",
        timeOut: "",
        isActive: true,
        campusID: this.$store.state.userData.cId,
      });
      // console.log(this.timingInfo);
    },
    async removeDay(item, ind) {
      if (item.id == 0) {
        this.timingInfo.splice(ind, 1);
        // console.log(this.timingInfo);
        this.currDays = this.currDays.filter((el) => el != item.day);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "DepartmentTimings/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.timingInfo.splice(ind, 1);
          this.currDays = this.currDays.filter((el) => el != item.day);
        }
        // console.log(this.timingInfo);
      }
    },
    CheckTimings() {
      let state = true;
      // this.timingInfo = this.timingInfo.filter(
      //   (el) => el.day != "" && el.timeIn != "" && el.timeOut != ""
      // );
      this.timingInfo.forEach((el) => {
        if (el.day == "" || !el.timeIn || !el.timeOut) {
          state = false;
        }
      });
      // console.log(state);
      return state;
    },
    async saveTimings() {
      // console.log(this.timingInfo);
      if (this.CheckTimings() == true) {
        this.savingTime = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "DepartmentTimings/SaveData?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.timingInfo,
          message: "Timings saved successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.savingTime = false;
        if (status) {
          this.LoadTimings();
        }
      } else {
        this.$bvToast.toast("Please enter the timings correctly!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      }
    },
    async syncTimings() {
      let result = await Swal.fire({
        title: "Are you sure?",
        text:
          "This will sync the student timings for all classes of " +
          this.currentDepName,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        this.syncing = true;
        this.$bvToast.toast("Student timings are being sync", {
          title: "Syncing",
          variant: "success",
          toaster: "b-toaster-top-right",
        });

        var status = await this.postObj({
          url:
            this.$store.state.domain +
            "DepartmentTimings/SyncData?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&id=" +
            this.currentDepID,
          message: "Student timings synced successfully",
          context: this,
          ignoreTimeout: true,
          token: this.$store.state.userData.token,
        });
        this.syncing = false;
      }
    },

    openLeft(id, name) {
      this.leftCls = id;
      this.leftName = name;
      this.leftSelected = "None";
      this.visibility8 = true;
    },
    async LoadLeftCat() {
      var obj = {
        url:
          this.$store.state.domain +
          "DropDownList/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&category=leftCategories",
        token: this.$store.state.userData.token,
      };
      this.leftOptions = await this.get(obj);
    },
    openCat() {
      this.catObj = {
        id: 0,
        title: "",
        typee: "leftCategories",
        campusID: this.$store.state.userData.cId,
      };
      this.visibility9 = true;
      var elem = this.$refs["cat_name"];
      elem.state = undefined;
    },
    checkCat() {
      var elem = this.$refs["cat_name"];
      if (this.catObj.title.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveCat() {
      if (this.checkCat() == true) {
        this.savingCat = true;
        if (this.catObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "DropDownList?db=" +
              this.$store.state.userData.db,
            body: this.catObj,
            message: "Category added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "DropDownList/" +
              this.catObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Category updated successfully.",
            context: this,
            body: this.catObj,
            token: this.$store.state.userData.token,
          });
        }
        if (status) {
          this.LoadLeftCat();
          this.catObj = {
            id: 0,
            title: "",
            typee: "leftCategories",
            campusID: this.$store.state.userData.cId,
          };
          var elem = this.$refs["cat_name"];
          elem.state = undefined;
        }
        this.savingCat = false;
      }
    },
    async editCat(acc) {
      this.catObj = { ...acc };
      var elem = this.$refs["cat_name"];
      elem.state = undefined;
    },
    async deleteCat(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "DropDownList/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Category deleted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadLeftCat();
      }
    },

    async loadSettings() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["multi_whatsapp", "department-timings", "studentTimings"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      // console.log(res);
      this.settObj = res.find((el) => el.key == "multi_whatsapp");
      this.dtObj = res.find((el) => el.key == "department-timings");
      this.stdObj = res.find((el) => el.key == "studentTimings");
      // console.log(this.settObj);
    },

    getClassName(id) {
      return this.allClasses.find((el) => el.id == id).name;
    },

    openSingle() {
      this.singleObj = {
        clsFrom: 0,
        secFrom: 0,
        clsTo: 0,
        secTo: 0,
      };
      this.visibility4 = true;
      this.currentSections = [];
      this.moveSections = [];
    },
    fillCurrent() {
      this.singleObj.secFrom = 0;

      this.currentSections = this.items.find(
        (el) => el.cls.id == this.singleObj.clsFrom
      ).sections;

      this.checkSame();
    },
    fillMove() {
      this.singleObj.secTo = 0;

      this.moveSections = this.items.find(
        (el) => el.cls.id == this.singleObj.clsTo
      ).sections;

      this.checkSame();
    },
    checkSame() {
      this.currentSections = this.items.find(
        (el) => el.cls.id == this.singleObj.clsFrom
      ).sections;

      this.moveSections = this.items.find(
        (el) => el.cls.id == this.singleObj.clsTo
      ).sections;

      if (this.singleObj.clsTo == this.singleObj.clsFrom) {
        // for current
        this.currentSections = this.currentSections.filter(
          (el) => el.id != this.singleObj.secTo
        );

        // for move
        this.moveSections = this.moveSections.filter(
          (el) => el.id != this.singleObj.secFrom
        );
      }
    },

    async ShiftSingle() {
      if (
        this.singleObj.clsFrom == 0 ||
        this.singleObj.secFrom == 0 ||
        this.singleObj.clsTo == 0 ||
        this.singleObj.secTo == 0
      ) {
        this.$bvToast.toast("Please enter the details!", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        // console.log(this.singleObj);
        this.shifting = true;

        var status = await this.post({
          url:
            this.$store.state.domain +
            "MyClasses/ShiftSingle?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.singleObj,
          message: "Class shifted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.shifting = false;

        if (status) {
          this.visibility4 = false;
        }
      }
    },

    async openShift() {
      this.loadingSh = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Students/LoadShiftData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.shiftData = await this.get(obj);
      // console.log(this.shiftData);
      this.loadingSh = false;
      this.visibility3 = true;
    },
    async Shift() {
      this.shifting = true;
      var status = await this.post({
        url:
          this.$store.state.domain +
          "Students/ShiftClass?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: this.shiftData,
        message: "Classes shift successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.shifting = false;
      if (status) {
        this.visibility3 = false;
      }
    },

    async EditDep(item) {
      // var obj = {
      //   url:
      //     this.$store.state.domain +
      //     "Departments/GetSelected?id=" +
      //     item.id +
      //     "&db=" +
      //     this.$store.state.userData.db,
      //   token: this.$store.state.userData.token,
      // };
      // this.depObj = await this.get(obj);
      this.depObj = { ...item };
      // console.log("editObj:", this.depObj);
      this.sidebarTitle = "Edit Department";
      this.sidebarButton = "Update";
      this.visibility1 = true;
      var elem = this.$refs["depname"];
      elem.state = undefined;
    },

    OpenDepartmentF() {
      this.depObj = {
        id: 0,
        name: "",
        sequence: 0,
        whatsAppCampusID: 0,
        campusID: this.$store.state.userData.cId,
      };
      this.sidebarTitle = "Add Department";
      this.sidebarButton = "Save";
      this.visibility1 = true;
      var elem = this.$refs["depname"];
      elem.state = undefined;
    },

    async Edit(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "MyClasses/GetSelectedClass?db=" +
          this.$store.state.userData.db +
          "&clsID=" +
          id,
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj);
      // console.log("editObj", result);
      this.myObj.cls = result.cls;
      await this.getSections();

      this.visibility = true;
      this.allSectionIDs = [];
      result.sections.forEach((el) => {
        this.allSectionIDs.push(el.id);
        // console.log(this.allSectionIDs);
        const myElement = document.getElementById("sectionBadge" + el.id);
        myElement.style.backgroundColor = "var(--primary)";
        myElement.style.color = "white";
      });

      this.sidebarTitle = "Edit Class";
      this.sidebarButton = "Update";
      this.buttonIcon = "EditIcon";
      var elem = this.$refs["name"];
      elem.state = undefined;
      var elem = this.$refs["department"];
      elem.state = undefined;
      var elem = this.$refs["sections"];
      elem.state = undefined;
    },

    async AddOpen() {
      this.allSectionIDs = [];
      this.getSections();
      this.visibility = true;
      this.myObj.cls.id = 0;
      this.myObj.cls.departmentID = 0;
      this.myObj.cls.campusID = this.$store.state.userData.cId;
      this.myObj.cls.sections = "";
      this.myObj.cls.name = "";
      this.myObj.cls.startsAt = null;
      this.sidebarTitle = "Add Class";
      this.sidebarButton = "Save";
      var elem = this.$refs["name"];
      elem.state = undefined;
      var elem = this.$refs["department"];
      elem.state = undefined;
      var elem = this.$refs["sections"];
      elem.state = undefined;
    },
    async getSections() {
      var obj = {
        url:
          this.$store.state.domain +
          "Sections?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.sections = await this.get(obj);
      // console.log("sections:", this.sections);
      this.sections = this.sections.filter(
        (el) => el.section != null && el.section != ""
      );
      this.sections = this.sections.sort((s1, s2) => {
        // console.log(s1);
        // console.log(s2);
        if (s1 != null && s2 != null && s1 != undefined && s2 != undefined)
          s1.section.toUpperCase() > s2.section.toUpperCase()
            ? 1
            : s1.section.toUpperCase() < s2.section.toUpperCase()
            ? -1
            : 0;
      });
      // console.log(sorted);
    },
    addSection() {
      this.selecting = false;
      this.sectionObj.section = "";
      // console.log("secObj:", this.sectionObj);
    },
    async postSection() {
      // console.log("secObj", this.sectionObj);
      if (this.sectionObj.section.trim() == "") {
        return this.$bvToast.toast("Please enter the section name.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Sections?db=" +
            this.$store.state.userData.db,
          body: this.sectionObj,
          message: "Section added successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.selecting = true;
          this.getSections();
        }
      }
    },
    HideSection() {
      this.selecting = true;
      this.sectionObj.section = "";
    },
    selectSection(id) {
      // console.log(id);
      if (this.allSectionIDs.length == 0) {
        this.allSectionIDs.push(id);
        const myElement = document.getElementById("sectionBadge" + id);
        myElement.style.backgroundColor = "var(--primary)";
        myElement.style.color = "white";
      } else {
        var data = [];
        this.allSectionIDs.forEach((el) => {
          if (el != id) {
            data.push(el);
          } else {
            const myElement = document.getElementById("sectionBadge" + id);
            myElement.style.backgroundColor = "white";
            myElement.style.color = "var(--primary)";
          }
        });
        if (this.allSectionIDs.length == data.length) {
          this.allSectionIDs.push(id);
          const myElement = document.getElementById("sectionBadge" + id);
          myElement.style.backgroundColor = "var(--primary)";
          myElement.style.color = "white";
        } else {
          this.allSectionIDs = data;
        }
      }
      // console.log("data", data);
      // console.log("sec_ids", this.allSectionIDs);
      this.CheckSections();
    },
    CheckRequired(name) {
      var elem = this.$refs[name];
      if (this.myObj.name == "" || this.myObj.name > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }

      // return (elem.state = this.$v.depObj.name.required);
    },

    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.cls.name == "" || this.myObj.cls.name > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDepName() {
      var elem = this.$refs["depname"];
      if (this.depObj.name == "" || this.depObj.name == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDepartment() {
      var elem = this.$refs["department"];
      if (this.myObj.cls.departmentID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSections() {
      var elem = this.$refs["sections"];
      if (this.allSectionIDs.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    hideSideBar() {
      this.visibility = false;
      this.allSectionIDs = [];
    },
    hideDepSideBar() {
      this.visibility1 = false;
    },

    Form() {
      this.$router.push("/apps/schoolinfo");
    },

    checkTitle() {
      var elem = this.$refs["title"];
      return (elem.state = this.myObj.name.length > 2 ? true : false);
    },

    checkStatus() {
      if (this.myObj.Status == "") {
        return (this.errors.status = true);
      } else {
        return (this.errors.status = false);
      }
    },

    async LoadDepartments() {
      var obj = {
        url:
          this.$store.state.domain +
          "departments?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.myDepartments = await this.get(obj);
    },

    async LoadClasses(dID) {
      this.current = dID;
      this.gridLoading = true;

      let obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dID=" +
          dID,
        token: this.$store.state.userData.token,
      };
      if (this.reportDomain == "myskoolhpgs") {
        obj = {
          url:
            this.$store.state.domain +
            "myclasses/LoadClassesAll?db=" +
            this.$store.state.userData.db +
            "&cId=" +
            this.$store.state.userData.cId +
            "&dID=" +
            dID,
          token: this.$store.state.userData.token,
        };
      }

      this.items = await this.get(obj);
      if (dID == 0) {
        this.allClasses = [];
        this.items.forEach((el) => {
          this.allClasses.push(el.cls);
        });
      }
      // console.log("classes", this.items);
      // this.items = [];

      if (this.items.length != 0) {
        this.totalRows = this.items.length;
      }
      this.gridLoading = false;
    },

    async DepAdd() {
      this.CheckDepName();
      if (this.CheckDepName() == false) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.depObj.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "departments?db=" +
              this.$store.state.userData.db,
            body: this.depObj,
            message: "Department added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility1 = false;
          if (status) this.LoadDepartments();
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "departments/" +
              this.depObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.depObj,
            message: "Department updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.visibility1 = false;
            this.LoadDepartments();
          }
        }
      }
    },

    async DeleteDep(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "departments/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Department removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadDepartments();
      }
    },

    async Add() {
      // console.log("classObj", this.myObj);
      this.CheckName();
      this.CheckDepartment();
      this.CheckSections();
      if (
        this.CheckName() == false ||
        this.CheckDepartment() == false ||
        this.CheckSections() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        // this.myObj.sections = this.myObj.sections.toString();
        this.myObj.sectionIDs = this.allSectionIDs;
        if (this.myObj.cls.startsAt == "") this.myObj.cls.startsAt = null;

        if (this.myObj.cls.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "myclasses/Add?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Class added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadClasses(0);
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "myclasses/Edit?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Class updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadClasses(0);
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "myclasses/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Class removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadClasses(0);
      }
    },

    async leftClass(id, text) {
      let st = text ? text : "left";
      let result = await Swal.fire({
        title: "Are you sure?",
        html: `Do you want to mark whole class as <span class="font-weight-bolder text-uppercase text-danger">${st}</span>.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      });

      if (result.isConfirmed) {
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Students/LeftWholeClass?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&classID=" +
            id +
            "&status=" +
            text,
          body: null,
          message: "Class marked as " + st,
          context: this,
          token: this.$store.state.userData.token,
        });
        // if (status) this.LoadClasses();
      }
    },
    async leftNew() {
      let result = await Swal.fire({
        title: "Are you sure?",
        html: `Do you want to mark whole class as <span class="font-weight-bolder text-uppercase text-danger">Left</span>.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      });

      if (result.isConfirmed) {
        this.lefting = true;
        let st = this.leftSelected == "None" ? "" : this.leftSelected;

        var status = await this.post({
          url:
            this.$store.state.domain +
            "Students/LeftWholeClassNew?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&classID=" +
            this.leftCls +
            "&status=" +
            st,
          body: null,
          message: "Class marked as left.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.lefting = false;
        if (status) this.visibility8 = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.sectionBadge {
  margin-inline: 5px;
  margin-top: 5px;
  margin-bottom: 3px;
  padding: 10px;
  border: 2px solid var(--primary);
  color: var(--primary);
  background-color: white;
  cursor: pointer;
}
</style>
