<template>
  <div>
    <!-- Club Details Card -->
    <template v-if="clubDetails">
      <h3 class="mb-50">
        <b-button
          variant="outline-secondary"
          class="btn-icon mr-50"
          pill
          v-b-tooltip.hover.top
          title="Back to Clubs"
          @click="$emit('back')"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </b-button>
        {{ clubDetails.title }}
      </h3>
      <b-card>
        <b-media>
          <template #aside>
            <b-img
              :src="
                clubDetails.cover ||
                'https://cdn.cloudious.net/file-1678189226453-406060527.png'
              "
              width="120"
              height="120"
              rounded
            />
          </template>

          <p class="mb-25">{{ clubDetails.description }}</p>
          <h6 class="mb-25">
            <span>Classes: </span>
            <b-badge variant="light-primary">
              {{ clubDetails.classNames }}
            </b-badge>
          </h6>
          <h6 class="mb-25">
            <span>Seats: </span>
            <b-badge variant="light-primary">
              {{ clubDetails.occupied }} /
              {{ clubDetails.seats }}
            </b-badge>
          </h6>
        </b-media>
      </b-card>
    </template>

    <h3 class="mb-1">Votes</h3>
    <b-card>
      <b-row>
        <b-col md="12" class="d-flex align-items-start">
          <b-form-group class="w-100">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
          <b-button
            variant="primary"
            v-b-tooltip.hover.top
            title="Export"
            class="btn-icon ml-1"
            :disabled="exporting || loading"
            @click="exportData()"
          >
            <b-spinner v-if="exporting" small />
            <feather-icon v-else icon="DownloadIcon" />
          </b-button>
        </b-col>
      </b-row>

      <b-table
        :items="filteredStudents"
        :fields="fields"
        :busy="loading"
        responsive
        show-empty
        hover
        small
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner variant="primary" />
          </div>
        </template>

        <template #cell(index)="data">
          <b-badge variant="light-primary">
            {{ data.index + 1 }}
          </b-badge>
        </template>

        <template #cell(student)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="44"
                :src="data.item.std.picture"
                variant="light-primary"
              />
            </template>

            <b-link class="font-weight-bold d-block text-nowrap mb-25">
              <b-badge variant="light-primary">
                {{ data.item.std.name }}
              </b-badge>
            </b-link>
            <small class="text-muted">
              <b-badge variant="light-primary" class="mr-50">
                GR -
                {{
                  ["myskoolhpgs", "myskoolprofectus"].includes(reportDomain)
                    ? data.item.std.grNoLocal
                    : data.item.std.grNo
                }}
              </b-badge>
              <b-badge variant="light-primary">
                F# - {{ data.item.std.family_code }}
              </b-badge>
            </small>
          </b-media>
        </template>

        <template #cell(class)="data">
          <b-badge variant="light-primary">
            {{ data.item.std.meta_class }}
          </b-badge>
        </template>

        <template #cell(date)="data">
          <b-badge variant="light-primary" class="mr-25">
            {{
              new Date(data.item.us.date).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
          <b-badge variant="light-primary">
            {{
              new Intl.DateTimeFormat("en-US", {
                hour: "numeric",
                minute: "numeric",
              }).format(new Date(data.item.us.date))
            }}
          </b-badge>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BTable,
  BBadge,
  BSpinner,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BMedia,
  BAvatar,
  BImg,
  BRow,
  BCol,
  VBTooltip,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import * as XLSX from "xlsx";

export default {
  components: {
    BCard,
    BButton,
    BTable,
    BBadge,
    BSpinner,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BMedia,
    BAvatar,
    BImg,
    BRow,
    BCol,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    clubID: {
      type: Number,
      required: true,
    },
    clubDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      exporting: false,
      searchQuery: "",
      fields: [
        { label: "S.No", key: "index" },
        { label: "Student", key: "student" },
        { label: "Class", key: "class" },
        { label: "Voted On", key: "date" },
      ],
      reportDomain: this.$store.state.report.replace(/_/g, ""),
    };
  },
  created() {
    this.LoadData();
  },
  computed: {
    filteredStudents() {
      return this.items.filter((el) =>
        el.std.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async exportData() {
      this.exporting = true;
      await this.LoadData();

      const newData = this.items.map((item) => ({
        name: item.std.name,
        father_name: item.std.father_name,
        grNo: item.std.grNo,
        fCode: item.std.family_code,
        contact: item.std.contact_father,
        class: item.std.meta_class,
        voted_on: item.us.date
          ? `${new Date(item.us.date).toLocaleDateString("en-UK", {
              year: "numeric",
              day: "numeric",
              month: "short",
            })} ${new Intl.DateTimeFormat("en-US", {
              hour: "numeric",
              minute: "numeric",
            }).format(new Date(item.us.date))}`
          : "",
      }));

      this.exporting = false;

      const worksheet = XLSX.utils.json_to_sheet(newData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
      XLSX.writeFile(workbook, `${this.clubDetails.title || ""}-votes.xlsx`);
    },
    async LoadData() {
      this.loading = true;
      var obj2 = {
        url:
          this.$store.state.domain +
          "Clubs/LoadDetails?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&clubID=" +
          this.clubID,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj2);
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
