<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1" v-if="settObj">
          <b-col md="12">
            <b-form-group
              label="Opening Balance"
              invalid-feedback="Opening Balance is required."
              ref="bal"
            >
              <b-form-input
                placeholder="Enter balance here"
                v-model="settObj.valueNumber"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" class="mt-1">
            <b-button
              variant="success"
              @click="saveSetting()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Select Month"
              invalid-feedback="Month is required."
              ref="month"
            >
              <v-select
                v-model="dateObj.month"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="months"
                label="text"
                :reduce="(opt) => opt.value"
                :clearable="false"
                placeholder="Select month"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Select Year"
              invalid-feedback="year is required."
              ref="year"
            >
              <v-select
                v-model="dateObj.year"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="years"
                :clearable="false"
                placeholder="Select year"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required."
              ref="date"
            >
              <flat-pickr
                :config="config"
                v-model="rangeDate"
                class="form-control"
                placeholder="Select Date."
                @on-change="setDate"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" class="mt-1">
            <b-button
              variant="success"
              @click="saveData()"
              :disabled="saving"
              block
            >
              <b-spinner v-if="saving" small type="grow" />
              <span v-else>Generate</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-button @click="AddOpen()" variant="primary">
        <span class="align-middle">Generate Report</span>
      </b-button>
      <b-button
        variant="outline-primary"
        class="btn-icon ml-1"
        v-b-tooltip.hover.top
        title="Set Opening Balance"
        @click="openSetting()"
        :disabled="settLoading"
      >
        <b-spinner v-if="settLoading" small />
        <feather-icon v-else icon="SettingsIcon" />
      </b-button>

      <b-table
        class="mt-1"
        :items="items"
        :fields="fields"
        :busy="dataLoading"
        responsive
        show-empty
        small
      >
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template #cell(datestr)="data">
          <b-badge variant="light-primary" class="mb-50">
            {{ data.value }}
          </b-badge>
          <br />
          <b-badge variant="light-primary">
            {{ monthName(data.item.month) }} {{ data.item.year }}
          </b-badge>
        </template>
        <template #cell(prevBalance)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(dues)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(badDebtsCount)="data">
          <b-badge
            class="cursor-pointer"
            variant="light-primary"
            @click="openReport(data.item, 'left')"
          >
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(badDebts)="data">
          <b-badge
            class="cursor-pointer"
            variant="light-primary"
            @click="openReport(data.item, 'left')"
          >
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(discountCount)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(discount)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(exemptCount)="data">
          <b-badge
            class="cursor-pointer"
            variant="light-primary"
            @click="openReport(data.item, 'exempt')"
          >
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(exempted)="data">
          <b-badge
            class="cursor-pointer"
            variant="light-primary"
            @click="openReport(data.item, 'exempt')"
          >
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(collection)="data">
          <b-badge
            class="cursor-pointer"
            variant="light-primary"
            @click="openReport(data.item, 'collection')"
          >
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(balance)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BSidebar,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BFormSpinbutton,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BSidebar,
    BInputGroupPrepend,
    BFormTextarea,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BFormSpinbutton,
    BAvatar,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      config: {
        mode: "range",
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      dataLoading: false,
      fields: [
        { label: "date", key: "datestr" },
        { label: "last balance", key: "prevBalance" },
        { label: "monthly fee", key: "dues" },
        // { label: "total", key: "total" },
        { label: "left", key: "badDebtsCount" },
        { label: "left total", key: "badDebts" },
        { label: "discount", key: "discountCount" },
        { label: "discount total", key: "discount" },
        { label: "exemption", key: "exemptCount" },
        { label: "exemption total", key: "exempted" },
        { label: "received", key: "collection" },
        { label: "balance", key: "balance" },
      ],
      items: [],
      visibility: false,
      settLoading: false,
      settObj: null,
      request: false,
      visibility2: false,
      rangeDate: null,
      dateObj: {
        dtFrom: null,
        dtTo: null,
        month: 0,
        year: 0,
      },
      months: [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],
      years: [
        2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026,
        2027, 2028, 2029, 2030,
      ],
      saving: false,
      reportDomain: this.$store.state.report.replace(/_/g, ""),
    };
  },
  created() {
    // this.$store.commit("setReconilData", []);
    // this.items = this.$store.state.reconilData;
    this.LoadData();
  },
  methods: {
    ...mapActions({
      get: "get",
      post: "post",
      postObj: "postObj",
      put: "put",
      delete: "delete",
    }),

    monthName(val) {
      let obj = this.months.find((el) => el.value == val);
      return obj ? obj.text.slice(0, 3) : "";
    },
    openReport(item, type) {
      let dtF = item.dtFrom.split("T")[0];
      let dtT = item.dtTo.split("T")[0];

      if (type == "left") {
        let url =
          `https://${this.reportDomain}.myskool.app/List/Show?type=left&dbb=` +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&cls=&status=&mob=false&dtFrom=" +
          dtF +
          "&dtTo=" +
          dtT;
        window.open(url, "_blank");
      } else if (type == "exempt") {
        let url =
          `https://${this.reportDomain}.myskool.app/Fee/Show?type=dcr&dtFrom=` +
          dtF +
          "&dtTo=" +
          dtT +
          "&dbb=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&month=&feeType=&format=Exemption&cls=&accId=0";
        window.open(url, "_blank");
      } else if (type == "collection") {
        let url =
          `https://${this.reportDomain}.myskool.app/Fee/Show?type=dcr&dtFrom=` +
          dtF +
          "&dtTo=" +
          dtT +
          "&dbb=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&month=&feeType=&format=Slip wise&cls=&accId=0";
        window.open(url, "_blank");
      }
    },

    AddOpen() {
      if (!this.rangeDate) {
        const fd = new Date();
        const firstDate = `${fd.getFullYear()}-${(fd.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-01`;

        const ld = new Date(fd.getFullYear(), fd.getMonth() + 1, 0);
        const lastDate = `${ld.getFullYear()}-${(ld.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${ld.getDate().toString().padStart(2, "0")}`;

        this.dateObj.dtFrom = firstDate;
        this.dateObj.dtTo = lastDate;
        this.rangeDate = `${firstDate} to ${lastDate}`;

        this.dateObj.month = fd.getMonth() + 1;
        this.dateObj.year = fd.getFullYear();
      }
      this.visibility2 = true;
      var elem = this.$refs["date"];
      elem.state = undefined;
    },
    setDate(sd, dateStr) {
      // console.log(sd, dateStr);
      if (dateStr) {
        if (sd.length > 1) {
          let date = dateStr.split(" to ");
          if (date.length == 1) {
            this.dateObj.dtFrom = date[0];
            this.dateObj.dtTo = date[0];
          } else {
            this.dateObj.dtFrom = date[0];
            this.dateObj.dtTo = date[1];
          }
        } else {
          this.dateObj.dtFrom = dateStr;
          this.dateObj.dtTo = null;
        }
      } else {
        this.dateObj.dtFrom = null;
        this.dateObj.dtTo = null;
      }
      // console.log(this.dateObj.dtFrom, this.dateObj.dtTo);
    },
    async saveData() {
      var elem = this.$refs["date"];
      if (this.dateObj.dtFrom && this.dateObj.dtTo) {
        elem.state = true;
        // console.log(this.dateObj);

        this.saving = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/saveReconcilation?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.dateObj,
          // message: "Account added successfully.",
          context: this,
          token: this.$store.state.userData.token,
          subjects: true,
        });
        this.saving = false;
        // console.log(status);
        if (status) {
          // this.items.push(status);
          // this.$store.commit("setReconilData", this.items);
          this.visibility2 = false;
          this.LoadData();
        }
      } else {
        elem.state = false;
      }
    },

    async openSetting() {
      this.settLoading = true;
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["reconcil-opening"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });

      let obj = res.find((el) => el.key == "reconcil-opening");
      if (!obj) {
        this.settObj = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          key: "reconcil-opening",
          value: "reconcil-opening",
          valueBool: true,
          valueNumber: 0,
        };
      } else this.settObj = obj;

      this.settLoading = false;
      this.visibility = true;
      this.$nextTick(() => {
        var elem = this.$refs["bal"];
        elem.state = undefined;
      });
    },
    CheckVal() {
      var elem = this.$refs["bal"];
      let x = parseInt(this.settObj.valueNumber);
      if (isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveSetting() {
      if (this.CheckVal() == true) {
        this.request = true;
        this.settObj.valueNumber = parseInt(this.settObj.valueNumber);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Settings/SaveNew?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: [this.settObj],
          message: "Opening balance save successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.visibility = false;
        }
      }
    },
    async LoadData() {
      this.dataLoading = true;

      var obj = {
        url:
          this.$store.state.domain +
          "FeeReconcilation?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      // console.log("this.items);
      this.dataLoading = false;
    },
  },
};
</script>
<style scoped>
.table.table-sm th {
  text-align: center;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
