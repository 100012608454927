<template>
  <div>
    <!-- SideBar For staff departments -->
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Department *"
              invalid-feedback="Department is required."
              ref="department"
            >
              <b-form-input
                ref="name"
                placeholder="Enter department name"
                v-model="myObj.name"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              @click="Add()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-timing"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
      width="500px"
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          Department Timings
          <b-form-checkbox
            class="mr-0 ml-1"
            v-model="sameDays"
            switch
            inline
            v-b-tooltip.hover.bottomright
            title="Everyday schedule same as monday"
            @input="setDays(timingInfo[0])"
          />
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="px-1 py-2" @submit.prevent>
        <b-row v-for="(item, index) in timingInfo" :key="index">
          <b-col md="4" class="pr-md-0">
            <b-form-group
              label="Day"
              invalid-feedback="Day is required."
              ref="day"
            >
              <v-select
                v-model="item.day"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="daysList"
                :reduce="(op) => op.value"
                :selectable="(opt) => !currDays.includes(opt.value)"
                :clearable="false"
                label="text"
                @input="changeDay()"
              />
            </b-form-group>
          </b-col>
          <b-col md="3" class="pr-md-0">
            <b-form-group
              label="Joins at"
              invalid-feedback="Joins at is required."
              ref="join"
            >
              <flat-pickr
                v-model="item.timeIn"
                class="form-control"
                :config="{
                  enableTime: true,
                  noCalendar: true,
                  altFormat: 'h:i K',
                  altInput: true,
                  dateFormat: 'Y-m-d\\TH:i:S',
                  defaultHour: 8,
                }"
              />
            </b-form-group>
          </b-col>
          <b-col md="3" class="pr-md-0">
            <b-form-group
              label="Leaves at"
              invalid-feedback="Leaves at is required."
              ref="leave"
            >
              <flat-pickr
                v-model="item.timeOut"
                class="form-control"
                :config="{
                  enableTime: true,
                  noCalendar: true,
                  altFormat: 'h:i K',
                  altInput: true,
                  dateFormat: 'Y-m-d\\TH:i:S',
                  defaultHour: 13,
                }"
                @on-close="setDays(item)"
              />
            </b-form-group>
          </b-col>

          <b-col md="2" class="pr-md-0 align-self-center">
            <feather-icon
              v-if="index == timingInfo.length - 1"
              icon="PlusIcon"
              size="16"
              class="cursor-pointer ml-50"
              @click="addDay()"
            />
            <feather-icon
              v-if="timingInfo.length > 1"
              icon="TrashIcon"
              size="16"
              class="cursor-pointer ml-50"
              @click="removeDay(item, index)"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="12">
            <b-button
              variant="success"
              @click="saveTimings()"
              :disabled="savingTime"
              block
            >
              <b-spinner v-if="savingTime" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row class="mt-1">
        <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
          <b-button
            @click="AddOpen()"
            block
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Add</span>
          </b-button>
        </b-col>

        <b-col
          class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          xl="10"
          lg="9"
          md="8"
          sm="12"
          cols="12"
        >
          <b-form-group class="">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="filters.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
      <b-table
        class="mt-1"
        ref="selectableTable"
        :items="filters"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="dataLoading"
        show-empty
        responsive
        hover
        :selectable="rights.edit"
        select-mode="single"
        @row-selected="Edit($event[0].id)"
      >
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template #cell(name)="data">
          <b-badge style="margin-inline: 2px" variant="light-primary">
            {{ data.item.name }}
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="text-center">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-center">
            <b-button
              v-if="rights.edit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="Timings"
              class="btn-icon mr-1"
              @click="openTimings(data.item)"
            >
              <feather-icon icon="CalendarIcon" />
            </b-button>

            <b-button
              v-if="rights.edit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="Edit"
              class="btn-icon mr-1"
              @click="Edit(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>

            <b-button
              v-if="rights.delete"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.right
              title="Delete"
              class="btn-icon"
              @click="Delete(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BForm,
  BSpinner,
  BSidebar,
  BInputGroupPrepend,
  BInputGroup,
  BFormCheckbox,
  VBTooltip,
  VBToggle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BForm,
    BSpinner,
    BSidebar,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      rights: {},
      request: false,
      sidebarTitle: "Add Department",
      sidebarButton: "Save",
      dataLoading: false,
      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      fields: [
        { label: "Name", key: "name" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      myObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      visibility2: false,
      sameDays: this.$store.state.sameTimings,
      daysList: [
        { text: "Monday", value: "monday" },
        { text: "Tuesday", value: "tuesday" },
        { text: "Wednesday", value: "wednesday" },
        { text: "Thursday", value: "thursday" },
        { text: "Friday", value: "friday" },
        { text: "Saturday", value: "saturday" },
        { text: "Sunday", value: "sunday" },
      ],
      currDays: [],
      timingInfo: [],
      savingTime: false,
      currentDep: null,
    };
  },
  computed: {
    filters: function () {
      return this.items.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });
    // console.log(this.right);
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadData();
    }
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    openTimings(item) {
      this.currentDep = { ...item };
      this.visibility2 = true;

      if (this.timingInfo.length == 0) {
        this.timingInfo = [
          {
            id: 0,
            tID: 0,
            day: "monday",
            timeIn: "",
            timeOut: "",
            campusID: this.currentDep.campusID,
          },
        ];
      }
    },
    changeDay() {
      this.currDays = this.timingInfo.reduce((acc, el) => {
        acc.push(el.day);
        return acc;
      }, []);
      // console.log(this.currDays);
    },
    setDays(item) {
      this.$store.commit("setSameTimings", this.sameDays);

      this.$nextTick(() => {
        // console.log(item.timeOut);
        if (
          this.timingInfo.length == 1 &&
          this.sameDays &&
          item.day == "monday" &&
          item.timeIn &&
          item.timeOut
        ) {
          // console.log(item);
          let days = ["tuesday", "wednesday", "thursday", "friday", "saturday"];
          let data = [item];
          days.forEach((el) => {
            data.push({
              id: 0,
              tID: 0,
              day: el,
              timeIn: item.timeIn,
              timeOut: item.timeOut,
              campusID: item.campusID,
            });
          });
          this.timingInfo = data;
          this.changeDay();
        }
      });
    },
    addDay() {
      this.timingInfo.push({
        id: 0,
        tID: 0,
        day: "",
        timeIn: "",
        timeOut: "",
        campusID: this.currentDep.campusID,
      });
      // console.log(this.timingInfo);
    },
    removeDay(item, ind) {
      this.timingInfo.splice(ind, 1);
      this.currDays = this.currDays.filter((el) => el != item.day);
    },
    CheckTimings() {
      let state = true;
      this.timingInfo.forEach((el) => {
        if (el.day == "" || !el.timeIn || !el.timeOut) {
          state = false;
        }
      });
      // console.log(state);
      return state;
    },
    async saveTimings() {
      if (this.CheckTimings() == true) {
        let result = await Swal.fire({
          title: "Are you sure?",
          text:
            "This will save the timings for all staff of " +
            this.currentDep.name,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes",
        });
        if (result.isConfirmed) {
          this.savingTime = true;
          let obj = {
            tIDs: [],
            depID: this.currentDep.id,
            timings: this.timingInfo,
          };
          // console.log(obj);

          var status = await this.post({
            url:
              this.$store.state.domain +
              "staff/BulkTimings?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.currentDep.campusID,
            body: obj,
            message: "Timings saved successfully!",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.savingTime = false;
          if (status) {
            this.visibility2 = false;
          }
        }
      } else {
        this.$bvToast.toast("Please enter the timings correctly!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      }
    },

    async Edit(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "StaffDepartments/GetSelected?id=" +
          id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      // console.log("editObj:", this.myObj);
      this.visibility = true;
      this.sidebarTitle = "Edit Staff Department";
      this.sidebarButton = "Update";

      var elem = this.$refs["name"];
      elem.state = undefined;
    },

    AddOpen() {
      this.myObj = {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      };
      this.visibility = true;
      this.sidebarTitle = "Add Staff Department";
      this.sidebarButton = "Save";

      var elem = this.$refs["name"];
      elem.state = undefined;
    },

    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "" || this.myObj.name > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    hideSideBar() {
      this.visibility = false;
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "StaffDepartments?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);

      this.dataLoading = false;
      // console.log("staff dept", this.items);
    },

    async Add() {
      this.CheckName();
      if (this.CheckName() == false) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.myObj.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "StaffDepartments?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Department added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadData();
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "StaffDepartments/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "department updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadData();
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "StaffDepartments/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Staff Department removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
