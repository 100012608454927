<template>
  <div>
    <b-modal
      id="modal-login"
      hide-footer
      centered
      no-close-on-backdrop
      title="Salary"
    >
      <b-form-group
        label="Final Salary"
        invalid-feedback="Final Salary is required."
        ref="final_sal"
      >
        <b-form-input
          type="number"
          v-model="finalSalary"
          class="form-control"
          placeholder="Enter final salary"
        />
      </b-form-group>
      <b-row class="mt-2">
        <b-col class="d-flex justify-content-end">
          <b-button variant="success" :disabled="request" @click="saveFinal()">
            <b-spinner v-if="request" type="grow" small label="Loading..." />
            <span v-else class="align-middle">Save</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-row class="match-height" align-h="center">
      <b-col lg="7" md="9">
        <b-card class="">
          <b-row align-h="center">
            <!-- <b-col cols="4"> </b-col> -->
            <b-col class="ml-3" cols="4">
              <b-form-group
                class="text-center"
                ref="picture"
                label=" "
                label-for="name-input"
                invalid-feedback="Picture is required."
              >
                <b-media no-body ref="picture">
                  <div v-if="logoloading == true">
                    <b-spinner
                      variant="primary"
                      type="grow"
                      class="m-5"
                      label="Spinning"
                    ></b-spinner>
                  </div>

                  <b-media-aside>
                    <b-link>
                      <b-img
                        ref="previewEl"
                        src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                        @click="$refs.fileProfile.click()"
                        v-if="logoloading == false"
                        style="border-radius: 100px"
                        fluid
                        height="120px"
                        width="120px"
                      />
                    </b-link>

                    <b-link v-if="logoloading == 'loaded'">
                      <div
                        v-if="!disableFields"
                        class="text-right"
                        style="margin-right: 35px"
                      >
                        <b-button
                          @click="deleteLogo()"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="position-absolute btn-icon"
                          variant="danger"
                        >
                          <feather-icon icon="Trash2Icon" />
                        </b-button>
                      </div>

                      <b-img
                        class="rcorners2"
                        :src="plus"
                        height="120px"
                        width="120px"
                      />

                      <br />
                    </b-link>
                    <input
                      type="file"
                      id="fileProfile"
                      hidden
                      ref="fileProfile"
                      @change="selectlogo()"
                      accept="image/*"
                      :disabled="disableFields"
                    />
                  </b-media-aside>
                </b-media>
              </b-form-group>
            </b-col>
            <!-- <b-col cols="4"> </b-col> -->
          </b-row>
          <!-- <h3 class="mt-1">Student Information</h3> -->
          <hr />

          <b-row>
            <b-col md="6">
              <b-form-group
                label="Name *"
                invalid-feedback="Staff name is required."
                label-class="font-weight-bold"
              >
                <b-form-input
                  placeholder="Enter name."
                  ref="name"
                  v-model.trim="myObj.staff.name"
                  :disabled="disableFields"
                  @focusout="CheckName()"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Father name *"
                invalid-feedback="Father name is required."
                label-class="font-weight-bold"
              >
                <b-form-input
                  ref="fname"
                  placeholder="Enter father name."
                  v-model.trim="myObj.staff.father_name"
                  :disabled="disableFields"
                  @focusout="CheckFName()"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Gender *"
                invalid-feedback="Gender name is required."
                label-class="font-weight-bold"
                ref="gender"
              >
                <v-select
                  v-model="myObj.staff.gender"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="genders"
                  :clearable="false"
                  :disabled="disableFields"
                  placeholder="Select gender"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Date of Birth *"
                invalid-feedback="Date of Birth is required."
                label-class="font-weight-bold"
                ref="dob"
              >
                <flat-pickr
                  :config="config"
                  v-model="myObj.staff.date_of_birth"
                  class="form-control"
                  :disabled="disableFields"
                  placeholder="Select date of birth."
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Contact *"
                invalid-feedback="Contact number is required."
                label-class="font-weight-bold"
              >
                <vue-tel-input
                  v-model="myObj.staff.contact_number"
                  :inputOptions="vueTelOptions"
                  :dropdownOptions="dropOptions"
                  @input="CheckSContact"
                  :disabled="disableFields"
                ></vue-tel-input>
                <small class="mr-1 text-danger" v-if="sPhoneerror"
                  >Phone number is required</small
                >
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Qualification *"
                invalid-feedback="qualification is required."
                label-class="font-weight-bold"
              >
                <b-form-input
                  ref="qua"
                  placeholder="Enter qualification."
                  v-model.trim="myObj.staff.qualification"
                  :disabled="disableFields"
                />
                <!-- @focusout="CheckQualification()" -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Designation *"
                invalid-feedback="designation is required."
                label-class="font-weight-bold"
              >
                <b-form-input
                  ref="design"
                  placeholder="Enter designation."
                  v-model.trim="myObj.staff.designation"
                  :disabled="disableFields"
                />
                <!-- @focusout="CheckDesignation()" -->
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Experience"
                invalid-feedback="Experience is required."
              >
                <b-form-input
                  placeholder="Enter experience."
                  v-model.trim="myObj.staff.experience"
                  :disabled="disableFields"
                />
              </b-form-group>
            </b-col>
            <template v-if="reportDomain == 'myskoolhpgs'">
              <b-col md="6">
                <b-form-group
                  label="Campus *"
                  invalid-feedback="Campus is required."
                  label-class="font-weight-bold"
                  ref="camp"
                >
                  <v-select
                    v-model="myObj.staff.campusID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="campuses"
                    :clearable="false"
                    :reduce="(val) => val.id"
                    label="branch"
                    placeholder="Select campus"
                    :disabled="disableFields"
                    @input="setData()"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Department *"
                  label-class="font-weight-bold"
                  invalid-feedback="Department is required."
                  ref="dep"
                >
                  <v-select
                    v-model="myObj.staff.departmentID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="departments"
                    :clearable="false"
                    :reduce="(val) => val.id"
                    label="name"
                    :disabled="depLoading || disableFields"
                    placeholder="Select department"
                    @input="CheckDep()"
                  />
                </b-form-group>
              </b-col>
            </template>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Teaching Level"
                invalid-feedback="Teaching Level is required."
              >
                <b-form-input
                  placeholder="Enter teaching level"
                  v-model.trim="myObj.staff.teachingLevel"
                  :disabled="disableFields"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="CNIC *"
                invalid-feedback="CNIC is required."
                label-class="font-weight-bold"
                ref="cnic"
              >
                <b-form-input
                  placeholder="Enter CNIC"
                  v-model.trim="myObj.staff.cnic"
                  :disabled="disableFields"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Applied on *"
                invalid-feedback="Applied on is required."
                label-class="font-weight-bold"
                ref="reg_date"
              >
                <flat-pickr
                  :config="config"
                  v-model="myObj.staff.registrationDate"
                  class="form-control"
                  placeholder="Select date"
                  :disabled="disableFields"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="reportDomain == 'myskoolhpgs'">
              <b-form-group
                label="Demo at Campus"
                invalid-feedback="Campus is required."
                ref="demo_camp"
              >
                <v-select
                  v-model="myObj.staff.demoCampusID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="campuses"
                  :clearable="false"
                  :reduce="(val) => val.id"
                  label="branch"
                  placeholder="Select campus"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Current Address *"
                invalid-feedback="Address is required."
                label-class="font-weight-bold"
                ref="address"
              >
                <b-form-input
                  placeholder="Enter current address."
                  v-model.trim="myObj.staff.address_current"
                  :disabled="disableFields"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Permanent Address"
                invalid-feedback="Address is required."
              >
                <b-form-input
                  placeholder="Enter permanent address."
                  v-model.trim="myObj.staff.address_permanent"
                  :disabled="disableFields"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Teaching Subjects"
                invalid-feedback="Subjects are required."
                ref="sub"
              >
                <v-select
                  v-model="myObj.subjectIDs"
                  multiple
                  :closeOnSelect="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="subjects"
                  :reduce="(opt) => opt.id"
                  label="subject"
                  :clearable="false"
                  input-id="categoryId"
                  ref="sub"
                  :disabled="dataloading || disableFields"
                  placeholder="Select teaching subjects"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Availability"
                invalid-feedback="Availability is required."
              >
                <v-select
                  v-model="myObj.staff.availibility"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="availibility"
                  :clearable="false"
                  input-id="categoryId"
                  ref="avail"
                  :disabled="disableFields"
                  placeholder="Your availability"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Expected Salary *"
                invalid-feedback="Expected salary is required."
                label-class="font-weight-bold"
              >
                <b-form-input
                  ref="salary"
                  type="number"
                  v-model="myObj.staff.expectedSalary"
                  class="form-control"
                  :disabled="disableFields"
                  placeholder="Enter expected salary"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Appointed on"
                invalid-feedback="Appointment date is required"
                ref="appoint_date"
              >
                <flat-pickr
                  :config="config"
                  v-model="myObj.staff.appointedOn"
                  class="form-control"
                  :disabled="disableFields"
                  placeholder="Select appointment date"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-2">
              <div class="file-uploader">
                <h5>
                  <b-spinner
                    variant="dark"
                    label="Spinning"
                    v-if="uploading"
                  ></b-spinner>
                  <span v-else> Upload Documents </span>
                </h5>
                <input
                  ref="cvDoc"
                  type="file"
                  class="file_upload"
                  @change="uploadDocument()"
                  multiple="true"
                  :disabled="uploading || disableFields"
                />
              </div>
            </b-col>
            <b-col md="12" class="mt-1">
              <template v-for="(file, index) in documents">
                <div class="file">
                  <a
                    :href="file"
                    target="_blank"
                    style="display: flex; align-items: center"
                  >
                    <div class="file-type">
                      {{ file.split(".").pop().toUpperCase() }}
                    </div>
                    <p class="file-name">{{ file }}</p>
                  </a>
                  <div class="file-delete" v-if="!disableFields">
                    <i
                      class="fa fa-trash"
                      aria-hidden="true"
                      @click="deleteDocument(index)"
                    ></i>
                  </div>
                </div>
              </template>
            </b-col>
          </b-row>
          <!-- <b-row class="mt-1">
            <b-col md="6">
              <b-form-group
                label="Upload CV"
                invalid-feedback="CV is required"
                ref="docs"
              >
                <b-button
                  ref="docs"
                  @click="$refs.cvDoc.click()"
                  variant="primary"
                  :disabled="request || docLoading"
                  block
                >
                  <b-spinner
                    v-if="docLoading"
                    type="grow"
                    small
                    label="Loading..."
                  />
                  <span v-else>Upload</span>
                </b-button>
                <input
                  type="file"
                  id="cvDoc"
                  hidden
                  ref="cvDoc"
                  @change="addCV()"
                  accept="image/*,.pdf"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              v-if="mediaLoaded"
              style="display: flex; align-items: center"
            >
              <a :href="myObj.cv" target="_blank">
                <div class="file-type cursor-pointer">
                  {{ myObj.cv.split(".").pop().toUpperCase() }}
                </div>
              </a>
              <feather-icon
                icon="Trash2Icon"
                size="22"
                class="text-danger cursor-pointer"
                @click="removeCV()"
              />
            </b-col>
          </b-row> -->
        </b-card>

        <template
          v-if="reportDomain == 'myskoolhpgs' ? rights.interviewInfo : true"
        >
          <template v-for="(item, ind) in interObj">
            <b-card>
              <h4 class="d-flex align-items-center justify-content-between">
                Interview Information
                <b-button
                  v-if="item.interviews.id == 0"
                  @click="removeValue(item.interviews.id, ind)"
                  variant="outline-danger"
                  class="btn-icon"
                  pill
                  v-b-tooltip.hover.top
                  title="Remove"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </h4>
              <hr />
              <!-- <feather-icon
                  size="22"
                  icon="SettingsIcon"
                  class="cursor-pointer"
                  @click="openEval()"
                  v-b-tooltip.hover.top
                  title="Manage Evaluation"
                /> -->
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Date"
                    invalid-feedback="Date is required."
                  >
                    <flat-pickr
                      :config="config"
                      v-model="item.interviews.date"
                      class="form-control"
                      placeholder="Select date"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Status"
                    invalid-feedback="Status is required."
                  >
                    <v-select
                      v-model="item.interviews.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="false"
                      :options="statusOptions"
                      :reduce="(opt) => opt.value"
                      label="text"
                      placeholder="Select status"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Interview By"
                    invalid-feedback="Interview By is required."
                  >
                    <b-form-input
                      v-model.trim="item.interviews.interviewedBy"
                      class="form-control"
                      placeholder="Enter name"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Recommended Salary"
                    invalid-feedback="Salary is required."
                  >
                    <b-form-input
                      type="number"
                      v-model="item.interviews.recommendedSalary"
                      class="form-control"
                      placeholder="Enter salary"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Note"
                    invalid-feedback="Note is required."
                  >
                    <b-form-textarea
                      placeholder="Enter note"
                      rows="3"
                      v-model="item.interviews.note"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  class="d-flex align-items-center justify-content-between mb-1"
                >
                  <label class="bv-no-focus-ring col-form-label p-0"
                    >Include Evaluation</label
                  >
                  <b-form-checkbox
                    v-model="item.interviews.includeEvaluation"
                    switch
                    @change="(e) => checkEval(e, item)"
                  />
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Duration"
                    invalid-feedback="Salary is required."
                  >
                    <b-form-input
                      v-model="item.interviews.duration"
                      class="form-control"
                      placeholder="Enter duration"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  md="12"
                  v-if="
                    item.interviews.includeEvaluation &&
                    item.evaluationData.length > 0
                  "
                >
                  <b-row
                    v-for="(ev, index) in item.evaluationData"
                    :key="index"
                    class="mb-1"
                  >
                    <b-col md="12">
                      <h5 class="font-weight-bold mb-50">
                        {{ ev.meta_question }}
                      </h5>
                    </b-col>
                    <b-col md="12">
                      <b-button
                        v-for="(rt, i) in evalResp"
                        :key="i"
                        pill
                        class="btn-icon mr-50 mb-50"
                        size="sm"
                        :variant="
                          ev.responseID == rt.id ? 'success' : 'outline-success'
                        "
                        @click="ev.responseID = rt.id"
                      >
                        {{ rt.title }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>

            <!-- <b-row>
              <b-col md="12" class="text-center">
                <b-button
                  v-if="ind == interObj.length - 1"
                  @click="AddValue()"
                  variant="primary"
                  class="btn-icon"
                  pill
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
                
              </b-col>
            </b-row> -->
          </template>

          <b-card>
            <b-row>
              <b-col md="12" class="text-center">
                <b-button
                  @click="AddValue()"
                  variant="primary"
                  class="btn-icon"
                  pill
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
                <p class="mb-0 mt-50">Add Interview Information</p>
              </b-col>
            </b-row>
          </b-card>
        </template>

        <b-card v-if="reportDomain == 'myskoolhpgs' ? rights.approve : true">
          <h4>Approval</h4>
          <hr />
          <b-row>
            <b-col
              v-if="false"
              md="12"
              v-for="(item, ind) in approveObj"
              :key="ind"
            >
              <span>{{ ind + 1 }} - </span>
              <b-badge variant="light-success" class="mb-50">
                {{ item.id == 0 ? item.name : approvedBy_Meta }}
              </b-badge>
            </b-col>
            <!-- <b-col md="12" class="text-center">
              <b-button @click="approve()" variant="primary" class="btn-icon">
                Approve By Me
              </b-button>
            </b-col> -->
            <b-col md="4">
              <b-form-group
                label="Final Salary"
                invalid-feedback="Final Salary is required."
                ref="final_sal"
              >
                <b-form-input
                  type="number"
                  v-model="finalSalary"
                  class="form-control"
                  placeholder="Enter final salary"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Approval Date"
                invalid-feedback="Approval Date is required."
                ref="approve_date"
              >
                <flat-pickr
                  :config="config"
                  v-model="myObj.staff.approvalDate"
                  class="form-control"
                  placeholder="Select date"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-button
                variant="primary"
                class="btn-icon mt-2"
                @click="formSubmitted('approve')"
                block
                :disabled="request"
              >
                Approve
              </b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-button
          @click="formSubmitted('')"
          variant="success"
          :disabled="request || uploading"
          block
        >
          <b-spinner v-if="request" type="grow" small label="Loading..." />
          <span v-else>Submit</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";
import vue2Dropzone from "vue2-dropzone";

import "vue2-dropzone/dist/vue2Dropzone.min.css";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BMediaAside,
  BImg,
  BMedia,
  BLink,
  BSpinner,
  BBadge,
  BFormFile,
  BFormTextarea,
  BTable,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";
import { resolve } from "path";
import { reject } from "q";
export default {
  components: {
    vueDropzone: vue2Dropzone,
    // DropZone,
    BFormTextarea,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
    BCard,
    BButton,
    BMediaAside,
    BImg,
    BMedia,
    BLink,
    BSpinner,
    BBadge,
    BFormFile,
    BTable,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      file: [],
      documents: [],
      uploading: false,
      request: false,
      plus: "",
      logoloading: false,
      netAmount: 0,
      dataloading: false,
      admittedSections: [],
      currentSections: [],
      classes: [],
      genders: ["Male", "Female"],
      availibility: ["Part Time", "Full Time"],
      groups: ["A", "B", "O", "AB"],
      religions: ["Islam", "Christianity", "Hinduism", "Buddhists"],
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      myObj: {
        staff: {
          id: 0,
          rfID: "",
          name: "",
          father_name: "",
          designation: "",
          gender: "",
          picture: "",
          date_of_birth: null,
          address_current: "",
          address_permanent: "",
          contact_number: "",
          emergency_contact_person: "",
          emergency_contact_relation: "",
          emergency_number: "",
          qualification: "",
          experience: "",
          campusID: this.$store.state.userData.cId,
          status: "applicant",
          documents: "",
          availibility: "",
          expectedSalary: 0,
          departmentID: 0,
          cnic: "",
          teachingLevel: "",
          applicantStatus: "pending",
          registrationDate: new Date().toJSON(),
          appointedOn: null,
          approvalDate: null,
          leaves_allowed_from: null,
          basicSalary: 0,
          totalSalary: 0,
          demoCampusID: 0,
        },
        subjectIDs: [],
      },
      finalSalary: 0,
      classObj: {
        class_admitted: null,
        section_admitted: null,
        subject_admitted: null,
      },
      fields: [
        { label: "Class", key: "class_admitted" },
        { label: "section", key: "section_admitted" },
        { label: "subject", key: "subject_admitted" },
      ],
      // for newTeachingList
      // fields2: [
      //   { label: "Class", key: "className" },
      //   { label: "section", key: "sectionName" },
      //   { label: "subject", key: "subjects" },
      //   "actions",
      // ],
      fields2: [
        { label: "Class", key: "cls" },
        { label: "section", key: "sec" },
        { label: "subject", key: "subjects" },
        "actions",
      ],

      classesOptions: [],
      teachingList: [],
      teachingClasses: [],
      newTeachingList: [],
      gridData: [],
      // teachingClassObj: {
      //   cID: 0,
      //   sectionName: "",
      //   subjects: 0,
      // },
      teachingClassObj: {
        tID: this.$route.params.id,
        clsID: 0,
        sections: [],
        subjects: [],
        campusID: this.$store.state.userData.cId,
      },
      checkvalue: {},
      fphoneerror: false,
      mphoneerror: false,
      phoneerror: false,
      emerPhoneerror: false,
      sPhoneerror: false,
      fileProfile: "",
      vueTelOptions: { placeholder: "Enter contact number" },
      dropOptions: {
        disabled: false,
        showDialCodeInList: true,
        showFlags: true,
      },
      emerOpt: { placeholder: "Enter emergency contact" },
      subjects: [],
      docLoading: false,
      mediaLoaded: false,
      campuses: [],
      departments: [],
      depLoading: false,
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      statusOptions: [
        { text: "Cleared", value: "cleared" },
        { text: "Not Cleared", value: "not cleared" },
      ],
      interObj: [
        // {
        //   id: 0,
        //   staffID: this.$route.params.id,
        //   date: null,
        //   status: "",
        //   interviewedBy: "",
        //   note: "",
        // },
      ],
      approveObj: [],
      rights: {},
      disableFields: false,
      evalQues: [],
      evalResp: [],
    };
  },
  //   mounted() {
  //     this.$refs.wizard.activateAll();
  //   },
  created() {
    let rights = this.$store.state.rights["applicants"];

    if (!rights) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = rights;
      // console.log(this.rights);
      this.$route.params.id = parseInt(this.$route.params.id);
      if (isNaN(this.$route.params.id)) {
        this.$route.params.id = 0;
      }
      if (
        (this.$route.params.id !== 0 &&
          (this.rights.edit ||
            this.rights.interviewInfo ||
            this.rights.approve)) ||
        (this.$route.params.id == 0 && this.rights.add)
      ) {
        if (this.$route.params.id !== 0) {
          this.LoadData(this.$route.params.id);
          if (
            this.reportDomain == "myskoolhpgs" &&
            !this.rights.edit &&
            (this.rights.interviewInfo || this.rights.approve)
          ) {
            this.disableFields = true;
          }
        } else if (this.reportDomain == "myskoolhpgs") {
          // console.log(this.myObj);
          this.LoadDep(this.$store.state.userData.cId);
          this.LoadInfo(this.$store.state.userData.cId);
        }
        if (this.reportDomain == "myskoolhpgs") this.LoadCampuses();
        else this.LoadInfo(this.$store.state.userData.cId);

        this.LoadEval();
        setTimeout(() => {
          this.sPhoneerror = false;
        }, 300);
      } else {
        this.$router.replace({
          name: "misc-not-authorized",
        });
      }
    }
  },
  computed: {
    ...mapGetters({ campusID: "campusID" }),
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async LoadEval() {
      var obj = {
        url:
          this.$store.state.domain +
          "DropDownList/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&category=interviewQuestions",
        token: this.$store.state.userData.token,
      };
      this.evalQues = await this.get(obj);

      var obj = {
        url:
          this.$store.state.domain +
          "DropDownList/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&category=interviewResponses",
        token: this.$store.state.userData.token,
      };
      this.evalResp = await this.get(obj);
    },
    openEval() {
      console.log(this.evalResp, this.evalQues);
    },

    checkEval(e, item) {
      if (e && item.evaluationData.length == 0) {
        item.evaluationData = this.evalQues.map((el) => ({
          id: 0,
          tID: this.$route.params.id,
          interviewID: item.interviews.id,
          questionID: el.id,
          responseID: 0,
          meta_question: el.title,
          meta_response: null,
          campusID: this.myObj.staff.campusID,
        }));
      }
      // console.log(item);
    },
    AddValue() {
      this.interObj.push({
        interviews: {
          id: 0,
          staffID: this.$route.params.id,
          date: new Date().toJSON().split("T")[0],
          status: "",
          interviewedBy: "",
          note: "",
          recommendedSalary: 0,
          duration: "",
          includeEvaluation: false,
        },
        evaluationData: this.evalQues.map((el) => ({
          id: 0,
          tID: this.$route.params.id,
          interviewID: 0,
          questionID: el.id,
          responseID: 0,
          meta_question: el.title,
          meta_response: null,
          campusID: this.myObj.staff.campusID,
        })),
      });
      // console.log(this.interObj);

      // this.interObj.push({
      //   id: 0,
      //   staffID: this.$route.params.id,
      //   date: null,
      //   status: "",
      //   interviewedBy: "",
      //   note: "",
      //   recommendedSalary: 0,
      //   duration: "",
      //   includeEvaluation: false,
      // });
    },
    async removeValue(id, ind) {
      if (id > 0) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "StaffInterviews/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.interObj.splice(ind, 1);
        }
      } else this.interObj.splice(ind, 1);
    },
    async approve() {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to approve this applicant?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        this.approveObj.push({
          id: 0,
          staffID: this.$route.params.id,
          name: this.$store.state.loggedUser.name,
          date: new Date().toJSON(),
          approvedBy: this.$store.state.userData.userID,
          approvedBy_Meta: "",
        });
      }
    },
    deleteDocument(index) {
      this.documents.splice(index, 1);
      this.myObj.staff.documents = this.documents.join(",");
    },
    uploadDocument() {
      let docs = this.$refs.cvDoc.files;
      if (docs.length != 0) {
        this.uploading = true;
        let formData = new FormData();
        docs.forEach((el) => {
          formData.append("file", el);
        });
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp;
            resp.forEach((el) => {
              this.documents.push(el.path);
            });
            this.myObj.staff.documents = this.documents.join(",");
            this.uploading = false;
            // console.log(this.myObj.staff.documents);
          });
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.staff.picture = null;
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    selectlogo() {
      // console.log(this.$refs.fileProfile.files[0]);
      this.fileProfile = this.$refs.fileProfile.files[0];
      // console.log(this.fileProfile);
      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        var axios = require("axios");
        formData.append("file", this.fileProfile);
        axios
          .post("https://upload.myskool.app/profile-picture", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            // this.file = undefined;
            // var fn1 = e.data.myresp[0].path;
            var fn1 = e.data.file.path;
            // console.log(fn1);
            this.plus = fn1;
            this.myObj.staff.picture = this.plus;
            //
            // console.log(this.plus);
            //console.log(this.myObj.coverImg);
            this.logoloading = "loaded";
          })
          .catch((err) => {
            this.logoloading = false;
            console.log(err);
          });
      }
    },
    removeCV() {
      this.myObj.cv = null;
      this.mediaLoaded = false;
      this.$refs.cvDoc.files = null;
    },
    addCV() {
      let doc = this.$refs.cvDoc.files[0];
      if (doc != "") {
        this.mediaLoaded = false;
        this.docLoading = true;
        let formData = new FormData();
        formData.append("file", doc);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            console.log(e.data);
            let resp = e.data.myresp[0].path;
            this.myObj.cv = resp;
            this.docLoading = false;
            this.mediaLoaded = true;
            console.log(this.myObj.cv);
          });
      }
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.staff.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckFName() {
      var elem = this.$refs["fname"];
      if (this.myObj.staff.father_name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckQualification() {
      var elem = this.$refs["qua"];
      if (this.myObj.staff.qualification == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDesignation() {
      var elem = this.$refs["design"];
      if (this.myObj.staff.designation == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckFContact() {
      // console.log(this.myObj.contact_father);
      // console.log(this.myObj.contact_father.length);
      if (this.myObj.contact_father.length == 12) {
        this.fphoneerror = false;
        return true;
      } else {
        this.fphoneerror = true;
        return false;
      }
    },
    CheckMContact() {
      // console.log(this.myObj.contact_mother);
      // console.log(this.myObj.contact_mother.length);
      if (this.myObj.contact_mother.length == 12) {
        this.mphoneerror = false;
        return true;
      } else {
        this.mphoneerror = true;
        return false;
      }
    },
    CheckContact() {
      // console.log(this.myObj.contact_student);
      // console.log(this.myObj.contact_student.length);
      if (this.myObj.staff.contact_number.length == 12) {
        this.phoneerror = false;
        return true;
      } else {
        this.phoneerror = true;
        return false;
      }
    },
    CheckSContact(s, e) {
      // console.log(this.myObj.contact_number);
      // console.log(this.myObj.contact_student.length);
      // if (this.myObj.staff.contact_number.length == 12) {
      //   this.sPhoneerror = false;
      //   return true;
      // } else {
      //   this.sPhoneerror = true;
      //   return false;
      // }
      if (e.valid) {
        this.sPhoneerror = false;
        this.myObj.staff.contact_number = e.number;
      } else {
        this.sPhoneerror = true;
      }
    },

    CheckEmerName() {
      var elem = this.$refs["emerName"];
      if (
        this.myObj.staff.emergency_name == null ||
        this.myObj.staff.emergency_name == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerRelation() {
      var elem = this.$refs["emerRel"];
      if (
        this.myObj.staff.emergency_relationship == null ||
        this.myObj.staff.emergency_relationship == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerContact() {
      if (this.myObj.staff.emergency_contact.length == 12) {
        console.log(this.myObj.staff.emergency_contact.length);
        this.emerPhoneerror = false;
        return true;
      } else {
        this.emerPhoneerror = true;
        return false;
      }
    },
    CheckSalary() {
      const regex = /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/;
      var elem = this.$refs["salary"];
      if (regex.test(this.myObj.staff.expectedSalary)) {
        if (this.myObj.staff.expectedSalary == 0) {
          return (elem.state = false);
        } else {
          this.myObj.staff.expectedSalary = parseInt(
            this.myObj.staff.expectedSalary
          );
          return (elem.state = true);
        }
      } else {
        return (elem.state = false);
      }
    },
    CheckDep() {
      if (this.reportDomain == "myskoolhpgs") {
        var elem = this.$refs["dep"];
        if (this.myObj.staff.departmentID == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckCnic() {
      var elem = this.$refs["cnic"];
      if (!this.myObj.staff.cnic) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDate() {
      var elem = this.$refs["dob"];
      if (!this.myObj.staff.date_of_birth) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckRegDate() {
      var elem = this.$refs["reg_date"];
      if (!this.myObj.staff.registrationDate) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGender() {
      var elem = this.$refs["gender"];
      if (this.myObj.staff.gender == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAddress() {
      var elem = this.$refs["address"];
      if (this.myObj.staff.address_current == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckInterview() {
      let state = true;
      if (
        this.reportDomain == "myskoolhpgs" ? this.rights.interviewInfo : true
      ) {
        this.interObj.forEach((el) => {
          let x = parseInt(el.interviews.recommendedSalary);
          if (
            !el.interviews.date ||
            !el.interviews.interviewedBy ||
            !el.interviews.status ||
            isNaN(x) ||
            x < 0
          ) {
            state = false;
          } else el.interviews.recommendedSalary = x;
        });
      } else {
        this.interObj.forEach((el) => {
          let x = parseInt(el.interviews.recommendedSalary);
          if (isNaN(x) || x < 0) {
            el.interviews.recommendedSalary = 0;
          } else el.interviews.recommendedSalary = x;
        });
      }
      return state;
    },
    CheckTcID() {
      var elem = this.$refs["tcID"];
      if (this.teachingClassObj.clsID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTSection() {
      // console.log(this.teachingClassObj.sections.length);
      var elem = this.$refs["tSection"];
      if (this.teachingClassObj.sections.length === 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTSubject() {
      // console.log(this.teachingClassObj.subjects.length);
      var elem = this.$refs["sub"];
      if (this.myObj.subjectIDs.length === 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    FillSections() {
      console.log(this.teachingClassObj.clsID);
      // this.teachingClassObj.sections = [];
      this.classes.forEach((elem) => {
        if (elem.cls.id === this.teachingClassObj.clsID) {
          if (elem.sections.length != 0) {
            console.log("not null");
            this.admittedSections = elem.sections;
          } else {
            console.log("null");
            this.admittedSections = [];
          }
        }
      });
      console.log("Thisis", this.admittedSections);
    },
    async assign() {
      this.teachingClassObj.tID = parseInt(this.$route.params.id);
      console.log(this.teachingClassObj);
      this.CheckTcID();
      this.CheckTSubject();
      this.CheckTSection();
      if (
        this.CheckTcID() == false ||
        this.CheckTSubject() == false ||
        this.CheckTSection() == false
      ) {
        return this.$bvToast.toast("Please enter all the required fields.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        var status = await this.post({
          url:
            this.$store.state.domain +
            "TeacherClasses/SaveData?db=" +
            this.$store.state.userData.db,
          body: this.teachingClassObj,
          message: "Classes assigned successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.LoadTeachingClasses();
          this.teachingClassObj = {
            tID: this.$route.params.id,
            clsID: 0,
            sections: [],
            subjects: [],
            campusID: this.$store.state.userData.cId,
          };
        }
      }
    },
    FillSectionsCurrent() {
      //return console.log(this.classes);
      this.classes.forEach((elem) => {
        if (elem.id == this.myObj.class_current) {
          this.currentSections = elem.sections.split(",");
        }
      });
      console.log("Thisis", this.admittedSections);
    },
    async LoadData(id) {
      // "staff/LoadSelectedApplicantNew?db=" +
      var obj = {
        url:
          this.$store.state.domain +
          "staff/LoadSelectedApplicantWithInterview?db=" +
          this.$store.state.userData.db +
          "&id=" +
          id,
        token: this.$store.state.userData.token,
        dashboard: true,
      };
      let res = await this.get(obj);
      // console.log(res);
      if (!Array.isArray(res)) {
        this.myObj = res.data;
        // this.evalResp = res.responsesData;
        // console.log(this.myObj);

        this.interObj = this.myObj.interviews;
        this.approveObj = this.myObj.approvals;

        if (this.reportDomain == "myskoolhpgs") {
          this.LoadDep(this.myObj.staff.campusID);
          this.LoadInfo(this.myObj.staff.campusID);
        }
        if (
          this.myObj.staff.picture !== null &&
          this.myObj.staff.picture !== ""
        ) {
          this.plus = this.myObj.staff.picture;
          this.logoloading = "loaded";
        }
        if (
          this.myObj.staff.documents !== null &&
          this.myObj.staff.documents !== ""
        ) {
          this.documents = this.myObj.staff.documents.split(",");
          // console.log(this.documents);
        }
      } else {
        this.$router.replace({ name: "applicants" }).then(() => {
          this.$bvToast.toast("No record found!", {
            title: "Error!",
            variant: "danger",
            solid: true,
          });
        });
      }
    },
    async LoadInfo(id) {
      this.dataloading = true;

      var obj = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          id,
        token: this.$store.state.userData.token,
      };
      this.subjects = await this.get(obj);

      // console.log("subject", this.subjects);
      this.dataloading = false;
    },
    async LoadCampuses() {
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.campuses = await this.get(obj);
    },
    async LoadDep(id) {
      this.depLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "StaffDepartments?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          id,
        token: this.$store.state.userData.token,
      };
      this.departments = await this.get(obj);
      this.depLoading = false;
    },
    setData() {
      // this.myObj.staff.departmentID = 0;
      // this.LoadDep(this.myObj.staff.campusID);
      this.myObj.subjectIDs = [];
      this.LoadInfo(this.myObj.staff.campusID);
    },

    EditTeaching(item) {
      console.log(item);
      this.teachingClassObj = {
        tID: this.$route.params.id,
        clsID: 0,
        sections: [],
        subjects: [],
        campusID: this.$store.state.userData.cId,
      };
      this.teachingClassObj.clsID = item.clsID;
      item.subjects.forEach((el) => this.teachingClassObj.subjects.push(el.id));
      this.FillSections();
      this.teachingClassObj.sections = item.sections;
    },
    async DeleteTeaching(item) {
      console.log(item);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.post({
          url:
            this.$store.state.domain +
            "TeacherClasses/Remove?db=" +
            this.$store.state.userData.db,
          body: item,
          message: "Teaching classes removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        console.log(status);
        if (status) this.LoadTeachingClasses();
      }
    },
    async formSubmitted(txt) {
      this.CheckName();
      this.CheckFName();
      // this.CheckSContact();
      this.CheckDesignation();
      this.CheckQualification();
      // this.CheckTSubject();
      this.CheckSalary();
      this.CheckDep();
      this.CheckCnic();
      this.CheckDate();
      this.CheckRegDate();
      this.CheckGender();
      this.CheckAddress();
      if (this.myObj.staff.contact_number == "") this.sPhoneerror = true;
      if (
        this.CheckName() == false ||
        this.CheckFName() == false ||
        // this.CheckSContact() == false ||
        this.myObj.staff.contact_number == "" ||
        this.sPhoneerror ||
        this.CheckDesignation() == false ||
        this.CheckQualification() == false ||
        // this.CheckTSubject() == false ||
        this.CheckSalary() == false ||
        this.CheckDep() == false ||
        this.CheckCnic() == false ||
        this.CheckDate() == false ||
        this.CheckRegDate() == false ||
        this.CheckGender() == false ||
        this.CheckAddress() == false ||
        this.CheckInterview() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else {
        if (txt == "approve") {
          this.saveFinal();
          // this.$bvModal.show("modal-login");
        } else {
          this.request = true;

          if (this.myObj.staff.date_of_birth == "") {
            this.myObj.staff.date_of_birth = null;
          }
          if (this.myObj.staff.approvalDate == "") {
            this.myObj.staff.approvalDate = null;
          }
          if (this.myObj.staff.appointedOn == "") {
            this.myObj.staff.appointedOn = null;
          }
          this.myObj.staff.leaves_allowed_from = this.myObj.staff.appointedOn;

          if (
            [null, "", "pending"].includes(this.myObj.staff.applicantStatus) &&
            this.interObj.length > 0
          ) {
            this.myObj.staff.applicantStatus = "in progress";
          }

          let interData = this.interObj.map((el) => {
            let obj = { interviews: el.interviews, evaluationData: [] };
            if (
              el.interviews.includeEvaluation &&
              el.evaluationData.find((el) => el.responseID > 0)
            ) {
              obj.evaluationData = el.evaluationData;
            } else {
              obj.interviews.includeEvaluation = false;
              el.interviews.includeEvaluation = false;
            }
            return obj;
          });
          // console.log(interData);

          // "staff/SaveApplicantNew?db=" +
          var status = await this.post({
            url:
              this.$store.state.domain +
              "staff/SaveApplicantWithInterview?db=" +
              this.$store.state.userData.db,
            body: {
              staff: this.myObj.staff,
              subjectIDs: this.myObj.subjectIDs,
              // interviews: this.interObj,
              interviews: interData,
              approvals: this.approveObj,
            },
            message: "Application submitted successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.$router.go(-1);
          }
        }
      }
    },
    CheckFinal() {
      var elem = this.$refs["final_sal"];
      let x = parseInt(this.finalSalary);
      if (isNaN(x) || x <= 0) {
        return (elem.state = false);
      } else {
        this.finalSalary = x;
        return (elem.state = true);
      }
    },
    CheckApprove() {
      var elem = this.$refs["approve_date"];
      if (!this.myObj.staff.approvalDate) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveFinal() {
      if (this.CheckFinal() == true && this.CheckApprove() == true) {
        this.request = true;

        if (this.myObj.staff.date_of_birth == "") {
          this.myObj.staff.date_of_birth = null;
        }
        if (this.myObj.staff.appointedOn == "") {
          this.myObj.staff.appointedOn = null;
        }
        this.myObj.staff.basicSalary = this.finalSalary;
        this.myObj.staff.totalSalary = this.finalSalary;
        this.myObj.staff.status = "present";
        this.myObj.staff.applicantStatus = "approved";
        // this.myObj.staff.appointedOn = new Date().toJSON();
        this.myObj.staff.leaves_allowed_from = this.myObj.staff.appointedOn;

        this.approveObj.push({
          id: 0,
          staffID: this.$route.params.id,
          date: new Date().toJSON(),
          approvedBy: this.$store.state.userData.userID,
          approvedBy_Meta: "",
        });

        let interData = this.interObj.map((el) => {
          let obj = { interviews: el.interviews, evaluationData: [] };
          if (
            el.interviews.includeEvaluation &&
            el.evaluationData.find((el) => el.responseID > 0)
          ) {
            obj.evaluationData = el.evaluationData;
          } else {
            obj.interviews.includeEvaluation = false;
            el.interviews.includeEvaluation = false;
          }
          return obj;
        });
        // console.log(interData);

        // "staff/SaveApplicantNew?db=" +
        var status = await this.post({
          url:
            this.$store.state.domain +
            "staff/SaveApplicantWithInterview?db=" +
            this.$store.state.userData.db,
          body: {
            staff: this.myObj.staff,
            subjectIDs: this.myObj.subjectIDs,
            // interviews: this.interObj,
            interviews: interData,
            approvals: this.approveObj,
          },
          message: "Application submitted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.$router.go(-1);
        }
      }
    },
  },
};
</script>
<style>
.heading {
  padding: 0;
  margin: 2.171303074670571vw 0;
  /* text-shadow: -4px 0 5px white, 0 4px 5px white, 4px 0 5px white,
        0 -4px 5px white; */
}
.input-form {
  margin: 0 70px;
  background: #fff;
}
#fileInput .custom-file-label {
  color: #707070;
  border: 1px dashed #33312f;
  text-align: center;
  padding: 2.5rem 1.5rem;
  width: 100%;
}
#fileInput input {
  display: none;
}
#imgInput input {
  display: none;
}
#imgInput {
  /* height: 8rem; */
  width: 50%;
}
#imgInput div {
  height: 8vw;
}
#imgInput .custom-file-label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.type {
  background-color: red;
  border-radius: 10px;
  font-size: 0.8rem;
  padding: 10px;
  color: white;
}
.filename {
  padding-left: 25px;
}
.delete {
  margin: 0 0 0 auto;
  color: red;
}
#checkbox-1 {
  margin-right: 10px !important;
}
.submit {
  background-color: #00c192;
  color: #fff;
  width: 16.5vw;
  height: 4vw;
  font-weight: bold;
  border-color: #00c192;
}
.form-file-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 20px;
}
/* #prop-det, #prop-det div {
      height: 100%;
    } */

.item-group {
  width: 50%;
  /* margin-top: 2.269399707174231vw; */
}
.inputfields,
.inputfields:focus {
  border: 1px solid #33312f;
  border-radius: 0.5856515373352855vw;
  height: 2.562225475841874vw;
  outline: none !important;
  resize: none;
}
@media only screen and (max-width: 990px) {
  .mob-setting {
    padding: 0 1rem !important;
    width: 100vw !important;
    margin: 0 auto !important;
  }
  .mobile-view {
    margin: 0 !important;
  }
  .item-group {
    margin-top: 0;
  }
  .margin-top-mobile {
    margin-top: 5vw;
  }
  .form-input-mobile-text {
    width: 92vw;
    margin: 10px auto;
    border-radius: 2.8301610541727673vw;
    height: 12.781844802342606vw;
    font-size: 5.171303074670571vw;
    background-color: #f2f2f2 !important;
    border: 1px solid #33312f;
  }
  .form-input-mobile-text:focus {
    background-color: #00c192 !important;
    color: #fff !important;
  }
  .form-input-mobile-text::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(190, 190, 190);
    opacity: 1; /* Firefox */
    font-family: DINNextRoundedLTW01-Regular;
  }

  .form-input-mobile-text:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgb(190, 190, 190);
  }

  .form-input-mobile-text::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgb(190, 190, 190);
  }

  .form-input-mobile-text:focus::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
  }
  .form-file-input-mobile {
    border-radius: 2.8301610541727673vw;
    height: 12.781844802342606vw;
    background-color: #f2f2f2 !important;
    color: #fff !important;
  }
  .custom-file-label {
    border-radius: 2.8301610541727673vw !important;
    height: 12.781844802342606vw;
    background-color: #f2f2f2 !important;
    color: #000 !important;
    height: 40vw;
    border: 1px solid #33312f;
  }
  .submit {
    width: 50vw;
    height: 10vw;
  }
  .filename {
    font-size: 3vw;
  }
  .shadowHead {
    font-size: 8vw !important;
  }
  #imgInput div {
    height: 29vw !important;
  }
  .item-group {
    height: 30vw !important;
  }
}

@media only screen and (min-width: 1366px) {
  .submit {
    width: 15.5vw;
    height: 7.8vh;
  }
}
@media only screen and (min-width: 1440px) {
  .submit {
    width: 14.7vw;
    height: 6.7vh;
  }
}
@media only screen and (min-width: 1536px) {
  .submit {
    width: 13.8vw;
    height: 6.9vh;
  }
}
@media only screen and (min-width: 1920px) {
  .submit {
    width: 11vw;
    height: 5.5vh;
  }
}
#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
</style>
<style scoped>
.file-uploader {
  position: relative;
  border: 1px solid #000;
  width: 100%;
  line-height: 30px;
  text-align: center;
  border-radius: 10px;
  font-family: "Helvetica", sans-serif;
  height: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.file_upload {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.file-type {
  background-color: #e62e2d;
  font-family: "Helvetica", sans-serif;
  color: white;
  border-radius: 10px;
  margin-right: 15px;
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.file-name {
  font-family: "Helvetica", sans-serif;
  margin: 0px;
}

.file-delete i {
  color: #e62e2d;
  font-size: 2rem;
  cursor: pointer;
}
.rcorners2 {
  border-radius: 100px;
  border: 4px solid var(--primary);
  padding: 2px;
}
.sticky {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 60px;
  margin-bottom: 30px;
  /* position: fixed;
        bottom: 5%;
        z-index: 1031;  */
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
